import bihuImageIcon from "../assets/images/bihu2024.png";
import duraPujaCelebration2023 from "../assets/images/duraPujaCelebration.png";
import durgaPujaImageIcon2022 from "../assets/images/durgaPujaIcon2022.png";
import bihuCelebration2023 from "../assets/images/bihuCelebration.png";
import eventHindhiText from "../assets/images/eventHindiTxt.svg";
import dugapuja1 from "../assets/images/durgapugaevent1.svg";
import dpuja from "../assets/images/durgapujahindi22.png";
import bih1img1 from "../assets/images/bihu2023/bihuImage1.jpeg";
import bih1img2 from "../assets/images/bihu2023/bihuImage2.jpeg";
import bih1img3 from "../assets/images/bihu2023/bihuImage3.jpeg";
import bih1img4 from "../assets/images/bihu2023/bihuImage4.jpeg";
import bih1img5 from "../assets/images/bihu2023/bihuImage5.jpeg";
import bih1img6 from "../assets/images/bihu2023/bihuImage6.jpeg";
import durgapujaImg1 from "../assets/images/durgapuja/durapuja1.png";
import durgapujaImg2 from "../assets/images/durgapuja/durapuja2.png";
import durgapujaImg3 from "../assets/images/durgapuja/durapuja3.png";
import durgapujaImg4 from "../assets/images/durgapuja/durapuja4.png";
import durgapujaImg5 from "../assets/images/durgapuja/durapuja5.png";
import durgapujaImg6 from "../assets/images/durgapuja/durapuja6.png";
import saraswatiPujaImage from '../assets/images/Sarasvati-pooja.png'
import cricketTournamentImage from '../assets/images/Cricket.png'
import joyOfGivingImage from '../assets/images/joy-of-giving.png'
import badmintonTournamentImage from '../assets/images/Badminton.png'

export const eventsInfo = [
  {
    id: "duraPujaCelebration2023",
    venue: "Freedom International School Ground, HSR Layout",
    Date: "2nd Oct - 4th Oct",
    event: "11th Shree Shree Durga Puja 2023",
    eventLogo: duraPujaCelebration2023,
    eventText: dugapuja1,
    isLocation: true,
    googleMapURL:
      "https://maps.google.com?q=Freedom+International+School+Ground,HSR+Layout",
  },

  {
    id: "BohagBihu2024",
    venue: "Freedom International School Ground, HSR Layout",
    Date: "7th April",
    eventLogo: bihuImageIcon,
    event: "23nd Rongali Bihu Celebration, 2024",
    isUpcoming: true,
    isLocation: true,
    googleMapURL:
      "https://maps.google.com?q=Freedom+International+School+Ground,HSR+Layout",
  },

  {
    id: 'saraswatiPuja2024',
    venue: "",
    Date: '',
    eventLogo: saraswatiPujaImage,
    isUpcoming: true
  },
  {
    id: 'cricketTournament2023',
    venue: "",
    Date: '',
    eventLogo: cricketTournamentImage,
    isUpcoming: true
  },
  {
    id: 'Joyofgiving2023',
    venue: "",
    Date: '',
    eventLogo: joyOfGivingImage,
    isUpcoming: true
  },
  {
    id: 'badmintonTournament2024',
    venue: "",
    Date: '',
    eventLogo: badmintonTournamentImage,
    isUpcoming: true
  },

  {
    id: "BohagBihu2023",
    venue: "Freedom International School Ground, HSR Layout",
    Date: "2nd Oct - 4th Oct",
    event: "10th Shree Shree Durga Puja 2022",
    eventLogo: bihuCelebration2023,
    eventText: eventHindhiText,
    imageFilenames: [
      { img: bih1img1 },
      { img: bih1img2 },
      { img: bih1img3 },
      { img: bih1img4 },
      { img: bih1img5 },
      { img: bih1img6 },
    ],
  },
  {
    id: "durgaPuja2022",
    venue: "Freedom International School Ground, HSR Layout",
    Date: "2nd Oct - 4th Oct",
    event: "10th Shree Shree Durga Puja 2022",
    eventLogo: durgaPujaImageIcon2022,
    eventText: dpuja,
    imageFilenames: [
      { img: durgapujaImg1 },
      { img: durgapujaImg2 },
      { img: durgapujaImg3 },
      { img: durgapujaImg4 },
      { img: durgapujaImg5 },
      { img: durgapujaImg6 },
    ],
  },
];
