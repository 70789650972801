import React, { useEffect } from "react";
import { Element } from "react-scroll";
import "./Style.css";
import Home from "../components/Home";
import Event from "../components/Event";
import Donation from "../components/Donation";
import AboutUs from "../components/AboutUs";
import Gallery from "../components/Gallery";
import Members from "../components/Members";
import Membership from '../components/membership'
import GalleryDetails from "../components/GalleryDetails";
import { Link, Route, Routes } from "react-router-dom";
import Footer from "../common/Footer";
import './headerstyles.css'
import useWindowDimensions from "../common/UseWindowDimensions";
import MobileFooter from "../common/MobileFooter"
import { connect } from "react-redux";

function Sections({navBarId}) {
  const { height, width } = useWindowDimensions();
  useEffect(()=>{
    if(navBarId == 1){
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }else if(navBarId == 2){
      window.scrollTo({ top: 1200, left: 0, behavior: "smooth" });
    }else if(navBarId == 3){
      window.scrollTo({ top: 2050, left: 0, behavior: "smooth" });
    }else if(navBarId == 4){
      window.scrollTo({ top: 3000, left: 0, behavior: "smooth" });
    }else if(navBarId == 5){
      window.scrollTo({ top: 5200, left: 0, behavior: "smooth" });
    }
  },[navBarId])

  return (
    <div>
      {width > 600 ? (
        <div>
          <Element className="section" name="section-1">
            {/* <Home /> */}
          </Element>
          <Element className="section" name="section-2">
            <Event />
          </Element>
          <Element className="section" name="section-3">
            <Gallery />
          </Element>
          <Element className="section" name="section-4">
            <Members />
          </Element>
          <Element className="section" name="section-5">
            <AboutUs />
          </Element>
          {/* <Element className="sectionDonation" name="section-6">
            <Donation />
          </Element> */}
          {/* <Element className="section" name="section-7">
            <Membership />
          </Element> */}
          <Footer />
        </div>
      ) : (
        <div>
          <Element className="mbsection" name="section-1">
            {/* <Home /> */}
          </Element>
          <Element className="mbsection2" name="section-2">
            <Event />
          </Element>
          <Element className="mbsection2" name="section-3">
            <Gallery />
          </Element>
          <Element className="mbsection2" name="section-4">
            <Members />
          </Element>
          <Element className="mbsection2" name="section-5">
            <AboutUs />
          </Element>
          {/* <Element className="mbsection2" name="section-6">
            <Donation />
          </Element> */}
          {/* <Element className="mbsection2" name="section-7">
            <Membership />
          </Element> */}
          <MobileFooter />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  const { navBarId } = state.asob;
  return {
    navBarId
  }
};
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Sections);
