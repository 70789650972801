import React from 'react';
import Lottie from "lottie-react";
import commingSoon from "../assets/images/commingSoon.json";

function CommingSoonTxt() {
  return (
    <div className="comngSnTxt">
      <Lottie animationData={commingSoon} />
    </div>
  )
}

export default CommingSoonTxt;