import baseUrl from '../common/baseUrl';
import returnUrl from '../common/baseUrl'

export const generatePaymentRequest = (response, orderId, customerName, customerEmail, customerPhoneNumber, generalTicketCount, corporateTicketCount, generalTicketCost, corporateTicketCost, taxPayable, totalAmount, serialNumber) => {

    const cashfree = window.Cashfree({
        mode: "sandbox", // or "production"
        // other configuration options
    });

    let checkoutOptions = {
        paymentSessionId: response?.payment_session_id,
        // returnUrl: `https://d1g1qu35qdranh.cloudfront.net/book-tickets/?orderId=${orderId}&name=${customerName}&email=${customerEmail}&phone=${customerPhoneNumber}&generalTicket=${generalTicketCount}&corporateTicket=${corporateTicketCount}&generalTicketAmount=${generalTicketCost}&regularTicketAmount=${corporateTicketCost}&tax=${taxPayable}&total=${totalAmount}`
        // returnUrl: `https://8584-2401-4900-1cc5-f426-fd77-f4f7-7ea6-b300.ngrok-free.app/book-tickets/?orderId=${orderId}&name=${customerName}&email=${customerEmail}&phone=${customerPhoneNumber}&generalTicket=${generalTicketCount}&corporateTicket=${corporateTicketCount}&generalTicketAmount=${generalTicketCost}&regularTicketAmount=${corporateTicketCost}&tax=${taxPayable}&total=${totalAmount}`
        // returnUrl: `https://assamsocietyofbangalore.com/apiV2/book-tickets/?orderId=${orderId}&name=${customerName}&email=${customerEmail}&phone=${customerPhoneNumber}&generalTicket=${generalTicketCount}&corporateTicket=${corporateTicketCount}&generalTicketAmount=${generalTicketCost}&regularTicketAmount=${corporateTicketCost}&tax=${taxPayable}&total=${totalAmount}`
        returnUrl: `https://d1g1qu35qdranh.cloudfront.net/book-tickets/?orderId=${orderId}&name=${customerName}&email=${customerEmail}&phone=${customerPhoneNumber}&generalTicket=${generalTicketCount}&corporateTicket=${corporateTicketCount}&generalTicketAmount=${generalTicketCost}&regularTicketAmount=${corporateTicketCost}&tax=${taxPayable}&total=${totalAmount}&serial=${serialNumber}`
    }

    cashfree.checkout(checkoutOptions).then(function (result) {
        if (result.error) {
            alert(result.error.message)
        }
        if (result.redirect) {
            console.log("Redirection")
        }
    });

    let options = {
        values: {
            upiId: ""
        }
    };

    let component = cashfree.create("upiCollect", options);

    const div = document.createElement('div');
    div.setAttribute("id", "componentContainer");
    document.body.appendChild(div);

    component.mount("#componentContainer");

    let paymentOptions = {
        paymentMethod: component,
        paymentSessionId: response?.payment_session_id,
        returnUrl: `https://d1g1qu35qdranh.cloudfront.net/post-payment/?orderId=${orderId}`,
    };

    cashfree.pay(paymentOptions).then(function (result) {
        if (result.error) {
            //there is an error
            //message at result.error.message
        }
        if (result.cancelled) {
            // User cancelled the payment
            // Close the tab or perform any other action
            window.close();
        }
        if (result.redirect) {
            //console.log("User would be redirected");
        }
        if (result.paymentDetails) {
            //only when redirect = 'if_required' for UPI payment
            //payment is successful
            //message is at result.paymentDetails.paymentMessage
        }
    });
}