import React, { useEffect, useState } from "react";
import Footer from "../common/Footer";
import "./Style.css";
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import { Element } from "react-scroll";
import { ArrowLeftOutlined } from "@ant-design/icons";
import assamSingleDanceIcon from "../assets/images/assamSingleDanceIcon.svg";
import assamGrpDanceIcon from "../assets/images/assamGrpDanceIcon.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import designIcon from "../assets/images/homeFooterDesign.svg";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../common/UseWindowDimensions";
import MobileFooter from "../common/MobileFooter";


export const FaqArray = [
    "No cancellations allowed. All rights reserved.",
    "Entry Gates will by closed at 6 PM sharp. No Entry allowed after 6 PM.",
    "This Ticket is proof of booking your Entry and must be presented at the time of Admission at the Venue.",
    "No Alcohol allowed to be brought inside the Venue Premises.",
    "ASOB reserves to right to refuse entry if anyone is found drunk, irrespective of holding valid ticket.",
    "Smoking is strictly prohibited inside the Venue Premises.",
    "Confirmed seats will be there for Corporate and VIP Category. There will be *No Confirmed Seats for General Category*.",
    "Corporate category Seating arrangements(Rows) will be First Come First Serve basis.",
    "Neither the Event Management nor ASOB can be held responsible for loss or damage of any personal property.",
    "If you experience any issue at the Venue, kindly do bring to our notice by approaching your nearest Volunteer.",
    "Roadside Parking Space available at the Venue."
]

function TermsAndConditions() {
    let location = useLocation();
    const { height, width } = useWindowDimensions();
    const [ path, setPath ] = useState(window.location.pathname);
    useEffect(() => {
        setPath(window.location.pathname);
    }, [window.location.pathname])
    
    return (
        <div>
            {width > 600 ? (
                <Element className="section-about-us-details">
                    <div className="aboutMainConatiner" >
                        { path === '/terms-and-conditions' ? 
                            <div className="homeSidebar-about-us-details">
                                <img
                                    className="homesideBarConatainer-about-us-details"
                                    src={sideBar}
                                />
                            </div> :
                            <div
                                className="homeLoad-about-us-details"
                                style={{
                                    rotate: '180deg'
                                }}
                            >
                                <img className="loadCircle-about-us-details" src={loadCircle} alt="No image" loading="lazy" />
                            </div>
                        }
                        <div className="about-us-details-content22" >
                            <div className="about-us-details-heading" >
                                <Link 
                                    to="/" 
                                    style={{ 
                                        // color: path === '/terms-and-conditions' ? 'inherit' : 'transparent',
                                        zIndex: path === '/terms-and-conditions' ? 10 : -10
                                    }}
                                >
                                    <ArrowLeftOutlined /> Back
                                </Link>
                                <h2 style={{ fontWeight: "bold" }}>FAQs</h2>
                            </div>
                            <div className="about-us-details-content-container">
                                <div style={{ padding: "20px 60px" }}>
                                    {FaqArray?.map((item) => (
                                        <div style={{ color: "#1F1E1B", fontSize: 16, marginBottom: 10, display: "flex", alignItems: "center", lineHeight: 1.5 }}><span style={{ color: "#BB0A00", fontSize: 24, marginRight: 15 }}> • </span>{item}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        { path !== '/terms-and-conditions' ? 
                            <div className="homeSidebar-about-us-details">
                                <img
                                    className="homesideBarConatainer-about-us-details"
                                    src={sideBar}
                                />
                            </div> :
                            <div className="homeLoad-about-us-details">
                                <img className="loadCircle-about-us-details" src={loadCircle} alt="No image" loading="lazy" />
                            </div>
                        }
                        {/* <div className="homeLoad-about-us-details">
                            <img className="loadCircle-about-us-details" src={loadCircle} alt="No image" loading="lazy" />
                        </div> */}
                    </div>
                </Element>)
                :
                <Element className="section-about-us-details1">
                    {
                        path === '/terms-and-conditions' ? 
                        <div className="aboutMainConatiner" style={{  paddingLeft: 20 }}>
                            <div className='mbaboutunlabel'>
                                <Link to="/">
                                    <ArrowLeftOutlined /> Back
                                </Link>
                            </div>
                        </div> : null
                    }
                    
                    <div className="about-us-details-heading" style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", lineHeight: 1.3, marginTop: -5 }} >
                        <h2 style={{ fontSize: 24, fontWeight: "bold" }}>FAQs</h2>
                    </div>
                    <div className="about-us-details-content-container">
                        <div style={{ padding: "-10px 20px 10px 20px" }}>
                            {FaqArray?.map((item) => (
                                <div style={{ color: "#1F1E1B", fontSize: 16, marginBottom: 10, display: "flex", lineHeight: 1.5, marginLeft:20, marginRight:20 }}><span style={{ color: "#BB0A00", fontSize: 24, marginTop: -5, marginRight: 8 }}> • </span>{item}</div>
                            ))}
                        </div>
                    </div>
                </Element>
            }
            <div>{width > 600 ? <Footer /> : <MobileFooter />}</div>
        </div>
    )
}

export default TermsAndConditions