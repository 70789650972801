import React from "react";
import "./Style.css";
import donationsidebarimges from "../assets/images/sideBarDesign.svg";
import donationSideBarContainerimg from "../assets/images/Loader1.svg";
import donationTitleDesign from "../assets/images/homeFooterDesign.svg";
import donateIcon from "../assets/images/donateIcon.svg";
import useWindowDimensions from "../common/UseWindowDimensions";
import "./donationStyle.css";
import mbdntdesign from "../assets/images/homeMbTB.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
function Donation() {
  let location = useLocation();
  const { height, width } = useWindowDimensions();
  const handleDonate = () => {
    console.log("clicked on donate");
  };
  return (
    <div>
      {width > 600 ? (
        <div>
          <div className={
            location.pathname === "/"
              ? "membersMainContainer"
              : "singlemembersMainContainer"
          }>
            <div className="homeLoad1">
              <img className="loadCircle1" src={donationSideBarContainerimg} />
            </div>
            <div className="memberContent" >
              <div className="donationTitle" style={{marginTop:"1rem"}}>
                <h1>Donations</h1>
                <p>
                  Please Fill The Form If You Would Like To Contribute For Daily
                  Puja, Purohit Dokhina, Specific Puja Contribution, Daily Bhog
                  Or Any Specific Contribution.
                </p>
              </div>
              <div className="donationContentContainer">
                <img src={donationTitleDesign} />
              </div>
              <div className="donationimgContainer">
                <img src={donateIcon} />
              </div>
              <div className="btn-content">
                <Link to="/donation-details">
                  <button >Donate</button>
                </Link>
              </div>
            </div>
            <div className="membersSiderDesign">
              <img
                className="memberSideBarContainerimg"
                src={donationsidebarimges}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={
              location.pathname === "/"
                ? "mbmembersMainContainer"
                : "singlembmembersMainContainer"
            }
          >
            <div className="mbSideBarDesign">
              <img src={donationsidebarimges} />
            </div>
            {/* <div className="homeLoad1">
              <img className="loadCircle1" src={donationSideBarContainerimg} />
            </div> */}
            <div className="memberContent">
              <div className="mbdonationTitle">
                <h3>Donations</h3>
                <img src={mbdntdesign} />
                <p>
                  Please Fill The Form If You Would Like To Contribute For Daily
                  Puja, Purohit Dokhina, Specific Puja Contribution, Daily Bhog
                  Or Any Specific Contribution.
                </p>
              </div>
              <div className="donationContentContainer">
                <img src={donationTitleDesign} />
              </div>
              <div className="mbdonationimgContainer">
                <img src={donateIcon} />
              </div>
              <div className="mbbtn-content">
                <Link to="/donation-details">
                  <button>Donate</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
}
export default Donation;
