import React, { useState } from "react";
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import { Element } from "react-scroll";
import { ArrowLeftOutlined } from "@ant-design/icons";
import assamSingleDanceIcon from "../assets/images/assamSingleDanceIcon.svg";
import assamGrpDanceIcon from "../assets/images/assamGrpDanceIcon.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import designIcon from "../assets/images/homeFooterDesign.svg";
import photoLayer from "../assets/images/memberLayer.svg";
import bhapuliRathgiriImage from "../assets/images/bhapuli_patgiri.jpeg";
import PulakeshBarmanlma from "../assets/images/Pulakesh_Barman.JPG";
import RinkuKalita from "../assets/images/Rinku_Kalita.jpeg";
import DipankarKalita from "../assets/images/Dipankar_Kalita.jpeg";
import shakiraPic from "../assets/images/Shakira.png";
import useWindowDimensions from "../common/UseWindowDimensions";
import MobileFooter from "../common/MobileFooter";
import Footer from "../common/Footer";
import AbhijitThapa from "../assets/images/subMemberPhoto/AbhijitThapa.jpeg";
import AstamBania from "../assets/images/subMemberPhoto/AstamBania.jpeg";
import BrazilChutia from "../assets/images/subMemberPhoto/BrazilChutia.jpeg";
import MayankAgarwall from "../assets/images/subMemberPhoto/MayankAgarwall.jpeg";
import sabirBhora from "../assets/images/subMemberPhoto/SabirBorah2.jpeg";
import AnjanDas from "../assets/images/sportsSubMem/AnjanDas2.jpg";
import DebashreeSengupta from "../assets/images/sportsSubMem/DebashreeSengupta.jpeg";
import DhrubaJyotiDas from "../assets/images/sportsSubMem/DhrubaJyotiDas.jpeg";
import HirakJyotiKakati from "../assets/images/sportsSubMem/HirakJyotiKakati.jpeg";
import PaulBora from "../assets/images/sportsSubMem/PaulBora.jpeg";

function MembersDetails() {
  const { height, width } = useWindowDimensions();

  const [members, setMembers] = useState([
    {
      id: 1,
      name: "Dipankar Kalita",
      role: "President",
      image: DipankarKalita,
    },
    { id: 2, name: "Rinku Kalita", role: "Vice President", image: RinkuKalita },
    {
      id: 3,
      name: "Pulakesh Barman",
      role: "General Secretary",
      image: PulakeshBarmanlma,
    },
    {
      id: 4,
      name: "Bhupali Patgiri",
      role: "Vice President",
      image: bhapuliRathgiriImage,
    },
    { id: 5, name: "Shakira Benazir", role: "Treasurer", image: shakiraPic },
  ]);

  const [secondSubMembers, setSecondSubMembers] = useState([
    {
      id: 1,
      name: "Sabir Borah",
      role: "Asst. General Secretary",
      image: sabirBhora,
    },
    {
      id: 2,
      name: "Mayank Agarwall",
      role: "Asst. General Secretary",
      image: MayankAgarwall,
    },
    {
      id: 3,
      name: "Abhijit Thapa",
      role: "Coordination Secretary",
      image: AbhijitThapa,
    },
    {
      id: 4,
      name: "Brazil Chutia",
      role: "Cultural Secretary",
      image: BrazilChutia,
    },
    {
      id: 5,
      name: "Astam Bania",
      role: "Social Welfare Secretary",
      image: AstamBania,
    },
  ]);
  const [sportsSubMem, setSportsSubMem] = useState([
    {
      id: 1,
      name: "Paul Bora",
      role: "Youth Welfare Secretary",
      image: PaulBora,
    },
    {
      id: 2,
      name: "Debashree Sengupta",
      role: "PR Secretary",
      image: DebashreeSengupta,
    },
    {
      id: 3,
      name: "Anjan Das",
      role: "Sport Secretary",
      image: AnjanDas,
    },
    {
      id: 4,
      name: "Dhruba Jyoti Das",
      role: "Ex Officio Member",
      image: DhrubaJyotiDas,
    },
    {
      id: 5,
      name: "Hirak Jyoti Kakati",
      role: "Ex Officio Member",
      image: HirakJyotiKakati,
    },
  ]);

  const [mblSubMembersPhoto, setMblSubMembersPhoto] = useState([
    {
      id: 1,
      name: "Sabir Borah",
      role: "Asst. General Secretary",
      image: sabirBhora,
    },
    {
      id: 2,
      name: "Mayank Agarwall",
      role: "Asst. General Secretary",
      image: MayankAgarwall,
    },
    {
      id: 3,
      name: "Abhijit Thapa",
      role: "Coordination Secretary",
      image: AbhijitThapa,
    },
    {
      id: 4,
      name: "Brazil Chutia",
      role: "Cultural Secretary",
      image: BrazilChutia,
    },
    {
      id: 5,
      name: "Astam Bania",
      role: "Social Welfare Secretary",
      image: AstamBania,
    },
    {
      id: 6,
      name: "Paul Bora",
      role: "Youth Welfare Secretary",
      image: PaulBora,
    },
    {
      id: 7,
      name: "Debashree Sengupta",
      role: "PR Secretary",
      image: DebashreeSengupta,
    },
    {
      id: 8,
      name: "Anjan Das",
      role: "Sport Secretary",
      image: AnjanDas,
    },
    {
      id: 9,
      name: "Dhruba Jyoti Das",
      role: "Ex Officio Member",
      image: DhrubaJyotiDas,
    },
    {
      id: 10,
      name: "Hirak Jyoti Kakati",
      role: "Ex Officio Member",
      image: HirakJyotiKakati,
    },
  ]);
  return (
    <div>
      <Element className="section-about-us-details">
        <div className="aboutMainConatiner" >
          {width > 600 ? (
            <div className="homeSidebar-about-us-details">
              <img
                className="homesideBarConatainer-about-us-details"
                src={sideBar}
                alt="No image"
                loading="lazy"
              />
            </div>
          ) : null}

          <div className="about-us-details-content1">
            {width > 600 ? (
              <div className="about-us-details-heading" style={{ marginTop: "2rem" }}>
                <Link to="/">
                  {" "}
                  <ArrowLeftOutlined /> Back
                </Link>

                <h2 style={{fontWeight:"bold"}}>Executive Members</h2>
              </div>
            ) : (
              <div className="mbmdlink">
                <Link to="/">
                  {" "}
                  <ArrowLeftOutlined /> Back
                </Link>
                <h2 className="mbme" style={{fontWeight:"bold"}}>Executive Members</h2>
              </div>
            )}

            <div className="about-us-details-designIcon">
              <img src={designIcon} alt="No image" loading="lazy" />
            </div>
            <div className="member-details-subheading">
              <h5>Office Bearers : 2023 - 2025</h5>
            </div>

            {width > 600 ? (
              <div className="memberGrp">
                {members.map((ele, i) => (
                  <div
                    key={i}
                    className={`photoLayer ${i % 2 == 0 ? "topImg" : "bottomImg"
                      }`}
                  >
                    <div className="picLayer">
                      <img
                        className="cicleImg"
                        src={ele?.image}
                        alt={ele.name || 'no image'}
                        loading="lazy"
                      />
                      <div className="personInfo">
                        <h5>{ele?.name}</h5>
                        <p> {ele?.role}</p>
                      </div>
                      <img
                        className="layerBg"
                        src={photoLayer}
                        alt="No image"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mbmultiimgss">
                <div className="mbhomeSidebar-about-us-details">
                  <img src={sideBar} alt="No image" loading="lazy" />
                </div>
                <div className="image-grid">
                  {members.map((img, index) => (
                    <div className={`item-${index + 1}`} key={index}>
                      <img
                        className="mbLayer"
                        src={photoLayer}
                        loading="lazy"
                      />
                      <img
                        className="mbcrclimgAll"
                        src={img.image}
                        alt={`Image ${index + 1}`}
                        loading="lazy"
                      />

                      <div className="personInfo">
                        <h5>{img?.name}</h5>
                        <p> {img?.role}</p>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            )}
          </div>
          {width > 600 ? (
            <div className="homeLoad-about-us-details">
              <img className="loadCircle-about-us-details" src={loadCircle} />
            </div>
          ) : null}
        </div>
      </Element>

      <Element className="member-details2-conatiner">
        <div className="aboutMainConatiner">
          {width > 600 ? (
            <div className="homeLoad1">
              <img className="loadCircle1-about-us-details" src={loadCircle} />
            </div>
          ) : null}

          <div>
            {/* <div className="members-sub-heading">
              <h2>Members</h2>
            </div> */}
            <div>
              {/* {width > 600 ? (
                <div className="members-details-designIcon">
                  <img src={designIcon} />
                </div>
              ) : (
                <div className="mbmembers-details-designIcon">
                  <img src={designIcon} />
                </div>
              )} */}
            </div>
            {/* <div className="member-details-subheading">
              <h5>Office Bearers : 2023 - 2025</h5>
            </div> */}

            {width > 600 ? (
              <div className="memberGrp" >
                {secondSubMembers.map((ele, i) => (
                  <div
                    key={i}
                    className={`photoLayer ${i % 2 == 0 ? "topImg" : "bottomImg"
                      }`}
                  >
                    <div className="picLayer">
                      <img
                        className="cicleImg"
                        src={ele?.image}
                        alt={ele.name || 'No image'}
                        loading="lazy"
                      />
                      <div className="personInfo">
                        <h5>{ele?.name}</h5>
                        <p> {ele?.role}</p>
                      </div>
                      <img
                        className="layerBg"
                        src={photoLayer}
                        alt="No image"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mbmultiimgss">
                <div className="mbsubMemberImg">
                  {mblSubMembersPhoto.map((img, index) => (
                    <div className={`mbitems-${index + 1}`} key={index}>
                      <img
                        className="mbLayerSubMem"
                        src={photoLayer}
                        loading="lazy"
                      />
                      <img
                        className="mbcrclimgAllSubMem"
                        src={img.image}
                        alt={`Image ${index + 1}`}
                        loading="lazy"
                      />
                      <div className="mbpersonInfo">
                        <h5>{img?.name}</h5>
                        <p> {img?.role}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="mbhomeSidebar-about-us-details">
                  <img src={sideBar} alt="No image" loading="lazy" />
                </div> */}
              </div>
            )}
          </div>

          {width > 600 ? (
            <div className="membersSiderDesign" >
              <img
                className="memberSideBarContainerimg-about-us-details"
                src={sideBar}
              />
            </div>
          ) : null}
        </div>
      </Element>

      {width > 600 ? (
        <Element className="section-about-us-details0">
          <div className="aboutMainConatiner">
            <div className="homeSidebar-about-us-details">
              <img
                className="homesideBarConatainer-about-us-details"
                src={sideBar}
              />
            </div>

            <div className="about-us-details-content1" style={{ marginTop: "-12rem" }}>
              <div className="memberGrp">
                {sportsSubMem.map((ele, i) => (
                  <div
                    key={i}
                    className={`photoLayer ${i % 2 == 0 ? "topImg" : "bottomImg"
                      }`}
                  >
                    <div className="picLayer">
                      <img
                        className="cicleImg"
                        src={ele?.image}
                        alt={ele.name || 'No image'}
                        loading="lazy"
                      />
                      <div className="personInfo">
                        <h5>{ele?.name}</h5>
                        <p> {ele?.role}</p>
                      </div>
                      <img
                        className="layerBg"
                        src={photoLayer}
                        alt="No image"
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="members-details-designIcon2">
                <img src={designIcon} />
              </div>

              <div className="member-details-name">
                <h3>General Body Members 2023 - 2025</h3>
                <h3> Subhash Dey </h3>
                <h5>Asst. Sport Secretary</h5>
                <h3>Bhargav Kaushik</h3>
                <h3>Shehnaz Ashrak</h3>
                <h5>Asst. Cultural Secretary</h5>
                <h3>Bedabrata Bharali</h3>
                <h5>Asst. Coordination Secretary</h5>
              </div>
            </div>

            <div className="homeLoad-about-us-details">
              <img className="loadCircle-about-us-details" src={loadCircle} />
            </div>
          </div>
        </Element>
      ) : (
        <Element className="section-about-us-details0">
          <div>
            <div className="mbmembers-details-designIcon2">
              <img src={designIcon} />
            </div>
            <div className="mbMemberSportsList2">
              <div>
                <h3>General Body Members 2023 - 2025</h3>
                <h3> Subhash Dey </h3>
                <h5>Asst. Sport Secretary</h5>
                <h3>Bhargav Kaushik</h3>
                <h3>Shehnaz Ashrak</h3>
                <h5>Asst. Cultural Secretary</h5>
                <h3>Bedabrata Bharali</h3>
                <h5>Asst. Coordination Secretary</h5>
              </div>
            </div>
          </div>
        </Element>
      )}

      {width > 600 ? (
        <Element className="member-details2-conatiner4">
          <div className="aboutMainConatiner">
            <div className="homeLoad1">
              <img className="loadCircle1-about-us-details" src={loadCircle} />
            </div>

            <div>
              <div className="member-details-name">
                <h3>Mayank Agarwal</h3>
                <h5>Asst. Treasurer Secretary</h5>
                <h3>Maznur Bhuyan</h3>
                <h5>Asst. Youth Welfare Secretary</h5>
                <h3>Madhurjya Handique</h3>
                <h3>Deba Jyoti Borah</h3>
                <h5>Asst. Social Welfare Secretary</h5>
                <h3>Nabajit Das</h3>
                <h5>ASOB IT Member</h5>
                <h3>Anupam Bordoloi</h3>
                <h3>Mananashrita Pinzee</h3>
                <h3>Uddipta</h3>
                <h5>ASOB Creative Members</h5>
              </div>
            </div>

            <div className="membersSiderDesign">
              <img
                className="memberSideBarContainerimg-about-us-details"
                src={sideBar}
              />
            </div>
          </div>
        </Element>
      ) : (
        <Element className="member-details2-conatiner4">
          <div className="mbMember-detailsList0">
            <div>
              <h3>Mayank Agarwal</h3>
              <h5>Asst. Treasurer Secretary</h5>
              <h3>Maznur Bhuyan</h3>
              <h5>Asst. Youth Welfare Secretary</h5>
              <h3>Madhurjya Handique</h3>
              <h3>Deba Jyoti Borah</h3>
              <h5>Asst. Social Welfare Secretary</h5>
              <h3>Nabajit Das</h3>
              <h5>ASOB IT Member</h5>
              <h3>Anupam Bordoloi</h3>
              <h3>Mananashrita Pinzee</h3>
              <h3>Uddipta</h3>
              <h5>ASOB Creative Members</h5>
            </div>
          </div>
        </Element>
      )}

      {width > 600 ? (
        <Element className="section-about-us-details3">
          <div className="aboutMainConatiner">
            <div className="homeSidebar-about-us-details">
              <img
                className="homesideBarConatainer-about-us-details"
                src={sideBar}
              />
            </div>

            <div className="about-us-details-content1">
              <div className="member-details-name">
                <h3>Chandrashree Medhi</h3>
                <h3>Mrityunjoy Talukdar</h3>
                <h3>Ananya Sharma</h3>
                <h5>ASOB Literature Wing Members</h5>
                <h3>Jiyanta Sarmah</h3>
                <h5>Student Wing Lead</h5>
                <h3>Dhruba Hazarika</h3>
                <h3>Mintu Nath</h3>
                <h3>Ron</h3>
                <h5>Emergency Task force Members</h5>
              </div>

              <div className="about-us-details-designIcon">
                {" "}
                <img src={designIcon} />{" "}
              </div>

              <div className="member-details-name">
                <h3> Prabahan </h3>
                <h3>Mridusmita Changkakoti </h3>
                <h3>Gopal Lama </h3>
                <h3>Little Handique </h3>
                <h3> Neal Das </h3>
                <h3> Debajit Sarma </h3>
                <h3>Lilly Khanam </h3>
                <h5>Members</h5>
              </div>
            </div>

            <div className="homeLoad-about-us-details">
              <img className="loadCircle-about-us-details" src={loadCircle} />
            </div>
          </div>
        </Element>
      ) : (
        <Element className="section-about-us-details3">
          <div className="mbMembers-SubList1">
            <div>
              <h3>Chandrashree Medhi</h3>
              <h3>Mrityunjoy Talukdar</h3>
              <h3>Ananya Sharma</h3>
              <h5>ASOB Literature Wing Members</h5>
              <h3>Jiyanta Sarmah</h3>
              <h5>Student Wing Lead</h5>
              <h3>Dhruba Hazarika</h3>
              <h3>Mintu Nath</h3>
              <h3>Ron</h3>
              <h5>Emergency Task force Members</h5>
            </div>
            <div className="about-us-details-designIcon">
              {" "}
              <img src={designIcon} />{" "}
            </div>
            <div className="mbMembers-SubList1">
              <div>
                <h3> Prabahan </h3>
                <h3>Mridusmita Changkakoti </h3>
                <h3>Gopal Lama </h3>
                <h3>Little Handique </h3>
                <h3> Neal Das </h3>
                <h3> Debajit Sarma </h3>
                <h3>Lilly Khanam </h3>
                <h5>Members</h5>
              </div>
            </div>
          </div>
        </Element>
      )}

      {width > 600 ? <Footer /> : <MobileFooter />}
    </div>
  );
}

export default MembersDetails;
