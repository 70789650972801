import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./singleEvent.css";
import { useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import mbLRimg from "../assets/images/mbLR.svg";
import mbTBImg from "../assets/images/homeMbTB.svg";
import { eventsInfo } from "../common/eventssInfo";
import tbimg from "../assets/images/mbsetb.png";
import lenImg from "../assets/images/mblongimg.svg";
import MobileFooter from "../common/MobileFooter";
import mbLocationimg from "../assets/images/mapImage.png";
import ModalListView from "./ModalListView";
// import CommingSoon from "../common/CommingSoonTxt"
import CommingSoon from "../common/commingSoonTxt";

function MobleSingleEvent() {
  const location = useLocation();
  let eventData = location?.state?.data;
  const [singleEventData, setSingleEventData] = useState([]);
  const [isModalOpens, setIsModalOpens] = useState(false);

  const showModals = () => {
    setIsModalOpens(true);
  };
  const handleOk = () => {
    setIsModalOpens(false);
  };
  const handleCancels = () => {
    setIsModalOpens(false);
  };

  const LiestViewEventDetails = [
    {
      heading: "Shashthi",
      date: "20th Oct, Fri, 2023",
      scheduleTimings: [
        { timing: "08:30 AM", PujaName: "Shashthi Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "06:00 PM", PujaName: "Durga Devi Bodhan" },
        { timing: "06:30 PM", PujaName: "Amantran & Adhivas" },
      ],
    },
    {
      heading: "Maha Saptami",
      date: "21st Oct, Sat, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Samptami Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },

    {
      heading: "Maha Asthami",
      date: "22nd Oct, Sun, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Asthami Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "04:53 PM", PujaName: "Sandhi Puja" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },

    {
      heading: "Maha Navami",
      date: "23rd Oct, Mon, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Navami Puja & Hoom" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "04:30 PM", PujaName: "Kumari Puja" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },
    {
      heading: "Vijaya Dashami",
      date: "24th Oct, Tue, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Dashami Puja" },
        { timing: "10:30 AM", PujaName: "Sindoor Khela" },
        { timing: "12:41 PM", PujaName: "Devi Visarjan" },
      ],
    },
  ];

  useEffect(() => {
    setSingleEventData([]);
    const viewSelected = eventsInfo.filter(
      (ele) => ele.id == eventData.eventId
    );
    setSingleEventData((old) => [...old, ...viewSelected]);
  }, []);

  const tst = () => {
    console.log("singleEventData", singleEventData);
  };
  console.log("eventVal from URL parameter:", location?.state?.data);
  const navigate = useNavigate();

  return (
    <div>
      <div
        className={
          location.pathname === "/" ? "mbSEContaineraa" : "singlembSEContainer"
        }
      >
        <div className="mbEventInfo" style={{ marginTop: -10 }}>
          <div className="mbeventsite">
            <img src={mbLRimg} />
          </div>
          <div className="mbEI" >
            <div className="mbevsite">
              <Link to="/">
                <p>
                  <ArrowLeftOutlined />
                  Back
                </p>
              </Link>
              <h3 style={{ fontWeight: "bold" }}>Events</h3>
            </div>
            <img src={mbTBImg} />
            <img className="mbeventLogo" src={eventData?.img} />
            <img className="mbTB" src={tbimg} />
            <img className="mbETxt" src={singleEventData[0]?.eventText} />
          </div>
        </div>
        <div className="mbEventInfoSeond">
          <div className="mbSecondCol">
            <img className="secondmbTB" src={tbimg} />
            <h3 className="mbename">{singleEventData[0]?.event}</h3>
            <p className="mbvenue">
              <span>Venue:</span>
              {singleEventData[0]?.venue}{" "}
            </p>
            <p className="mbvenue">
              <span>Date:</span>
              {singleEventData[0]?.Date}{" "}
            </p>
            <img className="mblgtb" src={lenImg} />
            {/* {singleEventData[0]?.id == "BohagBihu2024" ? <div className="book-tickets" style={{ marginTop: 20 }} onClick={() => { window.open("https://asobspringfest.in/events/23rdspringfest/") }}>Book Passes</div> : <></>} */}
            <div>
              {singleEventData[0]?.id == "duraPujaCelebration2023" ? (
                <a onClick={showModals} className="mbLinkMdl">
                  {" "}
                  View Schedule
                </a>
              ) : null}
              {/* <a onClick={showModals}  className="mbLinkMdl" > View Schedule</a> */}
            </div>
          </div>

          <div className="mbLinkMdldiv">
            <ModalListView
              showModals={showModals}
              isModalOpens={isModalOpens}
              LiestViewEventDetails={LiestViewEventDetails}
              handleCancels={handleCancels}
            />
          </div>
          <div>
            <img src={mbLRimg} />
          </div>
        </div>
        {singleEventData[0]?.imageFilenames == undefined &&
          singleEventData[0]?.isLocation == true && (
            <div className="mbEventInfo">
              <div>
                <img src={mbLRimg} />
              </div>
              <div className="mbEIMap">
                <img src={mbLocationimg} />
              </div>
            </div>
          )}
        {singleEventData[0]?.imageFilenames == undefined &&
          singleEventData[0]?.isLocation == undefined &&
          singleEventData[0]?.isUpcoming == true && (
            <div className="comingsoon">
              {/* <h2>coming soon</h2> */}
              <CommingSoon />
            </div>
          )}
        {singleEventData[0]?.imageFilenames?.length >= 1 && (
          <div className="mbEventInfoThird">
            <div>
              <img src={mbLRimg} />
            </div>
            <div>
              <div className="mbimage-gallery">
                {singleEventData[0]?.imageFilenames?.map((filename, index) => (
                  <div className="mbimage-container-sub" key={index}>
                    <img className="mbtstEvnetImgggggg" src={filename.img} />
                  </div>
                ))}
              </div>
              <div className="gLink">
                <a href="/gallery-details">Go to Gallery</a>
              </div>
            </div>
          </div>
        )}
      </div>
      <MobileFooter />
    </div>
  );
}

export default MobleSingleEvent;
