import React, { useState, useEffect } from "react";
import "./Style.css";
import "./homeStyle.css";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";


import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import durationImg from "../assets/images/durationImg1.svg";
// import thrishula from "../assets/images/thrishulLogo.svg";
import thrishula from "../assets/images/thrisulaIcon.svg";
import webBanner1 from "../assets/images/webBanner1.jpg";
import hindiContent from "../assets/images/hindiContent.svg";
import homeFooterDesign from "../assets/images/homeFooterDesign.svg";
import moment from "moment";
import useWindowDimensions from "../common/UseWindowDimensions";
import mbTBImg from "../assets/images/homeMbTB.svg";
import mbLRimg from "../assets/images/mbLR.svg";
import mbDurgapujaImg from "../assets/images/durgaPuja.png";
import mbhomeFooterdesign from "../assets/images/homeFooterDesign.svg";
import mbhindhiText from "../assets/images/mbhindhiText.svg";
import dailyPoojaIcon from "../assets/images/dailyPujaIcon.svg";
import dailyArathiIcon from "../assets/images/dailyArathiIcon.svg";
import dailybohagIcon from "../assets/images/dailyBohogIcon.svg";
import cultureIcon from "../assets/images/cultureIcon.svg";
import foodStalIcon from "../assets/images/foodStalsIcon.svg";
import fleaMarketIcon from "../assets/images/fleaMarketIcon.svg";
import dandiyaDance from "../assets/images/dandiyaDanceIcon.svg";
import djNightIcon from "../assets/images/djNightIcon.svg";
import { Modal } from "antd";
import duraPujaCelebration2023 from "../assets/images/duraPujaCelebration.png";
import sideDesignlstView from "../assets/images/sideDesignlstView.svg";
import ModalListView from "./ModalListView";
import mobileBanner from "../assets/images/mobileBanner1.jpg"
import { Link } from "react-router-dom";

function Home() {

  const navigate = useNavigate();
  const futureDate = moment("07-Apr-2024", "DD-MMM-YYYY");
  const { height, width } = useWindowDimensions();

  const [countdown, setCountdown] = useState(calculateCountdown());
  const [isModalOpens, setIsModalOpens] = useState(false);

  const showModals = () => {
    setIsModalOpens(true);
  };
  const handleOk = () => {
    setIsModalOpens(false);
  };
  const handleCancels = () => {
    setIsModalOpens(false);
  };

  const imageIcons = [
    { image: dailyPoojaIcon, name: "Daily Puja" },
    { image: dailyArathiIcon, name: "Daily Aarti" },
    { image: dailybohagIcon, name: "Daily Bhog" },
    { image: cultureIcon, name: "Cultural’s" },
    { image: foodStalIcon, name: "Food Stalls" },
    { image: fleaMarketIcon, name: "Flea Market" },
    { image: dandiyaDance, name: "Dandiya Dance" },
    { image: djNightIcon, name: "DJ Night" },
  ];
  const LiestViewEventDetails = [
    {
      heading: "Shashthi",
      date: "20th Oct, Fri, 2023",
      scheduleTimings: [
        { timing: "08:30 AM", PujaName: "Shashthi Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "06:00 PM", PujaName: "Durga Devi Bodhan" },
        { timing: "06:30 PM", PujaName: "Amantran & Adhivas" },
      ],
    },
    {
      heading: "Maha Saptami",
      date: "21st Oct, Sat, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Samptami Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },

    {
      heading: "Maha Asthami",
      date: "22nd Oct, Sun, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Asthami Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "04:53 PM", PujaName: "Sandhi Puja" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },

    {
      heading: "Maha Navami",
      date: "23rd Oct, Mon, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Navami Puja & Hoom" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "04:30 PM", PujaName: "Kumari Puja" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },
    {
      heading: "Vijaya Dashami",
      date: "24th Oct, Tue, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Dashami Puja" },
        { timing: "10:30 AM", PujaName: "Sindoor Khela" },
        { timing: "12:41 PM", PujaName: "Devi Visarjan" },
      ],
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function calculateCountdown() {
    const currentDate = moment();
    const duration = moment.duration(futureDate.diff(currentDate));
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return { days, hours, minutes, seconds };
  }
  return (
    <div>
      {width > 600 ? (
        <div>
          <div className="mainHome">
            <div className="homeSidebar">
              <img className="homesideBarConatainer" src={sideBar} />
            </div>
            <div className="homeContent">
              <div className="homeInfo">
                <img src={webBanner1} />
              </div>
              <div className="duration">
                <div className="subduration">
                  <img className="durationLogos" src={durationImg} />
                  <div className="durationInfo">
                    <div className="durationEach">
                      {countdown.days}
                      <p>Days</p>
                    </div>
                    :
                    <div className="durationEach">
                      {countdown.hours}
                      <p>Hours</p>
                    </div>
                    :
                    <div className="durationEach">
                      {countdown.minutes}
                      <p>Minutes</p>
                    </div>
                    :
                    <div>
                      {countdown.seconds}
                      <p>Seconds</p>
                    </div>
                  </div>
                  <img className="durationLogos" src={durationImg} />
                  <div onClick={() => window.open('https://asobspringfest.in/events/23rdspringfest/')} className="book-tickets">Book Passes</div>
                </div>
              </div>
            </div>

            <div className="homeLoad">
              <img className="loadCircle" src={loadCircle} />
            </div>
          </div>

          <div className="addressConatiner">
            <div className="addressConatinerContent">
              <p className="tstP">
                We, the  <span> Assam Society of Bangalore</span>, take great pride in announcing that after the successful completion of our 22nd spring fest celebration (2022) in Bangalore, we all are set to celebrate{" "}
                <span>23rd spring fest. </span>
              </p>
              <p className="venueP">
                <span> Venue: </span>{" "}
                <em className="venue">
                  {" "}
                  Freedom International School Ground, HSR Layout{" "}
                </em>
              </p>
              <p className="venueP">
                <span> Date: </span>{" "}
                <em className="venue"> Sunday 7th April, 2024 </em>
              </p>
              <img src={homeFooterDesign} />
              <div style={{ marginTop: 20, font: " normal normal 600 24px/45px Open Sans", color: "#1F1E1B" }}>Guest Artist</div>
              <div style={{ font: " normal normal bold 24px/45px Open Sans", color: "#AF180F", marginBottom: 10 }}>Neel Akash</div>
              <img src={homeFooterDesign} />
              {/* <div>
                <div className="hmeIcnList">
                  {imageIcons.map((icons) => {
                    return (
                      <div className="hmeIconHlder">
                        <img src={icons.image} />
                        <p>{icons.name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="hmeViewListBtn">
                <button onClick={showModals}> View Schedule</button>
              </div> */}
            </div>
          </div>

          <div className="scheduleModule">
            <ModalListView
              showModals={showModals}
              isModalOpens={isModalOpens}
              LiestViewEventDetails={LiestViewEventDetails}
              handleCancels={handleCancels}
            />
          </div>
        </div>
      ) : (
        <div className="mbHomeContainer" style={{ marginTop: 80 }}>
          <div className="mbTopImg">
            <img src={mbTBImg} />
          </div>
          <div className="mbHomeTop">
            <div>
              <img className="homembL" src={mbLRimg} />
            </div>
            <div className="mbBGhome">
              {/* <img className="mbHomeLogo" src={mbDurgapujaImg} />
              <img className="mbTrishula" src={thrishula} /> */}
              <img src={mobileBanner} style={{ marginLeft: "5%", borderRadius: 8, width: "90%" }} />
              <div className="mbduration">
                <div className="mbsubduration">
                  <div className="mbdurationInfo">
                    <div>
                      {countdown.days}
                      <p>Days</p>
                    </div>
                    :
                    <div>
                      {countdown.hours}
                      <p>Hours</p>
                    </div>
                    :
                    <div>
                      {countdown.minutes}
                      <p>Minutes</p>
                    </div>
                    :
                    <div>
                      {countdown.seconds}
                      <p>Seconds</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 50 }}>
            <div className="book-tickets" onClick={() => { window.open("https://asobspringfest.in/events/23rdspringfest/") }}>Book Passes</div>
          </div>
          <div className="mbevent-details" style={{ marginTop: 10 }}>
            <div className="mbPuja-details">
              <img src={mbTBImg} />
              <p style={{ marginTop: 20, marginBottom: 15 }}>23rd spring fest, 2024 - ASOB</p>
              <img className="hindiContent" src={mbhindhiText} />
              <h3 style={{ marginTop: 15, marginBottom: 15 }}>Sunday 7th April, 2024</h3>

              <img src={mbTBImg} style={{ marginTop: 5 }} />
            </div>
            <div>
              <img className="mbSideBar2" src={mbLRimg} />
            </div>
          </div>
          <div className="mb-content-container">
            <div className="mbsidecontainer">
              <img src={mbLRimg} />
            </div>

            <div className="mb-puja-detials">
              <p className="mb-puja-detials-txt">
                We, the <span> Assam Society of Bangalore</span>, take great pride in announcing that after the successful completion of our 22nd spring fest celebration (2022) in Bangalore, we all are set to celebrate{" "}
                <span>23rd spring fest. </span>
              </p>
              <p className="mb-puja-detials-venuetxt">
                <span> Venue: </span>
                <b>Freedom International School Ground, HSR Layout </b>
              </p>
              <p className="mb-puja-detials-venuetxt">
                <span> Date: </span>
                <b>  Sunday 7th April, 2024 </b>
              </p>
              <div>
                <img src={homeFooterDesign} style={{ width: 80, marginTop: 10 }} />
                <div style={{ font: " normal normal 600 18px/45px Open Sans", color: "#1F1E1B" }}>Guest Artist</div>
                <div style={{ font: " normal normal bold 18px/45px Open Sans", color: "#AF180F" }}>Neel Akash</div>
                <img src={homeFooterDesign} style={{ width: 80, marginTop: 10 }} />
                {/* <div>
                  {imageIcons?.map((iconss)=>{
                    return(<div>
                      <img src={iconss.image}/>
                    </div>)
                  })}
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="mbIconCntner">
            <div className="mbIcnHoldermap">
              <div className="mbIcnHoldermap2">
              {imageIcons.map((iconss) => {
                return (
                  <div className="mbIconHolderImg">
                    <img src={iconss.image} />
                  </div>
                );
              })}
              </div>
       
              <div className="mblistViewbtn">
                <button onClick={showModals}> View Schedule</button>
              </div>
            </div>
            <div className="mbIcnHoldermap">
              <div className="mbIcnHoldermap2">
                {imageIcons.map((iconss) => {
                  return (
                    <div className="mbIconHolderImg">
                      <img src={iconss.image} />
                    </div>
                  );
                })}
              </div>
              <div className="mblistViewbtn">
                <button onClick={showModals}> View Schedule</button>
              </div>

            </div>

            <div className="mbSideBarHolder">
              <img src={sideBar} />
            </div>
          </div> */}
          <ModalListView
            showModals={showModals}
            isModalOpens={isModalOpens}
            LiestViewEventDetails={LiestViewEventDetails}
            handleCancels={handleCancels}
          />
        </div>
      )}
    </div>
  );
}

export default Home;


{/*  */ }
// ln 174 paste it 
