import React, { useState } from "react";
import "./Style.css";
import membersloadCircle from "../assets/images/Loader1.svg";
import sidebarimg from "../assets/images/sideBarDesign.svg";
import headerImg from "../assets/images/homeFooterDesign.svg";
import photoLayer from "../assets/images/memberLayer.svg";
import shakiraPic from "../assets/images/Shakira.png";
import bhapuliRathgiriImage from "../assets/images/bhapuli_patgiri.jpeg";
import PulakeshBarmanlma from "../assets/images/Pulakesh_Barman.JPG";
import RinkuKalita from "../assets/images/Rinku_Kalita.jpeg";
import DipankarKalita from "../assets/images/Dipankar_Kalita.jpeg";

import dhiraaj_talukdar from "../assets/images/dhiraaj_talukdar.jpeg";
import mohsin_ahmed from "../assets/images/mohsin_ahmed.jpeg";
import pranjal from "../assets/images/pranjal.jpeg";
import raj_deep_dey from "../assets/images/raj_deep_dey.jpeg";
import raja_laxmi from "../assets/images/raja_laxmi.jpeg";
import samarjit_ghosh from "../assets/images/samarjit_ghosh.jpeg";
import sangamitra from "../assets/images/sangamitra.jpeg";
import sanjib_kalita from "../assets/images/sanjib_kalita.jpeg";

import { useLocation } from "react-router-dom";
import useWindowDimensions from "../common/UseWindowDimensions";
import "./mbmembersstye.css";
import mblimg from "../assets/images/mblongimg.svg";
import mbLRimg from "../assets/images/mbLR.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";

function Members() {

  let location = useLocation();
  const { height, width } = useWindowDimensions();

  const [members, setMembers] = useState([
    {
      id: 1,
      name: "Dipankar Kalita",
      role: "President",
      image: DipankarKalita,
    },
    { id: 2, name: "Rinku Kalita", role: "Vice President", image: RinkuKalita },
    {
      id: 3,
      name: "Pulakesh Barman",
      role: "General Secretary",
      image: PulakeshBarmanlma,
    },
    {
      id: 4,
      name: "Bhupali Patgiri",
      role: "Vice President",
      image: bhapuliRathgiriImage,
    },
    { id: 5, name: "Shakira Benazir", role: "Treasurer", image: shakiraPic },
  ]);
  const [advisors, setAdvisors] = useState([
    {
      id: 1,
      name: "Sanjeeb Kalita",
      role: "President",
      image: sanjib_kalita,
    },
    { id: 2, name: "Raja Lakhsmi", role: "Vice President", image: raja_laxmi },
    {
      id: 3,
      name: "Rajdeep Dey",
      role: "General Secretary",
      image: raj_deep_dey,
    },
    {
      id: 4,
      name: "Mohsin Ahmed",
      role: "Vice President",
      image: mohsin_ahmed,
    },
    { id: 5, name: "Sanghamitra", role: "Treasurer", image: sangamitra },
    
  ]);

  const advisors2=[
    { id: 6, name: "Samarjit Ghosh", role: "Treasurer", image: samarjit_ghosh },
    { id: 7, name: "Pranjal", role: "Treasurer", image: pranjal },
    { id: 8, name: "Dhiraj Talukdar", role: "Treasurer", image: dhiraaj_talukdar },
  ]

  return (
    <div>
      <div>
        {width > 600 ? (
          <div>
            <div
              className={
                location.pathname === "/"
                  ? "membersMainContainer"
                  : "singleEventPage"
              }
            >
              <div className="homeLoad1">
                <img className="loadCircle1" src={membersloadCircle} />
              </div>
              <div className="memberContent">

              <div className="memberTitle" style={{marginTop:"2rem"}}>
                  <h1>Advisors body</h1>
                  <p>Members 2023-2025</p>
                  <div>
                    {" "}
                    <img src={headerImg} />
                  </div>
                </div>

              <div className="memberGrp" >
                  {advisors.map((ele, i) => (
                    <div
                      key={i}
                      className={`photoLayer ${i % 2 == 0 ? "topImg" : "bottomImg"
                        }`}
                    >
                      <div className="picLayer">
                      <div className="personInfo">
                          <h5>{ele?.name}</h5>
                          {/* <p> {ele?.role}</p> */}
                        </div>
                        <img
                          className="cicleImg"
                          src={ele?.image}
                          alt={ele.name}
                        />
                        {/* </div> */}
                       
                        <img className="layerBg" src={photoLayer} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="memberGrp" style={{marginTop:"12rem"}}>
                  {advisors2.map((ele, i) => (
                    <div
                      key={i}
                      className={`photoLayer ${i % 2 == 0 ? "topImg1" : "bottomImg1"
                        }`}
                    >
                      <div className="picLayer">
                      <div className="personInfo">
                          <h5 style={{ marginLeft:(i==0) ? 70 : i==2 ? 95 : ""}}>{ele?.name}</h5>
                          {/* <p> {ele?.role}</p> */}
                        </div>
                        <img
                          className="cicleImg"
                          src={ele?.image}
                          alt={ele.name}
                        />
                        {/* </div> */}
                       
                        <img className="layerBg" src={photoLayer} />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="memberTitle" >
                  <h1>Executive Members</h1>
                  <p>Office Bearers : 2023 - 2025</p>
                  <div>
                    {" "}
                    <img src={headerImg} />
                  </div>
                </div>

                <div className="memberGrp" >
                  {members.map((ele, i) => (
                    <div
                      key={i}
                      className={`photoLayer ${i % 2 == 0 ? "topImg" : "bottomImg"
                        }`}
                    >
                      <div className="picLayer">
                        <img
                          className="cicleImg"
                          src={ele?.image}
                          alt={ele.name}
                        />
                        {/* </div> */}
                        <div className="personInfo">
                          <h5>{ele?.name}</h5>
                          <p> {ele?.role}</p>
                        </div>
                        <img className="layerBg" src={photoLayer} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="btn-content">
                  <Link to="/members-details">
                    <button>View All</button>
                  </Link>
                </div>
              </div>
              <div className="membersSiderDesign">
                <img className="memberSideBarContainerimg" src={sidebarimg} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={
                location.pathname === "/" ? "mbmemcont" : "singlembmemcont"
              }
            >

<h3 className="mblabel" style={{fontWeight:"bold"}}>Advisors body</h3>
              <h4>Members 2023-2025</h4>
              <img src={mblimg} />

              <div className="mbContainerimgs">
                <div className="mkrlcol">
                  <img src={mbLRimg} />
                </div>
                <div style={{display:"flex",flexDirection:"column"}}>
                <div className="mbmultiimgss">
                  <div className="image-grid" >
                    {advisors.map((img, index) => (
                      <div className={`item-${index + 1}`} key={index} style={{marginBottom:"1rem"}}>
                        <img className="mbLayer" src={photoLayer} />
                        <img
                          className="mbcrclimg"
                          src={img.image}
                          alt={`Image ${index + 1}`}
                        />
                        <div className="personInfo">
                          <h5 style={{marginTop:"1rem"}}>{img?.name}</h5>
                          {/* <p> {img?.role}</p> */}
                        </div>
                      </div>
                    ))}
                  </div>
                  
                </div>

                    
                <div className="mbmultiimgss">
                  <div className="image-grid">
                    {advisors2.map((img, index) => (
                      <div className={`item-${index + 1}`} key={index} style={{marginBottom:"1rem"}}>
                        <img className="mbLayer" src={photoLayer} />
                        <img
                          className="mbcrclimg"
                          src={img.image}
                          alt={`Image ${index + 1}`}
                        />
                        <div className="personInfo">
                          <h5 style={{marginTop:"1rem"}}>{img?.name}</h5>
                          {/* <p> {img?.role}</p> */}
                        </div>
                      </div>
                    ))}
                  </div>
                  
                </div>
                </div>
                
              </div>


              <h3 className="mblabel" style={{marginTop:"2rem"}}>Executive Members</h3>
              <h4>Office Bearers: 2023-2025</h4>
              <img src={mblimg} />

              <div className="mbContainerimgs">
                <div className="mkrlcol">
                  <img src={mbLRimg} />
                </div>
                <div className="mbmultiimgss">
                  <div className="image-grid">
                    {members.map((img, index) => (
                      <div className={`item-${index + 1}`} key={index}>
                        <img className="mbLayer" src={photoLayer} />
                        <img
                          className={index== 0 ? "mbcrclimg2" : "mbcrclimg1"}
                          src={img.image}
                          alt={`Image ${index + 1}`}
                        />
                        <div className="personInfo">
                          <h5>{img?.name}</h5>
                          <p> {img?.role}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>


              <div className="mbviewall">
                <Link to="/members-details">
                  <button >View All</button>
                </Link>
              </div>
              <div className="mbend">
                <img src={mblimg} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Members;
