import React from "react";
import { Modal } from "antd";
import useWindowDimensions from "../common/UseWindowDimensions";
import duraPujaCelebration2023 from "../assets/images/duraPujaCelebration.png";
import sideDesignlstView from "../assets/images/sideDesignlstView.svg";
import mbTBImg from "../assets/images/homeMbTB.svg";

import "./Style.css";
import "./homeStyle.css";

function ModalListView({ LiestViewEventDetails, showModals, isModalOpens, handleCancels }) {
  const { height, width } = useWindowDimensions();
  return <div>{width > 600 ? (<div>
    <Modal
      closable={true}
      header={null}
      footer={null}
      width="80%"
      open={isModalOpens}
      onCancel={handleCancels}
    >
      <div>
        <div className="hmelstvwContainer">
          <img className="hmeViewConatiner" src={sideDesignlstView} />
          <img src={duraPujaCelebration2023} />
          <img className="hmeViewConatiner" src={sideDesignlstView} />
        </div>

        <div className="listViewInfoCnt">
          <img className="hmeViewConatiner" src={sideDesignlstView} />
          <div className="hmeViewCntHolder">
            {LiestViewEventDetails.map((ele) => (
              <div className="schcontainer">
                <div className="scchheader">
                  <h3>{ele.heading}</h3>
                  <p>{ele.date}</p>
                </div>
                <div className="subschevnat">
                  {ele.scheduleTimings.map((item) => (
                    <p>
                      {item.timing} <span>{item.PujaName}</span>
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <img className="hmeViewConatiner" src={sideDesignlstView} />
        </div>

        <div className="listViewVenue">
          <p>
            {" "}
            <span>Venue:</span> Freedom International School Ground, HSR
            Layout
          </p>
        </div>
      </div>
    </Modal>
  </div>
  ) : (
    <div>
      <Modal
        bodyStyle={{ height: "70vh" }}
        open={isModalOpens}
        onCancel={handleCancels}
        closable={true}
        header={null}
        footer={null}
      >
        <div>
          <div className="mbmdlDesignCnt">
            <img src={mbTBImg} />
          </div>
          <div className="mbmdlListCont">
            <img src={duraPujaCelebration2023} />
            <h4>11th Shree Shree Durga Puja 2023 - ASOB</h4>
            <h5>
              <span>Venue:</span> Freedom International School Ground, HSR
              Layout
            </h5>
            <div className="mbMapCntHlder">
              {LiestViewEventDetails?.map((item) => {
                return (
                  <div>
                    <div className="mbMapCntHlderhdr">
                      <h3>{item.heading}</h3>
                      <p>{item.date}</p>
                    </div>
                    <div className="mbMapDetilHlder">
                      {item.scheduleTimings.map((items) => (
                        <p>
                          {items.timing} <span>{items.PujaName}</span>
                        </p>
                      ))}
                    </div>
                    <div className="mbmdlDesignCnt">
                      <img src={mbTBImg} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </div>)}
  </div>;
}

export default ModalListView;
