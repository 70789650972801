import React, { useState, useEffect } from 'react'
import useWindowDimensions from "../common/UseWindowDimensions";
import { ArrowLeftOutlined } from '@ant-design/icons';
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import { Button, Input, Result, Spin, Steps } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../common/baseUrl';
import { generatePaymentRequest } from './paymentGateway';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import { setFormValues } from '../action/asob';
import { useMemo } from 'react';
import './bookTickets.css'
import html2canvas from 'html2canvas';

import TicketImage from '../assets/images/tickets/ticket image.svg'
import SelectedTicket from '../assets/images/tickets/selected ticket.svg'
import SecureLockIcon from '../assets/images/tickets/securelock icon.svg'
import TermsAndConditions from './termsAndConditions';
import { FaqArray } from './termsAndConditions';

const bodyStyle = {
    background: "#FFFBF8",
    width: '100%',
    height: "calc(100% + 40px)",
    overflow: "hidden"
}

const BookTickets = ({ setFormValues, formValues }) => {

    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const urlParams = new URLSearchParams(window.location.search);
    const orderIdFromUrl = urlParams.get('orderId');
    const nameFromUrl = urlParams.get('name');
    const emailFromUrl = urlParams.get('email');
    const numberFromUrl = urlParams.get('phone');
    const generalTicketsFromUrl = urlParams.get('generalTicket');
    const corporateTicketsFromUrl = urlParams.get('corporateTicket');
    const generalTicketCostFromUrl = urlParams.get('generalTicketAmount');
    const corporateTicketCostFromUrl = urlParams.get('regularTicketAmount');
    const taxFromUrl = urlParams.get('tax');
    const totalAmountFromUrl = urlParams.get('total');
    const uniqueSerialNumber = urlParams.get('serial');

    const [currentView, setCurrentView] = useState(orderIdFromUrl ? 3 : 1)
    const [ticketCounts, setTicketCounts] = useState({ corporate: 0, general: 0 })
    const [loading, setLoading] = useState(false);
    const [passesSerials, setPassesSerials] = useState(null)
    const [postPaymentRes, setPostPaymentRes] = useState(null);
    const [remainingPasses, setRemainingPasses] = useState(null)
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: ''
    });

    useEffect(() => {
        if (orderIdFromUrl) {
            const fetchData = async () => {
                const url = `/user/post-payment/?orderId=${orderIdFromUrl}&name=${nameFromUrl}&email=${emailFromUrl}&phone=${numberFromUrl}&generalTicket=${generalTicketsFromUrl}&corporateTicket=${corporateTicketsFromUrl}&generalTicketAmount=${generalTicketCostFromUrl}&regularTicketAmount=${corporateTicketCostFromUrl}&tax=${taxFromUrl}&total=${totalAmountFromUrl}&isTest=${true}`;
                try {
                    const response = await axios.get(`${baseUrl}${url}`);
                    // console.log(response, 'response'); // Assuming you want to log the response
                    setPostPaymentRes(response?.data?.body);
                    setPassesSerials({
                        eTickets: response?.data?.eTickets,
                        cTickets: response?.data?.cTickets
                    });

                } catch (error) {
                    console.error('Error occurred:', error);
                }
            };
            fetchData();
        } else {
            // DO NOTHING!
        }
    }, [orderIdFromUrl]);

    useEffect(() => {
        if (currentView == 1) {

            const fetchRemainingPasses = async () => {
                const url = `/user/ticket-count/?isTest=${true}`;
                try {
                    const response = await axios.get(`${baseUrl}${url}`);
                    // console.log(response, 'response'); // Assuming you want to log the response
                    setRemainingPasses(response?.data)
                } catch (error) {
                    console.error('Error occurred:', error);
                }
            };
            fetchRemainingPasses();
        }
    }, [])

    const calculateTotalTicketsLeft = () => {
        if (!remainingPasses || Object.keys(remainingPasses).length === 0) {
            return { remainingCorporateTickets: 0, remainingGeneralTickets: 0 }; // Assuming no data or remainingPasses is empty
        }

        const { economyCount, corporateCount } = remainingPasses;
        const totalTickets = 200; // Assuming we have 200 tickets initially
        const remainingGeneralTickets = totalTickets - economyCount > 0 ? totalTickets - economyCount : 0;
        const remainingCorporateTickets = totalTickets - corporateCount > 0 ? totalTickets - corporateCount : 0;

        return { remainingCorporateTickets, remainingGeneralTickets };
    };

    const totalRemainingTickets = calculateTotalTicketsLeft();

    const handleInputChange = (fieldName) => (e) => {
        const inputValue = e.target.value;

        // Check if the entered value exceeds 10 digits
        if (fieldName === 'phoneNumber' && inputValue.length > 10) {
            return; // Prevent updating state if more than 10 digits
        }

        setFormData({
            ...formData,
            [fieldName]: inputValue
        });

        setFormValues(fieldName, inputValue);
    };

    const handleCountUpdate = (type, action) => {
        // Calculate total count of tickets
        const totalCount = ticketCounts.corporate + ticketCounts.general;

        // Check if total count exceeds 10 for increment action
        if (action === 'increment' && totalCount >= 10) {
            // Alert or display a message to the user indicating that they cannot select more than 10 tickets
            alert("Maximum 10 tickets per order");
            return;
        }

        setTicketCounts(prevCounts => {
            const updatedCounts = { ...prevCounts };
            if (action === 'increment') {
                updatedCounts[type] += 1;
            } else if (action === 'decrement' && updatedCounts[type] > 0) {
                updatedCounts[type] -= 1;
            }

            setFormValues('ticketCounts', updatedCounts)
            return updatedCounts;
        });
    };

    const captureAndSaveAsImage = () => {
        const elementToCapture = document.getElementById('contentToCapture');
        html2canvas(elementToCapture).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.download = 'capture.png'; // File name for the captured image
            link.href = imgData;
            link.click();
        });
    };

    const ticketPrices = {
        general: 500,
        corporate: 850
    };

    const calculateTotalCost = useMemo(() => {
        return () => {
            const generalCost = ticketCounts?.general * ticketPrices?.general;
            const corporateCost = ticketCounts?.corporate * ticketPrices?.corporate;
            return { general: generalCost, corporate: corporateCost };
        };
    }, [ticketCounts, ticketPrices]);

    const totalCosts = calculateTotalCost();

    const handleConfirm = () => {
        // Destructure the formData object to get the values of name, phoneNumber, and email
        const { name, phoneNumber, email } = formData;

        // Regular expression patterns for phone number and email validation
        const phoneRegex = /^[0-9]{10}$/ // Accepts exactly 10 digits
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation

        // Check if any of the fields are empty
        if (!name?.trim() || !phoneNumber?.trim() || !email?.trim()) {
            alert('Please fill in all the required fields.');
            return;
        }

        // Check if phone number is valid
        if (!phoneRegex.test(phoneNumber)) {
            alert('Please enter a valid 10-digit phone number.');
            return;
        }

        // Check if email is valid
        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address.');
            return;
        }

        setCurrentView(2)
    };

    function generateSerialNumber() {
        let randomNumber = Math.floor(Math.random() * 1000000);
        let serialNumber = randomNumber.toString();
        while (serialNumber.length < 6) {
            serialNumber = '0' + serialNumber;
        }
        return serialNumber;
    }

    let serialNumber; // Declare serialNumber outside the block

    // Check the condition
    if (currentView == 3) {
        // Function is conditionally executed only if currentView is 2
        const serialCode = generateSerialNumber();
        serialNumber = serialCode; // Assign serialCode to serialNumber
    } else {
        serialNumber = ''; // Assign an empty string if currentView is not 2
    }


    const customerName = formData?.name;
    const customerEmail = formData?.email;
    const customerPhoneNumber = formData?.phoneNumber;
    const generalTicketCount = ticketCounts?.general;
    const corporateTicketCount = ticketCounts?.corporate;
    const generalTicketCost = ticketCounts?.general * ticketPrices?.general;
    const corporateTicketCost = ticketCounts?.corporate * ticketPrices?.corporate;
    const taxPayable = ((totalCosts?.general + totalCosts?.corporate) * 0.02).toFixed(2);
    const totalAmount = (totalCosts?.general + totalCosts?.corporate + ((totalCosts?.general + totalCosts?.corporate) * 0.02));

    const handlePayNow = async () => {
        const url = '/user/create-order';
        const generateOrderId = Math.floor(Date.now() + Math.floor(Math.random() * 100))


        // Constructing the params object
        const params = {
            id: Date.now() || null,
            orderAmount: (totalCosts?.general + totalCosts?.corporate + ((totalCosts?.general + totalCosts?.corporate) * 0.02)) || null,
            // orderAmount: 1 || null,
            orderCurrency: 'INR',
            orderId: generateOrderId.toString() || null,
            customerDetails: {
                customer_email: formData?.email || null,
                customer_phone: formData?.phoneNumber?.toString() || null,
                customer_name: formData?.name || null
            }
        };

        setLoading(true);

        try {
            const response = await axios.post(`${baseUrl}${url}`, params);
            // console.log(response, 'response'); // Assuming you want to log the response
            if (response?.data?.body) {
                generatePaymentRequest(response?.data?.body, generateOrderId, customerName, customerEmail, customerPhoneNumber, generalTicketCount, corporateTicketCount, generalTicketCost, corporateTicketCost, taxPayable, totalAmount, serialNumber)
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }

        setLoading(false);
    }


    return (
        <div style={{ height: '100%', backgroundColor: '#FFFBF8', border: '1px solid white' }}>
            {width > 600 ?
                <div style={{ marginTop: 50, height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div className="homeSidebar-about-us-details">
                        <img
                            className="homesideBarConatainer-about-us-details"
                            src={sideBar}
                            style={{ height: 700, marginTop: 80 }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%', marginLeft: 130, marginTop: -570 }}>
                        <div className='' onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                            <ArrowLeftOutlined style={{ color: 'black' }} /> <span style={{ marginLeft: 5 }}>{currentView == 3 ? 'back to home' : 'back'}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <Steps direction="horizontal" size='medium' style={{ marginLeft: 100 }}>
                                <Steps.Step
                                    title={
                                        <span style={{ color: (currentView == 1) ? '#AF180F' : '#C2C2C2' }}>
                                            Passes Booking
                                        </span>
                                    }
                                    icon={
                                        <div
                                            style={{
                                                color: (currentView == 1) ? '#AF180F' : '#FFFFFF',
                                                backgroundColor: (currentView == 1) ? null : '#C2C2C2',
                                                border: (currentView == 1) ? '2px solid #AF180F' : null,
                                                fontSize: 15,
                                                height: 30,
                                                width: 30,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            1
                                        </div>
                                    }
                                />
                                <Steps.Step
                                    title={
                                        <div style={{
                                            color: (currentView == 2) ? '#AF180F' : '#C2C2C2'
                                        }}>
                                            Order Summary
                                        </div>
                                    }
                                    icon={
                                        <div
                                            style={{
                                                color: (currentView !== 2) ? '#FFFFFF' : '#AF180F',
                                                border: (currentView !== 2) ? null : '2px solid #AF180F',
                                                backgroundColor: (currentView !== 2) ? '#C2C2C2' : 'transparent',
                                                fontSize: 15,
                                                width: 30,
                                                height: 30,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            2
                                        </div>
                                    }
                                />
                                <Steps.Step
                                    title={
                                        <div style={{
                                            color: (currentView == 3) ? '#AF180F' : '#C2C2C2'
                                        }}>
                                            Payment Confirmation
                                        </div>
                                    }
                                    icon={
                                        <div
                                            style={{
                                                color: (currentView !== 3) ? '#FFFFFF' : '#AF180F',
                                                border: (currentView !== 3) ? null : '2px solid #AF180F',
                                                backgroundColor: (currentView !== 3) ? '#C2C2C2' : 'transparent',
                                                fontSize: 15,
                                                width: 30,
                                                height: 30,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            3
                                        </div>
                                    }
                                />
                            </Steps>
                            {currentView == 1 ?
                                <>
                                    <div style={{ textAlign: 'center', color: '#AF180F', fontWeight: 600, marginLeft: 160, marginTop: 20 }}>Select number of passes</div>
                                    <center style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '87%', marginLeft: 130, marginTop: 20 }}>
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: 30, left: 110, textAlign: 'left' }}>
                                                <div style={{ fontSize: '1em', fontWeight: 500 }}>Corporate Passes</div>
                                                <div style={{ fontSize: '1em', fontWeight: 600, color: '#c2c2c2', textDecoration: 'line-through' }}>₹1250.00</div>
                                                <div style={{ fontSize: '2em', fontWeight: 600, color: '#AF180F' }}>₹850.00</div>
                                            </div>
                                            <img src={TicketImage} style={{ width: 300 }} />
                                            {
                                                totalRemainingTickets?.remainingCorporateTickets ?
                                                    <>
                                                        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                                            <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, cursor: 'pointer' }} onClick={() => handleCountUpdate('corporate', 'decrement')}>-</div>
                                                            <span style={{ margin: '0 10px', color: '#AF180F', fontWeight: 600 }}>{ticketCounts?.corporate}</span>
                                                            <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, cursor: 'pointer' }} onClick={() => handleCountUpdate('corporate', 'increment')}>+</div>
                                                        </div>
                                                        <div style={{ margin: '10px 0', fontWeight: 600, color: '#AF180F' }}>{totalRemainingTickets?.remainingCorporateTickets} left</div>
                                                    </> : <>
                                                        <div style={{ margin: '30px 0', color: '#AF180F', fontWeight: 600 }}>
                                                            SOLD OUT
                                                        </div>
                                                    </>
                                            }

                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: 30, left: 110, textAlign: 'left' }}>
                                                <div style={{ fontSize: '1em', fontWeight: 500 }}>Economy Passes</div>
                                                <div style={{ fontSize: '1em', fontWeight: 600, color: '#c2c2c2', textDecoration: 'line-through' }}>₹750.00</div>
                                                <div style={{ fontSize: '2em', fontWeight: 600, color: '#AF180F' }}>₹500.00</div>
                                            </div>
                                            <img src={TicketImage} style={{ width: 300 }} />
                                            {
                                                totalRemainingTickets?.remainingGeneralTickets ? <>
                                                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, cursor: 'pointer' }} onClick={() => handleCountUpdate('general', 'decrement')}>-</div>
                                                        <span style={{ margin: '0 10px', color: '#AF180F', fontWeight: 600 }}>{ticketCounts?.general}</span>
                                                        <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, cursor: 'pointer' }} onClick={() => handleCountUpdate('general', 'increment')}>+</div>
                                                    </div>
                                                    <div style={{ margin: '10px 0', fontWeight: 600, color: '#AF180F' }}>{totalRemainingTickets?.remainingGeneralTickets} left</div>
                                                </> : <>
                                                    <div style={{ margin: '30px 0', color: '#AF180F', fontWeight: 600 }}>
                                                        SOLD OUT
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </center>
                                    <center style={{ width: '50%', marginLeft: 270, marginTop: 0 }}>
                                        <div style={{ textAlign: 'left', marginBottom: 5 }}>Name<span style={{ color: 'red' }}>*</span></div>
                                        <Input
                                            style={{ backgroundColor: '#fffbf8' }}
                                            value={formData?.name}
                                            onChange={handleInputChange('name')}
                                            placeholder='Enter full name'
                                        />

                                        <div style={{ textAlign: 'left', marginTop: 20, marginBottom: 5 }}>Phone Number<span style={{ color: 'red' }}>*</span></div>
                                        <Input
                                            style={{ backgroundColor: '#fffbf8' }}
                                            value={formData?.phoneNumber}
                                            onChange={handleInputChange('phoneNumber')}
                                            placeholder='Enter phone number'
                                            type='number'
                                            inputMode='numeric'
                                            onWheel={(e) => e.preventDefault()}
                                        />

                                        <div style={{ textAlign: 'left', marginTop: 20, marginBottom: 5 }}>Email ID<span style={{ color: 'red' }}>*</span></div>
                                        <Input
                                            style={{ backgroundColor: '#fffbf8' }}
                                            value={formData?.email}
                                            onChange={handleInputChange('email')}
                                            placeholder='Enter email id'
                                        />

                                        <Button disabled={ticketCounts?.general == 0 && ticketCounts?.corporate == 0} onClick={handleConfirm} style={{ borderRadius: 4, backgroundColor: '#BB0A00', color: 'white', width: 150, marginTop: 30, marginBottom: 28, border: 'none' }}>Confirm</Button>
                                    </center>
                                </>
                                : currentView == 2 ?
                                    <>
                                        <div style={{ marginTop: 50, width: '100%', display: 'flex', marginLeft: 150 }}>
                                            {/* Left column - Order Summary */}
                                            <div style={{ flex: 1, padding: '20px', }}>
                                                <div style={{ fontSize: '1.5em', fontWeight: 600 }}>Order Summary</div>
                                                {/* Add your order summary content here */}

                                                <div style={{ marginTop: 20 }}>
                                                    <div style={{ fontSize: 22 }}>{formData?.name}</div>
                                                    <div style={{ margin: '5px 0px', fontSize: 16 }}>+91-{formData?.phoneNumber}</div>
                                                    <div style={{ fontSize: 16 }}>{formData?.email}</div>

                                                    {/* horizontal divider */}
                                                    <div style={{ borderBottom: '1px solid #AF180F', width: '90%', marginTop: 10 }}></div>

                                                    {/* <div style={{ marginTop: 10 }}>Promo code</div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                                                        <Input style={{ width: '50%', backgroundColor: '#fffbf8', marginTop: 5 }} placeholder='Enter the code' />
                                                        <Button style={{ backgroundColor: '#AF180F', color: 'white', borderRadius: 8 }}>APPLY</Button>
                                                    </div> */}

                                                    <div style={{ marginTop: 10, width: '90%', display: 'flex', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <div style={{ fontSize: 14 }}>Economy passes: </div>
                                                            <div style={{ margin: '10px 0px', fontSize: 14 }}>Corporate passes: </div>
                                                            <div style={{ fontSize: 14, margin: '10px 0px' }}>Payment gateway charges (2%): </div>
                                                            {/* <div style={{ fontSize: 14 }}>Discount: </div> */}
                                                        </div>

                                                        <div style={{ textAlign: 'right' }}>
                                                            <div style={{ fontSize: 14 }}>{totalCosts?.general}.00</div>
                                                            <div style={{ margin: '10px 0px', fontSize: 14 }}>{totalCosts?.corporate}.00</div>
                                                            <div style={{ fontSize: 14, margin: '10px 0px' }}>{((totalCosts?.general + totalCosts?.corporate) * 0.02).toFixed(2)}</div>
                                                            <div></div>
                                                        </div>
                                                    </div>

                                                    {/* horizontal divider */}
                                                    <div style={{ borderBottom: '1px solid #AF180F', width: '90%', marginTop: 10 }}></div>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', marginTop: 10 }}>
                                                        <div>Total: </div>
                                                        <div style={{ fontSize: '1.5em', fontWeight: 600, color: '#AF180F' }}>₹ {(totalCosts?.general + totalCosts?.corporate + ((totalCosts?.general + totalCosts?.corporate) * 0.02))}</div>
                                                    </div>

                                                    <div style={{ marginTop: 50 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                            <img src={SecureLockIcon} style={{ width: 12 }} />
                                                            <div style={{ fontSize: 12, marginLeft: 5 }}>This payment is <span style={{ color: '#AF180F' }}>Safe and Secure</span></div>
                                                        </div>
                                                        <div>
                                                            <Button onClick={handlePayNow} style={{ borderRadius: 4, backgroundColor: '#BB0A00', color: 'white', width: 150, marginTop: 10, marginBottom: 115, textAlign: 'center', border: 'none' }}>Pay Now</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Right column - Tickets */}
                                            <div style={{ flex: 1, padding: '20px' }}>
                                                {ticketCounts?.corporate >= 1 &&
                                                    <div style={{ position: 'relative' }}>
                                                        <div style={{ position: 'absolute', top: 50, left: 140 }}>
                                                            <div style={{ fontSize: '1.5em', fontWeight: 600, color: 'white', marginTop: 20, textAlign: 'center' }}>{ticketCounts?.corporate}</div>
                                                            <div style={{ fontSize: '0.8em', fontWeight: 500, color: 'white' }}>Corporate Passes</div>
                                                        </div>
                                                        <img src={SelectedTicket} style={{ width: 320 }} />
                                                    </div>
                                                }
                                                {ticketCounts?.general >= 1 &&
                                                    <div style={{ position: 'relative' }}>
                                                        <div style={{ position: 'absolute', top: 50, left: 140 }}>
                                                            <div style={{ fontSize: '1.5em', fontWeight: 600, color: 'white', marginTop: 20, textAlign: 'center' }}>{ticketCounts?.general}</div>
                                                            <div style={{ fontSize: '0.8em', fontWeight: 500, color: 'white' }}>Economy Passes</div>
                                                        </div>
                                                        <img src={SelectedTicket} style={{ width: 320 }} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                    : currentView == 3 ?
                                        <>
                                            <React.Fragment>
                                                {postPaymentRes && postPaymentRes[0]?.payment_status === 'SUCCESS' ? (
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <center id={`contentToCapture`} style={{ marginLeft: 150, position: 'relative' }}>
                                                            <div style={{ position: 'absolute', top: 140, left: 250, color: 'white', textAlign: 'left' }}>
                                                                <div>23rd spring fest, 2024 - ASOB</div>
                                                                {generalTicketCostFromUrl >= 1 &&
                                                                    <div style={{ margin: '10px 0px', fontWeight: 600 }}>Economy Passes: {generalTicketsFromUrl}</div>
                                                                }
                                                                {corporateTicketsFromUrl >= 1 &&
                                                                    <div style={{ fontWeight: 600 }}>Corporate Passes: {corporateTicketsFromUrl}</div>
                                                                }
                                                            </div>
                                                            <img src={SelectedTicket} style={{ width: 600 }} />
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
                                                                <div style={{ fontWeight: 600, marginBottom: 30 }}>Your booking for the event is confirmed</div>
                                                                {
                                                                    passesSerials?.cTickets.length ? (
                                                                        <div style={{ marginLeft: 20 }}>
                                                                            <div>
                                                                                <div style={{ fontWeight: 600, color: '#AF180F', marginBottom: 10 }}>Corporate passes: </div>
                                                                                <span>{passesSerials?.cTickets?.join(', ')}</span>
                                                                            </div>
                                                                        </div>) : null
                                                                }
                                                                <div style={{ marginLeft: 20 }}>
                                                                    {passesSerials?.eTickets.length ? (
                                                                        <div>
                                                                            <div style={{ fontWeight: 600, color: '#AF180F', marginBottom: 10, marginTop: 10 }}>Economy passes: </div>
                                                                            <span>{passesSerials?.eTickets?.join(', ')}</span>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div style={{ marginTop: 10 }}>Total Amount Paid : <span style={{ fontSize: '1.2em', color: '#AF180F', fontWeight: 600 }}>₹ {totalAmountFromUrl}</span></div>
                                                                <div style={{ marginTop: 10 }}>Order Id: <span style={{ fontWeight: 600, }}>{orderIdFromUrl}</span></div>
                                                            </div>
                                                        </center>
                                                        <div onClick={captureAndSaveAsImage} style={{ textAlign: 'center', color: '#AF180F', marginLeft: 180, marginBottom: 65, cursor: 'pointer', marginTop: 50 }}>Print this screen</div>
                                                    </div>
                                                ) : postPaymentRes && postPaymentRes[0]?.payment_status === 'FAILED' ? (
                                                    <div style={{ marginLeft: 150 }}>
                                                        <Result
                                                            status='error'
                                                            title='Payment failed'
                                                            subTitle='Please try again!'
                                                            extra={[
                                                                <Button onClick={() => navigate('/')} type="primary" style={{ backgroundColor: '#AF180F', border: 'none', marginBottom: 260 }}>
                                                                    Back to home
                                                                </Button>
                                                            ]}
                                                        />
                                                    </div>
                                                ) : postPaymentRes && postPaymentRes[0]?.payment_status === 'CANCELLED' ? (
                                                    <div style={{ marginLeft: 150, marginBottom: 260 }}>
                                                        <Result
                                                            status='error'
                                                            title='Payment cancelled'
                                                            subTitle='Please try again!'
                                                            extra={[
                                                                <Button onClick={() => navigate("/")} type="primary" style={{ backgroundColor: '#AF180F', border: 'none' }}>
                                                                    Back to home
                                                                </Button>
                                                            ]}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Spin />
                                                )}
                                            </React.Fragment>
                                        </>
                                        : null
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '0 !important',
                            marginTop: '-15.5vh'
                        }}
                    >
                        <TermsAndConditions

                        />
                    </div>
                    <div className="" style={{ position: 'absolute', right: -100, top: 300 }}>
                        <img className="" src={loadCircle} style={{ width: 200 }} />
                    </div>
                </div>
                :
                // FOR MOBILE DESIGN
                <div style={bodyStyle}>
                    <Element className="section-about-us-details">
                        <div onClick={() => navigate('/')} >
                            <ArrowLeftOutlined style={{ color: 'black' }} /><span style={{}}>{currentView == 3 ? 'back to home' : 'back'}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {currentView == 1 ?
                                <div className='' style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                                    {/* <img src={sideBar} style={{ height: '100%', width: '8%', marginTop: -450 }} /> */}
                                    <div style={{ marginTop: 20, position: 'relative' }}>
                                        {/* Economy ticket block */}
                                        <div style={{ textAlign: 'center', color: '#AF180F', fontWeight: 600, marginBottom: 5 }}>Select number of passes</div>

                                        {/* Corporate ticket block */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, position: 'relative', marginTop: 20 }}>
                                            <div style={{ position: 'absolute', top: 40, left: 110 }}>
                                                <div style={{ fontSize: '1em', fontWeight: 500 }}>Corporate Passes</div>
                                                <div style={{ fontSize: '1em', fontWeight: 600, color: '#c2c2c2', textDecoration: 'line-through' }}>₹1250.00</div>
                                                <div style={{ fontSize: '1.5em', fontWeight: 600, color: '#AF180F' }}>₹850.00</div>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <img src={TicketImage} style={{ width: 300 }} />
                                            </div>
                                            {
                                                totalRemainingTickets?.remainingCorporateTickets ? <>
                                                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: -30 }}>
                                                        <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleCountUpdate('corporate', 'decrement')}>-</div>
                                                        <span style={{ margin: '0 10px', color: '#AF180F', fontWeight: 600, fontSize: '1.5em' }}>{ticketCounts?.corporate}</span>
                                                        <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleCountUpdate('corporate', 'increment')}>+</div>
                                                    </div>
                                                    <center style={{ margin: '10px 0', fontWeight: 600, color: '#AF180F', marginLeft: -30 }}>{totalRemainingTickets?.remainingCorporateTickets} left</center>
                                                </> : <>
                                                    <div style={{ fontWeight: 600, margin: '30px 0', color: '#AF180F' }}>
                                                        SOLD OUT
                                                    </div>
                                                </>

                                            }
                                        </div>

                                        {/* ECONOMY PASSES BLOCK */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, position: 'relative', marginTop: 20 }}>
                                            <div style={{ position: 'absolute', top: 40, left: 110 }}>
                                                <div style={{ fontSize: '1em', fontWeight: 500 }}>Economy Passes</div>
                                                <div style={{ fontSize: '1em', fontWeight: 600, color: '#c2c2c2', textDecoration: 'line-through' }}>₹750.00</div>
                                                <div style={{ fontSize: '1.5em', fontWeight: 600, color: '#AF180F' }}>₹500.00</div>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <img src={TicketImage} style={{ width: 300 }} />
                                            </div>
                                            {
                                                totalRemainingTickets?.remainingGeneralTickets ? <>
                                                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: -30 }}>
                                                        <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: '#AF180F' }} onClick={() => handleCountUpdate('general', 'decrement')}>-</div>
                                                        <span style={{ margin: '0 10px', color: '#AF180F', fontSize: '1.5em', fontWeight: 600 }}>{ticketCounts?.general}</span>
                                                        <div style={{ border: '1px solid #AF180F', borderRadius: '50%', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: '#AF180F' }} onClick={() => handleCountUpdate('general', 'increment')}>+</div>
                                                    </div>
                                                    <center style={{ margin: '10px 0', fontWeight: 600, color: '#AF180F', marginLeft: -30 }}>{totalRemainingTickets?.remainingGeneralTickets} left</center>
                                                </> : <>
                                                    <div style={{ fontWeight: 600, margin: '30px 0', color: '#AF180F' }}>
                                                        SOLD OUT
                                                    </div>
                                                </>
                                            }
                                        </div>

                                        {/* INPUTS */}
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div style={{ textAlign: 'left', marginBottom: 5 }}>Name<span style={{ color: 'red' }}>*</span></div>
                                                <Input
                                                    style={{ backgroundColor: '#fffbf8' }}
                                                    value={formData?.name}
                                                    onChange={handleInputChange('name')}
                                                    placeholder='Enter full name'
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div style={{ textAlign: 'left', marginTop: 20, marginBottom: 5 }}>Phone Number<span style={{ color: 'red' }}>*</span></div>
                                                <Input
                                                    style={{ backgroundColor: '#fffbf8' }}
                                                    value={formData?.phoneNumber}
                                                    onChange={handleInputChange('phoneNumber')}
                                                    placeholder='Enter phone number'
                                                    type='number'
                                                    inputMode='numeric'
                                                    onWheel={(e) => e.preventDefault()}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div style={{ textAlign: 'left', marginTop: 20, marginBottom: 5 }}>Email ID<span style={{ color: 'red' }}>*</span></div>
                                                <Input
                                                    style={{ backgroundColor: '#fffbf8' }}
                                                    value={formData?.email}
                                                    onChange={handleInputChange('email')}
                                                    placeholder='Enter email id'
                                                />
                                            </div>

                                            <Button disabled={(ticketCounts.general === 0 && ticketCounts.corporate === 0) || (!formData.name || !formData.email || !formData.phoneNumber)} onClick={handleConfirm} style={{ opacity: ((ticketCounts.general === 0 && ticketCounts.corporate === 0) || !formData.name || !formData.email || !formData.phoneNumber) ? 0.5 : 1, borderRadius: 4, backgroundColor: '#BB0A00', color: 'white', width: 150, marginTop: 30, fontSize: '1em', marginBottom: 50 }}>Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                                : currentView == 2 ?
                                    <center style={{ marginLeft: -30 }}>
                                        {ticketCounts?.corporate >= 1 &&
                                            <div style={{ position: 'relative' }}>
                                                <div style={{ position: 'absolute', top: 70, left: 170 }}>
                                                    <div style={{ fontSize: '1.6em', fontWeight: 600, color: 'white', marginTop: 20, textAlign: 'center' }}>{ticketCounts?.corporate}</div>
                                                    <div style={{ fontSize: '1.2em', fontWeight: 500, color: 'white' }}>Corporate Passes</div>
                                                </div>
                                                <img src={SelectedTicket} style={{ width: 420 }} />
                                            </div>
                                        }
                                        {ticketCounts?.general >= 1 &&
                                            <div style={{ position: 'relative' }}>
                                                <div style={{ position: 'absolute', top: 70, left: 170 }}>
                                                    <div style={{ fontSize: '1.6em', fontWeight: 600, color: 'white', marginTop: 20, textAlign: 'center' }}>{ticketCounts?.general}</div>
                                                    <div style={{ fontSize: '1.2em', fontWeight: 500, color: 'white' }}>Economy Passes</div>
                                                </div>
                                                <img src={SelectedTicket} style={{ width: 420 }} />
                                            </div>
                                        }

                                        <div style={{ padding: '20px', textAlign: 'left', marginLeft: 20 }}>
                                            <div style={{ fontSize: '1.5em', fontWeight: 600 }}>Order Summary</div>
                                            {/* Add your order summary content here */}

                                            <div style={{ marginTop: 20 }}>
                                                <div style={{ fontSize: 22 }}>{formData?.name}</div>
                                                <div style={{ margin: '5px 0px', fontSize: 16 }}>+91-{formData?.phoneNumber}</div>
                                                <div style={{ fontSize: 16 }}>{formData?.email}</div>

                                                {/* horizontal divider */}
                                                <div style={{ borderBottom: '1px solid #AF180F', width: '90%', marginTop: 10 }}></div>

                                                {/* <div style={{ marginTop: 10 }}>Promo code</div>
                                                    <Input style={{ width: '90%', backgroundColor: '#fffbf8', marginTop: 5 }} placeholder='Enter the code' /> */}

                                                <div style={{ marginTop: 10, width: '90%', display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <div style={{ fontSize: 14 }}>Economy passes: </div>
                                                        <div style={{ margin: '10px 0px', fontSize: 14 }}>Corporate passes: </div>
                                                        <div style={{ fontSize: 14 }}>Payment gateway charges (2%): </div>
                                                    </div>

                                                    <div style={{ textAlign: 'right' }}>
                                                        <div style={{ fontSize: 14 }}>{totalCosts?.general}.00</div>
                                                        <div style={{ margin: '10px 0px', fontSize: 14 }}>{totalCosts?.corporate}.00</div>
                                                        <div style={{ fontSize: 14 }}>{((totalCosts?.general + totalCosts?.corporate) * 0.02)}</div>
                                                    </div>
                                                </div>

                                                {/* horizontal divider */}
                                                <div style={{ borderBottom: '1px solid #AF180F', width: '90%', marginTop: 10 }}></div>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', marginTop: 10 }}>
                                                    <div>Total: </div>
                                                    <div style={{ fontSize: '1.5em', fontWeight: 600, color: '#AF180F' }}>₹ {(totalCosts?.general + totalCosts?.corporate + ((totalCosts?.general + totalCosts?.corporate) * 0.02))}</div>
                                                </div>

                                                <div style={{ marginTop: 50 }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <img src={SecureLockIcon} style={{ width: 12 }} />
                                                        <div style={{ fontSize: 12, marginLeft: 5 }}>This payment is <span style={{ color: '#AF180F' }}>Safe and Secure</span></div>
                                                    </div>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Button onClick={handlePayNow} style={{ borderRadius: 4, backgroundColor: '#BB0A00', color: 'white', width: 150, marginTop: 10, fontSize: '1em', marginBottom: 115 }}>Pay Now</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </center>
                                    : currentView == 3 ?
                                        <>
                                            <React.Fragment >
                                                {postPaymentRes && postPaymentRes[0]?.payment_status === 'SUCCESS' ? (
                                                    <>
                                                        <center id={`contentToCapture`} style={{ position: 'relative', marginLeft: -10 }}>
                                                            <div onClick={captureAndSaveAsImage} style={{ textAlign: 'right', cursor: 'pointer', color: '#AF180F' }}>Print this screen</div>
                                                            <div style={{ position: 'absolute', top: 100, left: 150, color: 'white', textAlign: 'left' }}>
                                                                <div style={{ fontSize: 12 }}>23rd spring fest, 2024 - ASOB</div>
                                                                {generalTicketCostFromUrl >= 1 &&
                                                                    <div style={{ margin: '10px 0px', fontWeight: 600 }}>Economy Passes: {generalTicketsFromUrl}</div>
                                                                }
                                                                {corporateTicketsFromUrl >= 1 &&
                                                                    <div style={{ fontWeight: 600 }}>Corporate Passes: {corporateTicketsFromUrl}</div>
                                                                }
                                                            </div>
                                                            <img src={SelectedTicket} style={{ width: 400 }} />
                                                            <div style={{ marginLeft: -5, marginBottom: 50 }}>
                                                                <div style={{ fontWeight: 600 }}>Your booking for the event is confirmed</div>
                                                                <div style={{ marginLeft: 10, padding: 20 }}>
                                                                    {passesSerials?.cTickets?.length ? (
                                                                        <div>
                                                                            <div style={{ fontWeight: 600, color: '#AF180F', textAlign: 'left' }}>Corporate passes: </div>
                                                                            <span style={{ display: 'flex', flexWrap: 'wrap' }}>{passesSerials?.cTickets?.join(', ')}</span>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div style={{ marginLeft: 10, padding: 20 }}>
                                                                    {passesSerials?.eTickets?.length ? (
                                                                        <div>
                                                                            <div style={{ fontWeight: 600, color: '#AF180F', textAlign: 'left' }}>Economy passes: </div>
                                                                            <span style={{ display: 'flex', flexWrap: 'wrap' }}>{passesSerials?.eTickets?.join(', ')}</span>
                                                                        </div>
                                                                    ) : null}
                                                                </div>

                                                                <div style={{ fontWeight: 600, marginTop: 10 }}>Total Amount Paid : <span style={{ fontSize: '1.2em', color: '#AF180F', fontWeight: 600 }}>₹ {totalAmountFromUrl}</span></div>
                                                                <div style={{ fontWeight: 600, marginTop: 10 }}>Order Id: {orderIdFromUrl}</div>
                                                            </div>
                                                        </center>
                                                        {/* <div onClick={captureAndSaveAsImage} style={{ textAlign: 'center', color: '#AF180F', position: 'absolute', top: 800, left: 130, zIndex: 10, cursor: 'pointer' }}>Print this screen</div> */}
                                                    </>
                                                ) : postPaymentRes && postPaymentRes[0]?.payment_status === 'FAILED' ? (
                                                    <div style={{ marginLeft: 80, marginBottom: 240 }}>
                                                        <Result
                                                            status='error'
                                                            title='Payment failed'
                                                            subTitle='Please try again!'
                                                            extra={[
                                                                <Button onClick={() => navigate("/")} type="primary" style={{ backgroundColor: '#AF180F', border: 'none' }}>
                                                                    Back
                                                                </Button>
                                                            ]}
                                                        />
                                                    </div>
                                                ) : postPaymentRes && postPaymentRes[0]?.payment_status === 'CANCELLED' ? (
                                                    <div style={{ marginLeft: 50, marginBottom: 240 }}>
                                                        <Result
                                                            status='error'
                                                            title='Payment cancelled'
                                                            subTitle='Please try again!'
                                                            extra={[
                                                                <Button onClick={() => navigate("/")} type="primary" style={{ backgroundColor: '#AF180F', border: 'none' }}>
                                                                    Back
                                                                </Button>
                                                            ]}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Spin />
                                                )}
                                            </React.Fragment>
                                        </>
                                        : null
                            }
                        </div>
                    </Element>
                    <div
                        style={{
                            padding: '0 !important',
                            marginTop: '-12vh'
                        }}
                    >
                        <TermsAndConditions />
                    </div>
                </div >
            }
        </div >
    )
}



const mapStateToProps = (state) => {
    const { formValues } = state.asob;
    return { formValues }
};

const mapDispatchToProps = (dispatch) => ({
    setFormValues: (key, val) => dispatch(setFormValues(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookTickets)