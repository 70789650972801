import React from "react";
import Lottie from "lottie-react";
import animatedLogo from "../assets/images/loaderJson.json";
import './Style.css'
function LoaderLottie() {
  return (
    <div className='tstLoadercontainer'>
     
      <div className="loader-logo">
        <Lottie animationData={animatedLogo} />
      </div>
    </div>
  );
}

export default LoaderLottie;
