import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from '../common/baseUrl';
import { Link } from 'react-router-dom';

const PostPayment = ({ }) => {

    const [responseFromAPI, setAPIResponse] = useState({});

    const urlParams = new URLSearchParams(window.location.search);
    const orderIdFromUrl = urlParams.get('orderId');



    return (
        <div>
            {responseFromAPI?.data?.map((item, index) => {
                return (
                    item?.payment_status === 'SUCCESS' ?
                        <Link to='/book-tickets' />
                        :
                        item?.payment_status === 'FAILED' ?
                            <Link to='/book-tickets' />
                            :
                            item?.payment_status === 'CANCELLED' ? 'Cancelled is getting shown.'
                                : null
                );
            })}
        </div>
    )
}

export default PostPayment