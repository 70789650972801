import React from "react";
import Footer from "../common/Footer";
import "./Style.css";
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import { Element } from "react-scroll";
import { ArrowLeftOutlined } from "@ant-design/icons";
import assamSingleDanceIcon from "../assets/images/assamSingleDanceIcon.svg";
import assamGrpDanceIcon from "../assets/images/assamGrpDanceIcon.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import designIcon from "../assets/images/homeFooterDesign.svg";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../common/UseWindowDimensions";
import MobileFooter from "../common/MobileFooter";

function AboutUSDetails() {
  let location = useLocation();
  const { height, width } = useWindowDimensions();

  return (
    <div>
      <div>
        <Element className="section-about-us-details">
          <div className="aboutMainConatiner">
            <div className="homeSidebar-about-us-details">
              <img
                className="homesideBarConatainer-about-us-details"
                src={sideBar}
              />
            </div>
            <div className="about-us-details-content22" >
              {width > 600 ? (
                <div>
                  <div className="about-us-details-heading" >
                    <Link to="/">
                      <ArrowLeftOutlined /> Back
                    </Link>

                    <h2 style={{fontWeight:"bold"}}>About Us</h2>
                  </div>
                </div>
              ) : (
                <div className='mbaboutunlabel'>
                   <Link to="/">
                      <ArrowLeftOutlined /> Back
                    </Link>
                    <h2 style={{fontWeight:"bold"}}>About Us</h2>

                </div>
              )}

              <div className="about-us-details-designIcon">
                {" "}
                <img src={designIcon} />{" "}
              </div>

              <div className="about-us-details-content-container">
                <img src={assamSingleDanceIcon}  alt="No image" loading="lazy" />

                <p>
                  অসম চ’ছাইটী, বাংগালোৰ হ’ল বাংগালোৰত থকা সমস্ত অসমীয়া লোকেৰে
                  সংগঠিত আৰু ১৯৮২ চনতে কৰ্ণাটক চ’ছাইটী পঞ্জীকৰণ অধিনিয়্ম অনুসাৰে
                  পঞ্জীকৃত এক সামাজিক আৰু সাংস্কৃতিক বেচৰকাৰী (NGO) সংস্থা । অসম
                  চ’ছাইটী বাংগালোৰ, উত্তৰ পূৱ ভাৰতৰ পৰা জীৱিকা আৰু অধ্যয়ন সুত্ৰে
                  বাংগালোৰ তথা কৰ্ণাটকৰ বিভিন্ন প্ৰান্তলৈ স্থায়ী বা অস্থায়ীভাৱে
                  অহা সকলো প্ৰৱাসী অসমীয়াৰ এক একত্ৰিত সংগঠন । ১৯৮২ চনৰ পৰাই সকলো
                  সামাজিক কাৰ্যকলাপত সক্ৰিয় এই সংগঠনটোৱে মাথো অসমীয়া লোকৰ কাৰণেই
                  নহয়, বাংগালোৰত থকা আন সকলো উত্তৰ পূব ভাৰতীয় লোকৰ হকে বিভিন্ন
                  সময়ত নানাৰকমৰ সাংস্কৃতিক আৰু সামাজিক অনুষ্ঠান আয়োজন কৰি আহিছে
                  । তদুপৰি অসম চ’ছাইটী, বাংগালোৰে বিভিন্ন ক্রীড়া প্রতিযোগিতা,
                  চাকৰিৰ প্রশিক্ষণ, কৰ্মসংস্থাপন নতুবা অন্যান্য অশেষ
                  জনকল্যাণমূলক কার্যক্রমত সদায় শীৰ্ষ আৰু গুৰুত্বপূৰ্ণ ভুমিকা
                  পালন কৰি আহিছে । ২০১২ চনত কৰ্ণাটকত হোৱা গণ প্রস্থান (mass
                  exodus), প্ৰতি বছৰৰ অসমৰ বানপানী সমস্যা, বিভিন্ন প্রাকৃতিক
                  দুর্যোগৰ সময়ত আৰু শেহতীয়াকৈ কৰোনা মহামাৰীৰ সময়ত আতংকিত উত্তৰ
                  পূৱৰ লোকক আবশ্যকীয় খাদ্যসামগ্ৰী বিতৰণৰ কাৰনে, সকলো সময়তে অসম
                  চ’ছাইটী, বাংগালোৰে অসমীয়া তথা উত্তৰ পূৱৰ লোকসকলৰ প্রয়োজনত
                  আগভাগ লৈ আহিছে । বহিৰাজ্যত অনুষ্ঠিত হোৱা অসম আৰু
                  উত্তৰ-পূৰ্বাঞ্চলৰ একমাত্ৰ বঁটা প্ৰদান অনুষ্ঠান প্ৰাগ চিনে
                  এৱাৰ্ড প্ৰথমবাৰৰ বাবে বাংগালোৰত আয়োজন কৰাতো আয়োজক হিচাবে অসম
                  চছাইটী, বাংগালোৰে ২০১৪ চনত এক উল্লেখনীয় আৰু লেখত লবলগীয়া
                  ভুমিকা আগবঢ়াইছিল ।{" "}
                </p>
              </div>
            </div>
            <div className="homeLoad-about-us-details">
              <img className="loadCircle-about-us-details" src={loadCircle}   alt="No image" loading="lazy" />
            </div>
          </div>
        </Element>

        <Element className="section-about-us-details2">
          <div className="aboutMainConatiner">
            <div className="homeLoad1">
              <img className="loadCircle1-about-us-details" src={loadCircle}    alt="No image" loading="lazy"/>
            </div>
            <div className="about-us-details-content2">
              <div className="about-us-details-english-text">
                <p>
                  {" "}
                  The ‘Assam Society of Bangalore’ was established in the year
                  1982. It is registered under Society’s registration Act
                  wherein the registration no is ‘SOR-GNR-13/2007-08’. The
                  ‘Assam Society of Bangalore’ is an individual body consisting
                  of nearly 5000 students, 4000 software professionals and 1500-
                  2000 other members like doctors, businessmen, housewives,
                  security guards, etc. from Assam.Irrespective of cast and
                  creed the ‘Assam Society of Bangalore’ is comprised with all
                  types of members originating from Assam. ‘The state,
                  phosphorous in the historical, cultural and religious domain,
                  conceivably is unrivaled in its vicissitude’. ..The ‘Assam
                  Society of Bangalore’ gives an effort to present a peep into
                  all these aspects. Since 1998 onwards the ‘Assam Society of
                  Bangalore’ has played a very crucial role in the contribution
                  of uplifting the rich Assamese culture and Heritage. The
                  organization has also played an outstanding role in bringing
                  all the individuals from Assam residing in Bangalore in one
                  platform. Many more future plans are being made for the unity&
                  co-ordination, uplifting the rich Assamese culture and
                  heritage of Assam among the people from Assam living in
                  Bangalore.
                </p>
                <img src={assamGrpDanceIcon}   alt="No image" loading="lazy" />
              </div>
            </div>

            <div className="membersSiderDesign">
              <img
                className="memberSideBarContainerimg-about-us-details"
                src={sideBar}
                alt="No image" loading="lazy"
              />
            </div>
          </div>
        </Element>
      </div>
      <div>{width > 600 ? <Footer /> : <MobileFooter />}</div>
    </div>
  );
}

export default AboutUSDetails;
