import React, { useState } from "react";
import "./Style.css";
import "./eventStyle.css";
import sidebar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import eventDesignimg from "../assets/images/homeFooterDesign.svg";
import duraPujaCelebration2023 from "../assets/images/duraPujaCelebration.png";
import bihuCelebration from "../assets/images/bihuCelebration.png";
import bihuImageIcon from "../assets/images/bihu2024.png";
import durgaPujaImageIcon from "../assets/images/durgaPujaIcon2022.png";
import { Modal } from "antd";
import { eventsInfo } from "../common/eventssInfo";
import useWindowDimensions from "../common/UseWindowDimensions";
import mbdesign from "../assets/images/homeMbTB.svg";
import mbsideBar from "../assets/images/mbLR.svg";

// import { Card } from "antd";
import { useLocation } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import maplocationImg from "../assets/images/mapImage.png";
import { Carousel } from "antd";
import ModalListView from "./ModalListView";
// import CommingSoonTxt from "../common/CommingSoonTxt"
import CommingSoonTxt from '../common/commingSoonTxt';
import saraswatiPujaImage from '../assets/images/Sarasvati-pooja.png'
import cricketTournamentImage from '../assets/images/Cricket.png'
import joyOfGivingImage from '../assets/images/joy-of-giving.png'
import badmintonTournamentImage from '../assets/images/Badminton.png'

const contentStyle = {
  margin: 0,
  color: "black",
  padding: ".5em",
  height: "42vh",
};

function Event() {
  let location = useLocation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };

  let slides = [
    {
      type: "Upcoming",
      subEvents: [
        {
          eventId: "BohagBihu2024",
          img: bihuImageIcon,
          name: "Bohag Bihu 2024",
        },
        {
          eventId: "saraswatiPuja2024",
          img: saraswatiPujaImage,
          name: "Saraswati Puja 2024",
        },
        {
          eventId: "cricketTournament2023",
          img: cricketTournamentImage,
          name: "Cricket Tournament 2023",
        },
        {
          eventId: "Joyofgiving2023",
          img: joyOfGivingImage,
          name: "Joy of giving 2023",
        },
        {
          eventId: "badmintonTournament2024",
          img: badmintonTournamentImage,
          name: "Badminton Tournament 2024",
        },
        {
          eventId: "duraPujaCelebration2023",
          img: duraPujaCelebration2023,
          name: "11th Shree Shree Durga Puja 2023 - ASOB",
          Venue: "Freedom International School Ground, HSR Layout",
          Date: "20th Oct - 24th Oct",
        },
      ],
    },
    {
      type: "Concluded",
      subEvents: [
        {
          eventId: "BohagBihu2023",
          img: bihuCelebration,
          name: "Bohag Bihu 2023",
        },
        {
          eventId: "durgaPuja2022",
          img: durgaPujaImageIcon,
          name: "10th Shree Durga Puja celebration (2022) ",
        },

      ],
    },
  ];

  const LiestViewEventDetails = [
    {
      heading: "Shashthi",
      date: "20th Oct, Fri, 2023",
      scheduleTimings: [
        { timing: "08:30 AM", PujaName: "Shashthi Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "06:00 PM", PujaName: "Durga Devi Bodhan" },
        { timing: "06:30 PM", PujaName: "Amantran & Adhivas" },
      ],
    },
    {
      heading: "Maha Saptami",
      date: "21st Oct, Sat, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Samptami Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },

    {
      heading: "Maha Asthami",
      date: "22nd Oct, Sun, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Asthami Puja" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "04:53 PM", PujaName: "Sandhi Puja" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },

    {
      heading: "Maha Navami",
      date: "23rd Oct, Mon, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Navami Puja & Hoom" },
        { timing: "11:00 AM", PujaName: "Pushpanjali" },
        { timing: "04:30 PM", PujaName: "Kumari Puja" },
        { timing: "06:30 PM", PujaName: "Evening Aarti" },
      ],
    },
    {
      heading: "Vijaya Dashami",
      date: "24th Oct, Tue, 2023",
      scheduleTimings: [
        { timing: "09:30 AM", PujaName: "Dashami Puja" },
        { timing: "10:30 AM", PujaName: "Sindoor Khela" },
        { timing: "12:41 PM", PujaName: "Devi Visarjan" },
      ],
    },
  ];
  const [isModalOpens, setIsModalOpens] = useState(false);

  const showModals = () => {
    setIsModalOpens(true);
  };
  // const handleOk = () => {
  //   setIsModalOpens(false);
  // };
  const handleCancels = () => {
    setIsModalOpens(false);
  };

  let mobileslides = [
    {
      type: "Upcoming",
      eventId: "BohagBihu2024",
      img: bihuImageIcon,
      name: "Bohag Bihu 2024",
      Venue: "Freedom International School Ground, HSR Layout",
    },
    {
      type: "Upcoming",
      eventId: "saraswatiPuja2024",
      img: saraswatiPujaImage,
      name: "Saraswati Puja 2024",
    },
    {
      type: "Upcoming",
      eventId: "cricketTournament2023",
      img: cricketTournamentImage,
      name: "Cricket Tournament 2023",
    },
    {
      type: "Upcoming",
      eventId: "Joyofgiving2023",
      img: joyOfGivingImage,
      name: "Joy of giving 2023",
    },
    {
      type: "Upcoming",
      eventId: "badmintonTournament2024",
      img: badmintonTournamentImage,
      name: "Badminton Tournament 2024",
    },
    {
      type: "Concluded",
      eventId: "BohagBihu2023",
      img: bihuCelebration,
      name: "Bohag Bihu 2023",
    },
    {
      type: "Concluded",
      eventId: "durgaPuja2022",
      img: durgaPujaImageIcon,
      name: "10th Shree Durga Puja celebration (2022) ",
    },
    {
      type: "Upcoming",
      eventId: "duraPujaCelebration2023",
      img: duraPujaCelebration2023,
      name: "11th Shree Shree Durga Puja 2023 - ASOB",
      Venue: "Freedom International School Ground, HSR Layout",
      Date: "20th Oct - 24th Oct",
    },
  ];

  // durgaPujaImageIcon
  const imageFilenames = [];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const showModal = (info) => {
    console.log("info", info);
    if (info == "BohagBihu2023") {
      imageFilenames.push(
        "bihuImage1.jpeg",
        "bihuImage2.jpeg",
        "bihuImage3.jpeg",
        "bihuImage4.jpeg",
        "bihuImage5.jpeg",
        "bihuImage6.jpeg"
      );
    } else if (info == "durgaPuja2022") {
      imageFilenames.push(
        "durapuja1.png",
        "durapuja2.png",
        "durapuja3.png",
        "durapuja4.png",
        "durapuja5.png",
        "durapuja6.png"
      );
    }
    setSelectedEvent([]);
    const viewSelected = eventsInfo.filter((ele) => ele.id == info);
    setSelectedEvent((old) => [...old, ...viewSelected]);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const importAllImages = (context) => {
    const images = {};
    context.keys().forEach((item, index) => {
      images[imageFilenames[index]] = context(item);
    });
    return images;
  };

  const navigateSingleEvent = (eventVal) => {
    navigate("/single-event", { state: { data: eventVal } });
  };

  const images = importAllImages(
    require.context("../assets/images/bihu2023", false, /\.(jpeg)$/)
  );

  return (
    <div>
      {width > 600 ? (
        <div>
          <div
            className={
              location.pathname === "/" ? "eventMainHome" : "singleEventPage"
            }
          >
            <div className="homeLoad1">
              <h1>{location.pathname}</h1>
              <img className="loadCircle1" src={loadCircle} />
            </div>
            <div className="eventCarosuelConatiner">
              <div>
                <h2 className="eventsFontConatiner">Events</h2>
                <div className="eventDesignContainer">
                  <img src={eventDesignimg} />
                  <img src={eventDesignimg} />
                </div>

                <div class="wrapper">
                  <div class="scrolls">
                    {slides.map((ele) => (
                      <div className="eventCard">
                        <h5 className="eventType">{ele.type}</h5>
                        <div className="subcardEventtxt">
                          {ele.subEvents.map((item) => (
                            <div
                              onClick={() => showModal(item?.eventId)}
                              className="subcardEventtxtInfo"
                            >
                              <div className="eventImgContainer">
                                <img src={item.img} />
                              </div>
                              <div className="eventInfocontainer" style={{ padding: 5, }}>
                                <p className="eventName" style={{ fontSize: 14 }}>{item.name}</p>
                                {item.Venue ? (
                                  <p style={{ fontSize: 14 }}>
                                    <span>Venue: </span>
                                    {item.Venue}
                                  </p>
                                ) : null}
                                {item.Date ? (
                                  <p style={{ fontSize: 14 }}>
                                    <span>Date:</span>
                                    {item.Date}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <Modal
                  bodyStyle={{ height: "70vh" }}
                  open={isModalVisible}
                  onCancel={handleCancel}
                  closable={true}
                  header={null}
                  footer={null}
                  width="80%"
                >
                  <div className="eventModalImageContainer">
                    <div className="eventInfoTst">
                      <div className="evtInfoImgCont">
                        <img
                          className="eventLogotst"
                          src={selectedEvent[0]?.eventLogo}
                        />
                      </div>
                      <div className="evtInfoData">
                        <div className="eventModalDesignMain">
                          <img
                            className="eventModalDesign"
                            src={eventDesignimg}
                          />
                        </div>
                        <div className="subEventtxt">
                          <img
                            className="subEventtxtImg"
                            src={selectedEvent[0]?.eventText}
                          />
                        </div>
                        <div className="eventModalDesignMain">
                          <img
                            className="eventModalDesign"
                            src={eventDesignimg}
                          />
                        </div>
                        <div className="eventAdd">
                          <h3>{selectedEvent[0]?.event}</h3>
                          {selectedEvent[0]?.venue !== '' && (
                            <p>
                              <span className="venueAdd">Venue: </span>
                              {selectedEvent[0]?.venue}
                            </p>
                          )}
                          {selectedEvent[0]?.Date !== '' && (
                            <p>
                              <span className="venueAdd">Date: </span>
                              {selectedEvent[0]?.Date}
                            </p>
                          )}
                          {/* <a onClick={showModals} className="mdscdletag">
                            {" "}
                            View Schedule
                          </a> */}
                          {selectedEvent[0]?.id == "duraPujaCelebration2023" ? (
                            <a onClick={showModals} className="mbLinkMdl">
                              {" "}
                              View Schedule
                            </a>
                          ) : null}
                          {/* {selectedEvent[0]?.id == "BohagBihu2024" ? <div className="book-tickets" style={{ marginTop: 20 }} onClick={() => { window.open("https://asobspringfest.in/events/23rdspringfest/") }}>Book Passes</div> : <></>} */}
                        </div>
                      </div>
                      <div>
                        {" "}
                        <ModalListView
                          showModals={showModals}
                          isModalOpens={isModalOpens}
                          LiestViewEventDetails={LiestViewEventDetails}
                          handleCancels={handleCancels}
                        />
                      </div>
                    </div>
                    {selectedEvent[0]?.imageFilenames?.length >= 1 && (
                      <div className="eeettttt">
                        <div className="evtTop">
                          <img className="evtTopImg" src={eventDesignimg} />
                        </div>

                        <div className="image-row">
                          {selectedEvent[0]?.imageFilenames?.map(
                            (filename, index) => (
                              <div className="image-container-sub" key={index}>
                                <img
                                  className="tstEvnetImgggggg"
                                  src={filename.img}
                                />
                              </div>
                            )
                          )}
                        </div>

                        <div className="evtBtm">
                          <a href="/gallery-details">Go to Gallery</a>
                          <img className="evtTopImg" src={eventDesignimg} />
                        </div>
                      </div>
                    )}
                    {selectedEvent[0]?.imageFilenames == undefined &&
                      selectedEvent[0]?.isLocation == true && (
                        <div class="mapLocation">
                          <img src={maplocationImg} />
                        </div>
                      )}
                    {selectedEvent[0]?.imageFilenames == undefined &&
                      selectedEvent[0]?.isLocation == undefined &&
                      selectedEvent[0]?.isUpcoming == true && (
                        <div className="comingsoon">
                          {/* <h2>coming soon</h2> */}
                          <CommingSoonTxt />

                        </div>
                      )}
                  </div>
                </Modal>

                <div className="eventDesignContainer">
                  <img src={eventDesignimg} />
                  <img src={eventDesignimg} />
                </div>
              </div>
            </div>

            <div className="eventSideBarDesign">
              <img className="eventSideBarimg" src={sidebar} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className={
              location.pathname === "/"
                ? "mbeventcontainer"
                : "singlembeventcontainer"
            }
          >
            <div className="mbeventHeader">
              <h3 className="mbeventsFontConatiner" style={{ fontWeight: "bold" }}>Events</h3>
              <img src={mbdesign} />
              <div className="curscontainer">
                <div className="crslsub">
                  <Carousel autoplay afterChange={onChange}>
                    {mobileslides.map((ele) => (
                      <div className="tstCrl">
                        <div
                          onClick={() => navigateSingleEvent(ele)}
                          style={contentStyle}
                        >
                          <h3> {ele.type}</h3>
                          <div className="crclimgcontainer">
                            <img src={ele.img} />
                          </div>
                          <div className="mbcrclInfo">
                            <p>{ele.name}</p>
                            {ele.Venue ? (
                              <p>
                                <span>Venue:</span>
                                {ele.Venue}
                              </p>
                            ) : null}
                            {ele.Date ? (
                              <p>
                                <span>Date:</span>
                                {ele.Date}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div>
                  {" "}
                  <img src={mbsideBar} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Event;
