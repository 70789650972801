import React, { useState, useEffect } from "react";
import "./Style.css";
import { Link } from "react-scroll";
import logo from "../assets/images/logo.png";
import headerDesign1 from "../assets/images/headerDesign1.png";
import { BrowserRouter, Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import useWindowDimensions from "../common/UseWindowDimensions";
import "./headerstyles.css";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import homeFooterDesign from "../assets/images/homeFooterDesign.svg";
import { useLocation } from "react-router-dom";
import instagramIcon from "../assets/images/instagramIcon.png";
import faceBookIcon from "../assets/images/faceBookIcon.png";
import contactUsIcon from "../assets/images/contactUsIcon.svg";
import sideBars from "../assets/images/sideBarDesign.svg";
import contactUS from "../assets/images/contactUsIcnMbl.svg";
import contactUSIconverticle from "../assets/images/contatUsIconverticle.svg";
import { setNavBarId } from "../action/asob";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../common/baseUrl';


function Header({ setNavBarId, navBarId }) {
  let location = useLocation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const duration = 310;
  const [isOpen, setIsOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHeaderVisible(prevScrollY > currentScrollY || currentScrollY === 0);
      setPrevScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  const handleClickRoute = (val) => {
    if (location.pathname !== "/") {
      window.location.href = val;
    } else if (isOpen) {
      window.location.href = val;
      setIsOpen(false);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promoCodesArray, setPromoCodes] = useState(null)


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const instagramProfileUrl = "https://instagram.com/assamsocietyblr?igshid=MzRlODBiNWFlZA==";
  const faceBookProfileUrl = "https://www.facebook.com/assamsocietybangalore/";

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // PROMOCODES API //
    const url = `${baseUrl}/user/promoCodes`;

    axios.get(url).then(response => {
      setPromoCodes(response?.data);
    })
      .catch(error => {
        console.error('Error fetching promo codes:', error);
      });
  }, []);

  const scrollToSection = (offset, barId) => {
    // setNavBarId(barId);
    navigate("/")
    // window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
  };

  const FlashingMessage = () => {
    return (
      <>
        {promoCodesArray && promoCodesArray?.promocodes && promoCodesArray?.promocodes?.map((promoCode, index) => (
          <div key={index} className="flash-msg-font" style={{ backgroundColor: "#FF9B05", color: "#FFFFFF", width: "100%", height: "fit-content", position: "fixed", top: 0, left: 0, zIndex: 10000, fontWeight: "bold", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center", lineHeight: 1.5, textAlign: "center" }}>
            Early Bird Discount Of ₹{promoCode.discount} from 01/03/2024 to 15/03/2024. Use Promo code : {promoCode.code}
          </div>
        ))}
      </>
    );
  }

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);
  return (
    <div className="tstHeader">
      {width > 600 ? (
        <div className={`top-bar ${visible ? 'visible' : 'hidden'}`} style={{/*  animation: visible ? "header 1.3s ease" : "" */ }}>
          {/* <FlashingMessage /> */}
          <header style={{ backgroundColor: "#FFFFFF" }}>
            <img
              className="logo"
              onClick={() => handleClickRoute("/")}
              src={logo}
            />
            <div className="site-txt">
              <img src={homeFooterDesign} />
              <h4 className="sitelabel">Assam Society of Bangalore</h4>
              <img src={homeFooterDesign} />
            </div>
            <nav>
              <ul>
                <li>
                  <div
                    onClick={() => scrollToSection(0, 1)}
                    className={(scrollPosition < 10 && location?.pathname == "/") ? "active-route" : ""}
                    style={{ /* cursor: "pointer", */ marginRight: 20 }}
                  >
                    Home
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => scrollToSection(10, 2)}
                    className={(scrollPosition > 10 && scrollPosition < 800 && location?.pathname == "/") ? "active-route" : ""}
                    style={{ /* cursor: "pointer", */ marginRight: 20 }}
                  >
                    Events
                  </div>{" "}
                </li>
                <li>
                  <div
                    onClick={() => scrollToSection(800, 3)}
                    className={(scrollPosition > 800 && scrollPosition < 1600 && location?.pathname == "/") || location?.pathname === "/gallery-details" ? "active-route" : ""}
                    style={{ /* cursor: "pointer", */ marginRight: 20 }}
                  >
                    Gallery
                  </div>{" "}
                </li>
                <li>
                  <div
                    onClick={() => scrollToSection(1600, 4)}
                    className={(scrollPosition > 1600 && scrollPosition < 3500 && location?.pathname == "/") || location?.pathname === "/members-details" ? "active-route" : ""}
                    style={{ /* cursor: "pointer", */ marginRight: 20 }}
                  >
                    Members
                  </div>{" "}
                </li>
                <li>
                  <div
                    onClick={() => scrollToSection(3500, 5)}
                    className={(scrollPosition > 3500 && scrollPosition < 5000 && location?.pathname == "/") || location?.pathname === "/aboutus-details" ? "active-route" : ""}
                    style={{ /* cursor: "pointer", */ marginRight: 20 }}
                  >
                    About Us
                  </div>{" "}
                </li>
                {/* <li>
                  <Link
                    activeClass={location.pathname === "/" ? "active" : ""}
                    to="section-6"
                    spy
                    smooth
                    duration={duration}
                    onClick={() => handleClickRoute("/donation")}
                    className={
                      (location.pathname !== "/" &&
                        (location.pathname === "/donation" ||
                          location.pathname === "/donation-details"))
                        ? "active-route"
                        : ""
                    }
                  >
                    Donation
                  </Link>{" "}

                </li> */}
                {/* <li>
                  <Link
                    activeClass={location.pathname === "/" && location.pathname == '/membership' ? "active" : ""}
                    to="section-7"
                    spy
                    smooth
                    duration={duration}
                    onClick={() => navigate('/membership')}
                    className={
                      (location.pathname !== "/" &&
                        (location.pathname === "/membership"))
                        ? "active-route"
                        : ""
                    }
                  >
                    Membership
                  </Link>{" "}
                </li> */}
              </ul>
            </nav>
          </header>
          {/* <Modal
            open={isModalOpen}
            bodyStyle={{ height: "63vh", }}
            visible={isModalOpen}
            onCancel={handleCancel}
            closable={true}
            header={null}
            footer={null}
            width="60%"
          >
            <div>
              <div className="sideBarContact">
                <img src={sideBars} />
                <div className="contactUsIcnCnt">
                  <img src={contactUSIconverticle} />
                  <img className="iconCnt" src={contactUS} />
                  <img src={contactUSIconverticle} />
                </div>

                <img src={sideBars} />
              </div>
            </div>
          </Modal> */}
        </div>
      ) : (
        <div className="mbHeadercontainer" style={{ width: "100vw" }}>
          <div className={`top-bar ${visible ? 'visible' : 'hidden'}`} style={{ /* animation: visible ? "header 1.3s ease" : "" */ }}>
            <header /* className={`site-header ${isHeaderVisible ? "" : "hide"}`} */ style={{backgroundColor: "#FFFFFF" }}>
              {/* {isOpen ? <></> : <FlashingMessage />} */}
              <img className="mblogo" src={logo} />
              <div className="mb-site-txt">
                <img className="mbsitetxt" src={headerDesign1} />
                <h4 className="siteName">Assam Society of Bangalore</h4>
                <img className="mbsitetxt" src={headerDesign1} />
              </div>
              <div className="mbMenuOptionCntner">
                <MenuOutlined className="menuoption" onClick={openModal} />
              </div>
            </header>
          </div>
          {isOpen && (
            <div className="modal" >
              <div className="modal-content" >
                <div className="closeHeader" style={{ paddingRight: "1.5rem" }}>
                  <CloseOutlined className="closeicon" onClick={closeModal} />
                </div>
                <div className="mbheadercon">
                  <nav>
                    <ul>
                      <li>
                        <div
                          onClick={() => {navigate("/");closeModal(); window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); }}
                        >
                          Home
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {navigate("/"); closeModal(); window.scrollTo({ top: 1150, left: 0, behavior: "smooth" }); }}
                        >
                          Events
                        </div>{" "}
                      </li>
                      <li>
                        <div
                          onClick={() => {navigate("/"); closeModal(); window.scrollTo({ top: 1700, left: 0, behavior: "smooth" }); }}
                        >
                          Gallery
                        </div>{" "}
                      </li>
                      <li>
                        <div
                          onClick={() => {navigate("/"); closeModal(); window.scrollTo({ top: 2200, left: 0, behavior: "smooth" }); }}
                        >
                          Members
                        </div>{" "}
                      </li>
                      <li>
                        <div
                          onClick={() => {navigate("/"); closeModal(); window.scrollTo({ top: 4050, left: 0, behavior: "smooth" }); }}
                        >
                          About Us
                        </div>{" "}
                      </li>
                      {/* <li>
                        <Link
                          activeClass="active"
                          to="section-6"
                          spy
                          smooth
                          duration={duration}
                          onClick={() => handleClickRoute("/donation")}
                          className={
                            location.pathname !== "/" &&
                              location.pathname == "/donation"
                              ? "active-route"
                              : ""
                          }
                        >
                          Donation
                        </Link>{" "}
                      </li> */}
                      {/* <li>
                        <Link
                          activeClass="active"
                          to="section-7"
                          spy
                          smooth
                          duration={duration}
                          onClick={() => handleClickRoute("/membership")}
                          className={
                            location.pathname !== "/" &&
                              location.pathname == "/membership"
                              ? "active-route"
                              : ""
                          }
                        >
                          Membership
                        </Link>{" "}
                      </li> */}
                    </ul>
                  </nav>
                </div>
                <div className="mbhdrthricnHldr">
                  <a
                    href={instagramProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="mbCir-btn">
                      <img src={instagramIcon} />
                    </button>
                  </a>
                  <a
                    href={faceBookProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="mbCir-btn">

                      <img src={faceBookIcon} />
                    </button>
                  </a>

                  <button onClick={showModal} className="mbCir-btn">

                    <img className="hrcontactIcn" src={contactUsIcon} />

                  </button>


                </div>
                <Modal
                  open={isModalOpen}
                  bodyStyle={{ height: "53vh", }}
                  visible={isModalOpen}
                  onCancel={handleCancel}
                  closable={true}
                  header={null}
                  footer={null}
                  width="85%">
                  <div>
                    <div className="mbSideBarContact">
                      {/* <img className="mbSideBarContainer" src={sideBars}/> */}
                      <div className="mbCntContentHolder">
                        <img className="mbCntContentHolderCntner" src={contactUSIconverticle} />
                        <img className="mbCntUsCntner" src={contactUS} />

                        <img className="mbCntContentHolderCntner" src={contactUSIconverticle} />


                      </div>
                      {/* <img className="mbSideBarContainer" src={sideBars}/> */}

                    </div>

                  </div>

                </Modal>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  const { navBarId } = state.asob;
  return {
    navBarId
  }
};
const mapDispatchToProps = (dispatch) => ({
  setNavBarId: (val) => dispatch(setNavBarId(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);




