import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import designIcon from "../assets/images/homeFooterDesign.svg";
import donationDetailsIcons from "../assets/images/donationdtIcons.svg";
import { Form, Input, Select, notification, Button, Radio, Upload, Spin } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import googlePayIcon from "../assets/images/google-pay-seeklogo.com.svg";
import phonePayIcon from "../assets/images/phonepe-seeklogo.com.svg";
import bhartPayIcon from "../assets/images/bharat-interface-for-money-bhim-logo-vector.svg";
import orCode from "../assets/images/orCodeForScanner.png";

import axios from "axios";
import "./donationStyle.css";
import useWindowDimensions from "../common/UseWindowDimensions";
import mbdntdesign from "../assets/images/homeMbTB.svg";
import mbdonationsidebarimges from "../assets/images/sideBarDesign.svg";
import Footer from "../common/Footer";
import baseUrl from "../common/baseUrl";
import phoneIcon from "../assets/images/phoneIcon1.svg";
import emailIcon from "../assets/images/gmailIcon1.svg";
import locationIcon from "../assets/images/locationIcon1.svg";
import mbLRimg from "../assets/images/mbLR.svg";
import MobileFooter from "../common/MobileFooter";


const { Option } = Select;

function DonationDetails() {
  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
},[])
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [customdonationForValue, setCustomDonationForValue] = useState("");
  const [selectedRadioValue, setSelectedRadioValue] = useState(undefined);
  const [selectedDonationForRadioValue, setSelectedDonationForRadioValue] = useState(undefined);
  const [customAmountValue, setCustomAmountValue] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [customDonationAmountValue, setCustomDonationAmountValue] = useState("");
  const [selectDonationAmtRadioVal, setSelectDonationAmtRadioVal] = useState(undefined);
  const [selectedAmountRadioValue, setSelectedAmountRadioValue] = useState(undefined);


  const radioOptions = [
    { label: "Saptami Puja", value: "Saptami Puja" },
    { label: "Ashtami Puja", value: "Ashtami Puja" },
    { label: "Navami Puja", value: "Navami Puja" },
    { label: "Dashami Puja", value: "Dashami Puja" },
    { label: "Payas (3 days)", value: "Payas (3 days)" },
    { label: "Rosogolla 1200 Pieces", value: "Rosogolla 1200 Pieces" },
    {
      label: "Daily Puja Bazaar Expenses",
      value: "Daily Puja Bazaar Expenses",
    },
    { label: "Saptami Bhog", value: "Saptami Bhog" },
    { label: "Ashtami Bhog", value: "Ashtami Bhog" },
    { label: "Navami Bhog", value: "Navami Bhog" },
  ];
  const amountOptions = [
    { label: "Rs. 1,000", value: " 1,000" },
    { label: "Rs. 2,000", value: " 2,000" },
    { label: "Rs. 3,000", value: " 3,000" },
    { label: "Rs. 4,000", value: " 4,000" },
    { label: "Rs. 5,000", value: " 5,000" },
    { label: "Rs. 6,000", value: " 6,000" },
    { label: "Rs. 7,000", value: " 7,000" },
    { label: "Rs. 8,000", value: " 8,000" },
    { label: "Rs. 9,000", value: " 9,000" },
  ];
  const validateMessages = {
    required: '${label} is required!',

    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSubmit = (values) => {
    console.log("vvv", values);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("phone_number", values.phone_number);
    formData.append("image", imageFile);
    if (values?.email !== undefined) {
      formData.append("email", values?.email);
    }

    if (selectedDonationForRadioValue === "donation_for_other") {
      formData.append("donation_for", customdonationForValue);
    } else {
      formData.append("donation_for", values.donation_for);
    }
    if (selectDonationAmtRadioVal === "donation_amount_val") {
      formData.append("donation_amount", customDonationAmountValue);
    } else {
      formData.append("donation_amount", values.donation_amount);
    }

    setLoading(true);
    axios.post(`${baseUrl}/user/donationDetails `, formData, {}).then((response) => {
      setLoading(false);
      console.log(response);
      notification.success({
        message: 'Success',
        description: response?.data,
        placement: 'topRight',
      });
      form.resetFields();
      setSelectedDonationForRadioValue(undefined);
      setCustomDonationForValue("");
      setSelectDonationAmtRadioVal(undefined);
      setCustomDonationAmountValue("");
      setImageFile(null);
    })
      .catch((error) => {
        console.error(error?.response);
        notification.error({
          message: 'error',
          description: error?.response?.data,
          placement: 'topRight',
        });
      });
  };

  const handleRadioChange = (e) => {
    setSelectedDonationForRadioValue(e.target.value);
    console.log("eeee", e.target.value, selectedRadioValue);
    setCustomDonationForValue("");
  };

  const handleAmountRadioChange = (e) => {
    setSelectDonationAmtRadioVal(e.target.value);
    console.log("eeee", e.target.value, selectedRadioValue);
    setCustomDonationAmountValue("");
  };

  const customRequest = ({ file, onSuccess }) => {

    setImageFile(file);

    setTimeout(() => {
      console.log("Upload complete");
      onSuccess("ok");
    }, 1000);
  };

  const uploadProps = {
    customRequest: customRequest,
  };

  return (
    <div>
      {width > 600 ? (
        <div>
          <Element className="section-about-us-details">
            <div className="aboutMainConatiner">
              <div className="homeSidebar-about-us-details">
                <img
                  className="homesideBarConatainer-about-us-details"
                  src={sideBar}
                />
              </div>
              <div className="about-us-details-content22" >
                <div>
                  <div className="about-us-details-heading" >
                    <Link to="/">
                      <ArrowLeftOutlined /> Back
                    </Link>

                    <h2 style={{fontWeight:"bold"}}>Contact Us</h2>
                  </div>
                </div>
                <center>
                  <div className="about-us-details-content-container" style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "30%" }}>
                    <div style={{ marginTop: 40, display: "flex" }}>
                      <img src={phoneIcon} style={{ height: 40, width: 40, marginRight: 10, marginTop: -10 }} />
                      <div style={{ color: "1F1E1B" }}>882-223-7904, 889-294-2382, 874-800-8777</div>
                    </div>
                    <div style={{ marginTop: 30, display: "flex" }}>
                      <img src={emailIcon} style={{ height: 40, width: 40, marginRight: 10, marginTop: -10 }} />
                      <div style={{ color: "1F1E1B" }}>asobinfo@gmail.com</div>
                    </div>
                    <div style={{ marginTop: 30, display: "flex" }}>
                      <img src={locationIcon} style={{ height: 40, width: 40, marginRight: 10 }} />
                      <div style={{ color: "1F1E1B", lineHeight: 1.5, textAlign: "left" }}>4th floor, Registered office only) Axomi, 61, 1st Main Rd, KHB Colony, 7th Block, Koramangala, Bengaluru, Karnataka 560095</div>
                    </div>
                  </div>
                </center>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 100 }}>
                  <div className="about-us-details-heading" >
                    <h2 style={{fontWeight:"bold"}}>Contributions</h2>
                  </div>
                </div>
                <center>
                  <div style={{ color: "1F1E1B", textAlign: "center", fontWeight: "bold", fontSize: 18, lineHeight: 1.5, width: "80%", marginTop: 20 }}>Please Fill The Form If You Would Like To Contribute For Daily Puja, Purohit Dokhina, Specific Puja Contribution, Daily Bhog Or Any Specific Contribution.</div>
                </center>
              </div>
              <div className="homeLoad-about-us-details">
                <img className="loadCircle-about-us-details" src={loadCircle} alt="No image" loading="lazy" />
              </div>
            </div>
            <div className="about-us-details-designIcon">
              {" "}
              <img src={designIcon} />{" "}
            </div>
            <div className="aboutMainConatiner" style={{ paddingTop: 30 }}>
              <Form
                onFinish={handleSubmit}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                validateMessages={validateMessages}
              >
                <div className="donation-details-content-conatiner">
                  <img
                    className="donationDetailsIconsTst"
                    src={donationDetailsIcons}
                  />
                  <div className="dt-user-info">
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter a name" style={{ border: "1px solid #1F1E1B" }} />
                    </Form.Item>

                    <Form.Item
                      name="phone_number"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Enter the contact number",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <Input placeholder="Enter the contact number" style={{ border: "1px solid #1F1E1B" }} />
                    </Form.Item>
                    <Form.Item
                      name={["user", "email"]}
                      label="Email Id"
                      rules={[
                        {
                          // type: "email",
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <Input placeholder="  Enter the Email" style={{ border: "1px solid #1F1E1B" }} />
                    </Form.Item>
                    <Form.Item
                      name="donation_for"
                      label="Donation For"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select an option or enter a custom value",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <div className="donation-for-details11" style={{ marginTop: 5 }}>
                        <div className="donationField">
                          <div style={{ color: "1F1E1B", marginBottom: 10, fontWeight: "bold" }}>Select an option for the donation</div>
                          <Radio.Group
                            className="radioContainer"
                            onChange={handleRadioChange}
                          >
                            {radioOptions.map((option) => (
                              <Radio
                                className="radioOption"
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Radio>
                            ))}
                            <Radio value="donation_for_other" style={{ marginTop: 10 }}>Other</Radio>
                          </Radio.Group>
                        </div>
                        {selectedDonationForRadioValue ===
                          "donation_for_other" && (
                            <Form.Item
                              className="donation-for-details2"
                              name="customInput"
                              label="Custom Input"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a custom value",
                                },
                              ]}
                            >
                              <Input
                                value={customdonationForValue}
                                onChange={(e) =>
                                  setCustomDonationForValue(e.target.value)
                                }
                                placeholder="Enter custom value"
                                style={{ marginBottom: 5 }}
                              />
                            </Form.Item>
                          )}
                      </div>
                    </Form.Item>
                    <Form.Item
                      // className="donation-for-details-amount"
                      name="donation_amount"
                      label="Donation Amount"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select an option or enter a custom value",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <div className="donation-for-details11" style={{ marginTop: 5 }}>
                        <div style={{ color: "1F1E1B", marginBottom: 10, fontWeight: "bold" }}>Select an option for the donation amount</div>
                        <Radio.Group
                          className="radioContainer"
                          onChange={handleAmountRadioChange}
                        >
                          {amountOptions.map((option) => (
                            <Radio
                              className="radioOption"
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Radio>
                          ))}
                          <Radio value="donation_amount_val">Other</Radio>
                        </Radio.Group>
                        {selectDonationAmtRadioVal === "donation_amount_val" && (
                          <Form.Item
                            name="otherDonationAmount"
                            label="Other Donation Amount"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a custom value",
                              },
                            ]}
                            style={{ marginTop: 10 }}
                          >
                            <Input
                              value={customAmountValue}
                              onChange={(e) =>
                                setCustomDonationAmountValue(e.target.value)
                              }
                              placeholder="Enter custom value"
                              style={{ marginBottom: 5 }}

                            />
                          </Form.Item>
                        )}
                      </div>
                    </Form.Item>
                  </div>
                  <div className="dt-payment-info">
                    <div className="dt-payment-content-header">
                      <p>Scan the QR code for UPI Payments</p>
                      <p>Or</p>
                      <p>Transfer The Amount to ASOB’S IDFC Bank Account</p>
                      <div className="bank-details-subheading">
                        <p>Assam Society Of Bangalore</p>
                        <p>
                          {" "}
                          <span className="textColor-donation">
                            Account No. :
                          </span>{" "}
                          10036618579
                        </p>
                        <p>
                          {" "}
                          <span className="textColor-donation">
                            Account Type :
                          </span>{" "}
                          Current
                        </p>
                        <p>
                          {" "}
                          <span className="textColor-donation">IFSC : </span>
                          IDFB0080158
                        </p>
                        <p>
                          {" "}
                          <span className="textColor-donation">
                            Branch :
                          </span>{" "}
                          Sarjapura Road
                        </p>
                      </div>
                      <div className="payment-methods">
                        <img src={googlePayIcon} style={{ marginRight: 20 }} />
                        <img src={phonePayIcon} style={{ marginRight: 20 }} />
                        <img src={bhartPayIcon} />
                      </div>
                      <div className="bank-details-subheading">
                        <p>Assam Society Of Bangalore</p>
                        <p>asob.since.1982@gmail.com</p>
                        <p>+91- 89711431982</p>
                      </div>
                      <div className="orCodeImageIcon">
                        <img src={orCode} />
                      </div>
                      <div className="bank-details-subheading">
                        {" "}
                        <p>asob.since.1982@oksbi</p>
                      </div>
                      <div className="uploadPayment">
                        <Form.Item
                          className="uploadPymtConatiner"
                          name="image"
                          label="Attachment"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required: true,
                              message: "Please upload your payment screen shot ",
                            },
                          ]}
                        >
                          <Upload {...uploadProps} maxCount={1}>
                            <Button icon={<UploadOutlined />}>
                              Upload screenshot of payment
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <img
                    className="donationDetailsIconsTst"
                    src={donationDetailsIcons}
                  />
                </div>
                <Form.Item className="donation-submit-btn">
                  <Button
                    className="submitDonation"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Element>
          <Footer />
        </div>
      ) : (
        <div>
          <Element className="section-about-us-details1">
            <div className="aboutMainConatiner" style={{ paddingLeft: 20 }}>
              <div className='mbaboutunlabel'>
                <Link to="/">
                  <ArrowLeftOutlined /> Back
                </Link>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <img className="homembL" src={mbLRimg} />
              <div className="about-us-details-heading" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }} >
                <h2 style={{ fontSize: 24, fontWeight:"bold" }}>Contact Us</h2>
                <center>
                  <div className="about-us-details-content-container" style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "90%" }}>
                    <div style={{ marginTop: 60, display: "flex" }}>
                      <img src={phoneIcon} style={{ height: 30, width: 30, marginRight: 10, marginTop: -10 }} />
                      <div style={{ color: "1F1E1B", fontSize: 16 }}>882-223-7904, 889-294-2382, 874-800-8777</div>
                    </div>
                    <div style={{ marginTop: 40, display: "flex" }}>
                      <img src={emailIcon} style={{ height: 30, width: 30, marginRight: 10, marginTop: -10 }} />
                      <div style={{ color: "1F1E1B", fontSize: 16 }}>asobinfo@gmail.com</div>
                    </div>
                    <div style={{ marginTop: 30, display: "flex" }}>
                      <img src={locationIcon} style={{ height: 30, width: 30, marginRight: 10 }} />
                      <div style={{ color: "1F1E1B", lineHeight: 1.5, textAlign: "left", fontSize: 16 }}>4th floor, Registered office only) Axomi, 61, 1st Main Rd, KHB Colony, 7th Block, Koramangala, Bengaluru, Karnataka 560095</div>
                    </div>
                  </div>
                </center>
              </div>
            </div>
            <div className="about-us-details-heading" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <h2 style={{ fontSize: 24, fontWeight:"bold" }}>Contributions</h2>
            </div>
            <div className="aboutMainConatiner" style={{}}>
              <Form
                onFinish={handleSubmit}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                validateMessages={validateMessages}
              >
                <div className="donation-details-content-conatiner-mob">
                  <div className="dt-user-info" style={{ width: "92%" }}>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter a name" style={{ border: "1px solid #1F1E1B" }} />
                    </Form.Item>
                    <Form.Item
                      name="phone_number"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Enter the contact number",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <Input placeholder="Enter the contact number" style={{ border: "1px solid #1F1E1B" }} />
                    </Form.Item>
                    <Form.Item
                      name={["user", "email"]}
                      label="Email Id"
                      rules={[
                        {
                          // type: "email",
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <Input placeholder="  Enter the Email" style={{ border: "1px solid #1F1E1B" }} />
                    </Form.Item>
                    <Form.Item
                      name="donation_for"
                      label="Donation For"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select an option or enter a custom value",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <div className="donation-for-details11" style={{ marginTop: 5 }}>
                        <div className="donationField">
                          <div style={{ color: "1F1E1B", marginBottom: 10, fontWeight: "bold" }}>Select an option for the donation</div>
                          <Radio.Group
                            className="radioContainer"
                            onChange={handleRadioChange}
                          >
                            {radioOptions.map((option) => (
                              <Radio
                                className="radioOption"
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Radio>
                            ))}
                            <Radio value="donation_for_other" style={{ marginTop: 10 }}>Other</Radio>
                          </Radio.Group>
                        </div>
                        {selectedDonationForRadioValue ===
                          "donation_for_other" && (
                            <Form.Item
                              className="donation-for-details2"
                              name="customInput"
                              label="Custom Input"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter a custom value",
                                },
                              ]}
                            >
                              <Input
                                value={customdonationForValue}
                                onChange={(e) =>
                                  setCustomDonationForValue(e.target.value)
                                }
                                placeholder="Enter custom value"
                                style={{ marginBottom: 5 }}
                              />
                            </Form.Item>
                          )}
                      </div>
                    </Form.Item>
                    <Form.Item
                      // className="donation-for-details-amount"
                      name="donation_amount"
                      label="Donation Amount"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select an option or enter a custom value",
                        },
                      ]}
                      style={{ marginTop: 15 }}
                    >
                      <div className="donation-for-details11" style={{ marginTop: 5 }}>
                        <div style={{ color: "1F1E1B", marginBottom: 10, fontWeight: "bold" }}>Select an option for the donation amount</div>
                        <Radio.Group
                          className="radioContainer"
                          onChange={handleAmountRadioChange}
                        >
                          {amountOptions.map((option) => (
                            <Radio
                              className="radioOption"
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Radio>
                          ))}
                          <Radio value="donation_amount_val">Other</Radio>
                        </Radio.Group>
                        {selectDonationAmtRadioVal === "donation_amount_val" && (
                          <Form.Item
                            name="otherDonationAmount"
                            label="Other Donation Amount"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a custom value",
                              },
                            ]}
                            style={{ marginTop: 10 }}
                          >
                            <Input
                              value={customAmountValue}
                              onChange={(e) =>
                                setCustomDonationAmountValue(e.target.value)
                              }
                              placeholder="Enter custom value"
                              style={{ marginBottom: 5 }}

                            />
                          </Form.Item>
                        )}
                      </div>
                    </Form.Item>
                  </div>
                  <div className="dt-payment-info" style={{ width: "92%", margin: "20px 0px 0px 15px" }}>
                    <div className="dt-payment-content-header">
                      <p>Scan the QR code for UPI Payments</p>
                      <p>Or</p>
                      <p>Transfer The Amount to ASOB’S IDFC Bank Account</p>
                      <div className="bank-details-subheading">
                        <p>Assam Society Of Bangalore</p>
                        <p>
                          {" "}
                          <span className="textColor-donation">
                            Account No. :
                          </span>{" "}
                          10036618579
                        </p>
                        <p>
                          {" "}
                          <span className="textColor-donation">
                            Account Type :
                          </span>{" "}
                          Current
                        </p>
                        <p>
                          {" "}
                          <span className="textColor-donation">IFSC : </span>
                          IDFB0080158
                        </p>
                        <p>
                          {" "}
                          <span className="textColor-donation">
                            Branch :
                          </span>{" "}
                          Sarjapura Road
                        </p>
                      </div>
                      <div className="payment-methods">
                        <img src={googlePayIcon} style={{ marginRight: 20 }} />
                        <img src={phonePayIcon} style={{ marginRight: 20 }} />
                        <img src={bhartPayIcon} />
                      </div>
                      <div className="bank-details-subheading">
                        <p>Assam Society Of Bangalore</p>
                        <p>asob.since.1982@gmail.com</p>
                        <p>+91- 89711431982</p>
                      </div>
                      <div className="orCodeImageIcon">
                        <img src={orCode} />
                      </div>
                      <div className="bank-details-subheading">
                        {" "}
                        <p>asob.since.1982@oksbi</p>
                      </div>
                      <div className="uploadPayment">
                        <Form.Item
                          className="uploadPymtConatiner"
                          name="image"
                          label="Attachment"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required: true,
                              message: "Please upload your payment screen shot ",
                            },
                          ]}
                        >
                          <Upload {...uploadProps} maxCount={1}>
                            <Button icon={<UploadOutlined />}>
                              Upload screenshot of payment
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <Form.Item className="donation-submit-btn">
                  <Button
                    className="mbDDSubBtn"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <MobileFooter />
          </Element>
          {/* {loading ? (<div> <Spin size="large" tip="processing..." >
            <div className="mbDonationDetailsConatiner">
              <div className="mbSideBarDonationdtl">
                <img src={mbdonationsidebarimges} />
              </div>
              <div className="mbdonationContentConatiner">
                <div className="mbHeadingConatinerDD">
                  {" "}
                  <Link to="/">
                    <ArrowLeftOutlined /> Back
                  </Link>
                  <h3> Donations</h3>
                </div>
                <img src={mbdntdesign} />
                <div>
                  <Form
                    onFinish={handleSubmit}
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                  >
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a name",
                        },
                      ]}
                    >
                      <Input placeholder=" Name" />
                    </Form.Item>
                    <Form.Item
                      name="phone_number"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter the contact number",
                        },
                      ]}
                    >
                      <Input placeholder="Enter the contact number" />
                    </Form.Item>
                    <Form.Item
                      name={["email"]}
                      label="Email-(optional)"
                      rules={[
                        {
                          type: "email",


                        },
                      ]}
                    >
                      <Input placeholder=" Email" />
                    </Form.Item>
                    <div className="donation-for-details11">
                      <Form.Item
                        name="donation_for"
                        label="Donation For"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select an option or enter a custom value",
                          },
                        ]}
                      >
                        <div className="donationField">
                          <p>Select an option for the donation</p>
                          <Radio.Group
                            className="mbradioContainer"
                            onChange={handleRadioChange}
                          >
                            {radioOptions.map((option) => (
                              <Radio
                                className="radioOption"
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Radio>
                            ))}
                            <Radio value="donation_for_other">Other</Radio>
                          </Radio.Group>
                        </div>
                      </Form.Item>

                      {selectedDonationForRadioValue === "donation_for_other" && (
                        <Form.Item
                          className="donation-for-details2"
                          name="customInput"
                          label="Custom Input"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a custom value",
                            },
                          ]}
                        >
                          <Input
                            value={customdonationForValue}
                            onChange={(e) =>
                              setCustomDonationForValue(e.target.value)
                            }
                            placeholder="Enter custom value"
                          />
                        </Form.Item>
                      )}
                    </div>
                    <div className="mbddamountdetailsContainer">
                      <div>
                        {" "}
                        <Form.Item
                          className="mbdonation-for-details-amount"
                          name="donation_amount"
                          label="Donation Amount"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select an option or enter a custom value",
                            },
                          ]}
                        >
                          <div className="donationField">
                            <p>Select an option for the donation amount</p>
                            <Radio.Group
                              className="mbradioContainer"
                              onChange={handleAmountRadioChange}
                            >
                              {amountOptions.map((option) => (
                                <Radio
                                  className="radioOption"
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Radio>
                              ))}
                              <Radio value="donation_amount_val">Other</Radio>
                            </Radio.Group>
                          </div>
                        </Form.Item>
                        {selectDonationAmtRadioVal === "donation_amount_val" && (
                          <Form.Item
                            name="otherDonationAmount"
                            label="Other Donation Amount"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a custom value",
                              },
                            ]}
                          >
                            <Input
                              value={customAmountValue}
                              onChange={(e) =>
                                setCustomDonationAmountValue(e.target.value)
                              }
                              placeholder="Enter custom value"
                            />
                          </Form.Item>
                        )}
                      </div>
                      <div className="mbSideDesgnAmtCnt">
                        <img src={mbdonationsidebarimges} />
                      </div>
                    </div>
                    <div className="mbscannerDetailConatiner">
                      <div>
                        <p>Scan the QR code for UPI Payments</p>
                        <p>Or</p>
                        <p>Transfer The Amount to ASOB’S IDFC Bank Account</p>
                        <div className="bank-details-subheading">
                          <p>Assam Society Of Bangalore</p>
                          <p>
                            {" "}
                            <span className="textColor-donation">
                              Account No. :
                            </span>{" "}
                            10036618579
                          </p>
                          <p>
                            {" "}
                            <span className="textColor-donation">
                              Account Type :
                            </span>{" "}
                            Current
                          </p>
                          <p>
                            {" "}
                            <span className="textColor-donation">IFSC : </span>
                            IDFB0080158
                          </p>
                          <p>
                            {" "}
                            <span className="textColor-donation">
                              Branch :
                            </span>{" "}
                            Sarjapura Road
                          </p>
                        </div>
                        <div className="payment-methods">
                          <img src={googlePayIcon} />
                          <img src={phonePayIcon} />
                          <img src={bhartPayIcon} />
                        </div>
                        <div className="bank-details-subheading">
                          <p>Assam Society Of Bangalore</p>
                          <p>asob.since.1982@gmail.com</p>
                          <p>+91- 89711431982</p>
                        </div>
                        <div className="orCodeImageIcon">
                          <img src={orCode} />
                        </div>
                        <div className="bank-details-subheading">
                          {" "}
                          <p>asob.since.1982@oksbi</p>
                        </div>

                        <div className="uploadPayment">
                          <Form.Item
                            className="uploadPymtConatiner"
                            name="image"
                            label="Attachment"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: "Please upload your payment screen shot ",
                              },
                            ]}
                          >
                            <Upload {...uploadProps} maxCount={1}>
                              <Button icon={<UploadOutlined />}>
                                Upload screenshot of payment
                              </Button>
                            </Upload>
                          </Form.Item>
                        </div>
                        <Form.Item className="donation-submit-btn">
                          <Button
                            className="mbDDSubBtn"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Spin>

          </div>) : (
            <div className="mbDonationDetailsConatiner">
              <div className="mbSideBarDonationdtl">
                <img src={mbdonationsidebarimges} />
              </div>
              <div className="mbdonationContentConatiner">
                <div className="mbHeadingConatinerDD">
                  {" "}
                  <Link to="/">
                    <ArrowLeftOutlined /> Back
                  </Link>
                  <h3> Donations</h3>
                </div>
                <img src={mbdntdesign} />
                <div>
                  <Form
                    onFinish={handleSubmit}
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                  >
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a name",
                        },
                      ]}
                    >
                      <Input placeholder=" Name" />
                    </Form.Item>
                    <Form.Item
                      name="phone_number"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter the contact number",
                        },
                      ]}
                    >
                      <Input placeholder="Enter the contact number" />
                    </Form.Item>
                    <Form.Item
                      name={["email"]}
                      label="Email-(optional)"
                      rules={[
                        {
                          type: "email",


                        },
                      ]}
                    >
                      <Input placeholder=" Email" />
                    </Form.Item>
                    <div className="donation-for-details11">
                      <Form.Item
                        name="donation_for"
                        label="Donation For"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select an option or enter a custom value",
                          },
                        ]}
                      >
                        <div className="donationField">
                          <p>Select an option for the donation</p>
                          <Radio.Group
                            className="mbradioContainer"
                            onChange={handleRadioChange}
                          >
                            {radioOptions.map((option) => (
                              <Radio
                                className="radioOption"
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Radio>
                            ))}
                            <Radio value="donation_for_other">Other</Radio>
                          </Radio.Group>
                        </div>
                      </Form.Item>

                      {selectedDonationForRadioValue === "donation_for_other" && (
                        <Form.Item
                          className="donation-for-details2"
                          name="customInput"
                          label="Custom Input"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a custom value",
                            },
                          ]}
                        >
                          <Input
                            value={customdonationForValue}
                            onChange={(e) =>
                              setCustomDonationForValue(e.target.value)
                            }
                            placeholder="Enter custom value"
                          />
                        </Form.Item>
                      )}
                    </div>
                    <div className="mbddamountdetailsContainer">
                      <div>
                        {" "}
                        <Form.Item
                          className="mbdonation-for-details-amount"
                          name="donation_amount"
                          label="Donation Amount"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select an option or enter a custom value",
                            },
                          ]}
                        >
                          <div className="donationField">
                            <p>Select an option for the donation amount</p>
                            <Radio.Group
                              className="mbradioContainer"
                              onChange={handleAmountRadioChange}
                            >
                              {amountOptions.map((option) => (
                                <Radio
                                  className="radioOption"
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Radio>
                              ))}
                              <Radio value="donation_amount_val">Other</Radio>
                            </Radio.Group>
                          </div>
                        </Form.Item>
                        {selectDonationAmtRadioVal === "donation_amount_val" && (
                          <Form.Item
                            name="otherDonationAmount"
                            label="Other Donation Amount"
                            rules={[
                              {
                                required: true,
                                message: "Please enter a custom value",
                              },
                            ]}
                          >
                            <Input
                              value={customAmountValue}
                              onChange={(e) =>
                                setCustomDonationAmountValue(e.target.value)
                              }
                              placeholder="Enter custom value"
                            />
                          </Form.Item>
                        )}
                      </div>
                      <div className="mbSideDesgnAmtCnt">
                        <img src={mbdonationsidebarimges} />
                      </div>
                    </div>
                    <div className="mbscannerDetailConatiner">
                      <div>
                        <p>Scan the QR code for UPI Payments</p>
                        <p>Or</p>
                        <p>Transfer The Amount to ASOB’S IDFC Bank Account</p>
                        <div className="bank-details-subheading">
                          <p>Assam Society Of Bangalore</p>
                          <p>
                            {" "}
                            <span className="textColor-donation">
                              Account No. :
                            </span>{" "}
                            10036618579
                          </p>
                          <p>
                            {" "}
                            <span className="textColor-donation">
                              Account Type :
                            </span>{" "}
                            Current
                          </p>
                          <p>
                            {" "}
                            <span className="textColor-donation">IFSC : </span>
                            IDFB0080158
                          </p>
                          <p>
                            {" "}
                            <span className="textColor-donation">
                              Branch :
                            </span>{" "}
                            Sarjapura Road
                          </p>
                        </div>
                        <div className="payment-methods">
                          <img src={googlePayIcon} />
                          <img src={phonePayIcon} />
                          <img src={bhartPayIcon} />
                        </div>
                        <div className="bank-details-subheading">
                          <p>Assam Society Of Bangalore</p>
                          <p>asob.since.1982@gmail.com</p>
                          <p>+91- 89711431982</p>
                        </div>
                        <div className="orCodeImageIcon">
                          <img src={orCode} />
                        </div>
                        <div className="bank-details-subheading">
                          {" "}
                          <p>asob.since.1982@oksbi</p>
                        </div>

                        <div className="uploadPayment">
                          <Form.Item
                            className="uploadPymtConatiner"
                            name="image"
                            label="Attachment"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: "Please upload your payment screen shot ",
                              },
                            ]}
                          >
                            <Upload {...uploadProps} maxCount={1}>
                              <Button icon={<UploadOutlined />}>
                                Upload screenshot of payment
                              </Button>
                            </Upload>
                          </Form.Item>
                        </div>
                        <Form.Item className="donation-submit-btn">
                          <Button
                            className="mbDDSubBtn"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

export default DonationDetails;