import React, { useEffect } from "react";
import "./Style.css";
import "./aboutUS.css";
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import aboutUsDesign from "../assets/images/homeFooterDesign.svg";
import aboutUStopCircleIcon from "../assets/images/aboutUSCircleIcon.svg";
import aboutUSCircleBottomIcon from "../assets/images/aboutUSBottomIcon.svg";
import leftDirectionArrows from "../assets/images/leftDirectionArrows.svg";
import rightDirectionArrows from "../assets/images/rightDirectionArrow.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import useWindowDimensions from "../common/UseWindowDimensions";
import { useLocation } from "react-router-dom";
import mbsideBar from "../assets/images/sideBarDesign.svg";
import mbaboutUsDesign from "../assets/images/homeFooterDesign.svg";
import mbAbtdesign from "../assets/images/homeMbTB.svg";
function AboutUs() {
  let location = useLocation();
  const { height, width } = useWindowDimensions();
  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
},[])
  return (
    <div>
      {width > 600 ? (<div>
        <div 
        style={{marginTop:"2rem"}}
        className={
              location.pathname === "/"
                ? "mainHome"
                : "singlemainHome"
            } >
          <div className="homeSidebar">
            <img className="homesideBarConatainer" src={sideBar} />
          </div>
          <div className="aboutUSContent">
            <h3>About Us</h3>
            <div className="aboutUsDesignContainer">
              <img src={aboutUsDesign} />
              <img src={aboutUsDesign} />
            </div>
            <div class="element">
              <div className="aboutUsConatent-eng">
                <p>
                  The ‘Assam Society of Bangalore’ is a non-government,
                  non-political and a Socio-cultural organization with the aim of
                  building up unity and integrity among the people from Assam in
                  Bangalore with an effort of uplifting the rich Assamese culture
                  and building co-ordination between the peoples of Karnataka and
                  Assam in Bangalore.
                </p>
                <img src={aboutUStopCircleIcon} />
              </div>
              <div className="aboutUs-row-design">
                <div>
                  <img src={rightDirectionArrows} />
                </div>
                <div>
                  <img src={aboutUsDesign} />
                </div>
                <div>
                  <img src={leftDirectionArrows} />
                </div>
              </div>
              <div className="aboutUs-bottom-Content">
                <p>
                  অসম চ’ছাইটী, বাংগালোৰ হ’ল বাংগালোৰত থকা সমস্ত অসমীয়া লোকেৰে
                  সংগঠিত আৰু ১৯৮২ চনতে কৰ্ণাটক চ’ছাইটী পঞ্জীকৰণ অধিনিয়্ম অনুসাৰে
                  পঞ্জীকৃত এক সামাজিক আৰু সাংস্কৃতিক বেচৰকাৰী (NGO) সংস্থা । অসম
                  চ’ছাইটী বাংগালোৰ, উত্তৰ পূৱ ভাৰতৰ পৰা জীৱিকা আৰু অধ্যয়ন সুত্ৰে
                  বাংগালোৰ তথা কৰ্ণাটকৰ বিভিন্ন প্ৰান্তলৈ স্থায়ী বা অস্থায়ীভাৱে
                  অহা সকলো প্ৰৱাসী অসমীয়াৰ এক একত্ৰিত সংগঠন ।
                </p>
              </div>
            </div>
            <div className="about-content">
              <Link to="/aboutus-details">
                <button>Read More </button>
              </Link>
            </div>
          </div>
          <div className="homeLoad">
            <img className="loadCircle" src={loadCircle} />
          </div>
        </div>
      </div>) : (<div>
        <div  className={
              location.pathname === "/"
                ? "mbAboutUsMainConatainer"
                : "singlembAboutUsMainConatainer"
            }>
          <div className="mbelement">
            <div className="mbContentConatinerAbtUs">
              <h3 style={{fontWeight:"bold", marginTop:-10}}>About Us</h3>
              <img className='mbabtimg' src={mbAbtdesign} />
              <p>The ‘Assam Society of Bangalore’ is a non-government, non-political and a Socio-cultural organization with the aim of building up unity and integrity among the people from Assam in Bangalore with an effort of uplifting the rich Assamese culture and building co-ordination between the peoples of Karnataka and Assam in Bangalore.</p>
              <div className="mbAbtdesignRow">
                <img src={rightDirectionArrows} />
                <img src={mbaboutUsDesign} />
                <img src={leftDirectionArrows} />
              </div>
              <p>অসম চ’ছাইটী, বাংগালোৰ হ’ল বাংগালোৰত থকা সমস্ত অসমীয়া লোকেৰে সংগঠিত আৰু ১৯৮২ চনতে কৰ্ণাটক চ’ছাইটী পঞ্জীকৰণ অধিনিয়্ম অনুসাৰে পঞ্জীকৃত এক সামাজিক আৰু সাংস্কৃতিক বেচৰকাৰী (NGO) সংস্থা । অসম চ’ছাইটী বাংগালোৰ, উত্তৰ পূৱ ভাৰতৰ পৰা জীৱিকা আৰু অধ্যয়ন সুত্ৰে বাংগালোৰ তথা কৰ্ণাটকৰ বিভিন্ন প্ৰান্তলৈ স্থায়ী বা অস্থায়ীভাৱে অহা সকলো প্ৰৱাসী অসমীয়াৰ এক একত্ৰিত সংগঠন ।</p>
            </div>
            <div className="mbreadMoreBtn">
              <Link to="/aboutus-details">
                <button>Read More</button>
              </Link>
            </div>
          </div>
          <div className="mbSideBarConatiner">
            <img src={mbsideBar} />
          </div>
        </div>
      </div>)}
    </div>
  );
}
export default AboutUs;