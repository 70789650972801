import { SET_NAV_BAR_ID, SET_FORM_DATA } from "../action/asob";

const initialState = {
    navBarId: 1,
    formValues: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAV_BAR_ID:
            return {
                ...state,
                navBarId: action.val
            }

        case SET_FORM_DATA:
            let temp = { ...state.formValues };
            temp[action.key] = action.val;
            return {
                ...state,
                formValues: temp,
            }

        default:
            return state;
    }
};

export default reducer;