import React, { useState } from "react";
import "./Style.css";
import Header from "./Header";
import Sections from "./Sections";
import sideCol from "../assets/images/sideCol.svg";
import sideBarDesign from "../assets/images/sideBarDesign.svg";
import {
  CommentOutlined,
  InstagramOutlined,
  FacebookFilled,
} from "@ant-design/icons";
import { FloatButton, Switch } from "antd";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from 'react-router-dom';

function AppLayout() {
  const [open, setOpen] = useState(true);
  const onChange = (checked) => {
    setOpen(checked);
  };
  return (
    <div className="container">
      <div>
        {/* <Header /> */}
      </div>
      <div>
        <Sections />
      </div>

      <div>
      {/* <Outlet /> */}
        </div>

    </div>
  );
}

export default AppLayout;
