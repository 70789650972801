import React, { useState } from "react";
import "./Style.css";
import "./galleryStyle.css";
import gallerySideBarContainerimg from "../assets/images/sideBarDesign.svg";
import sideBarLoader from "../assets/images/Loader1.svg";
import galleryImage from "../assets/images/galleryImage.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../common/UseWindowDimensions";
import mbdesign from "../assets/images/homeMbTB.svg";
import mbsideBar from "../assets/images/mbLR.svg";
import mbdurgaPujaImages1 from "../assets/images/durgapuja/durapuja1.png";
import mbdurgaPujaImages2 from "../assets/images/durgapuja/durapuja2.png";
import mbdurgaPujaImages3 from "../assets/images/durgapuja/durapuja3.png";
import mbdurgaPujaImages4 from "../assets/images/durgapuja/durapuja4.png";
import mbdurgaPujaImages5 from "../assets/images/durgapuja/durapuja5.png";
import mbdurgaPujaImages6 from "../assets/images/durgapuja/durapuja6.png";
import { Carousel } from "antd";
import { setNavBarId } from "../action/asob";
import { connect } from "react-redux";

function Gallery({ setNavBarId }) {
  const [folderName, setFolderName] = useState([]);
  let location = useLocation();
  const { height, width } = useWindowDimensions();
  const galleryImages = [
    { image: mbdurgaPujaImages1 },
    { image: mbdurgaPujaImages2 },
    { image: mbdurgaPujaImages3 },
    { image: mbdurgaPujaImages4 },
    { image: mbdurgaPujaImages5 },
    { image: mbdurgaPujaImages6 },
  ];
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  return (
    <div>
      {width > 600 ? (
        <div>
          <div
            style={{ marginTop: "2rem" }}
            className={
              location.pathname === "/"
                ? "galleryMainContainer"
                : "singlegalleryMainContainer"
            }
          >
            <div className="gallerySideBar">
              <img
                className="gallerySideBarContainer"
                src={gallerySideBarContainerimg}
              />
            </div>
            <div className="galleryContent">
              <h2>Gallery</h2>
              <img src={galleryImage} />
              {/* <div className="gallerybtn-content">
                <Link to="/gallery-details" onClick={()=>{setNavBarId(3)}}>
                  <button> View More</button>
                </Link>
              </div> */}
            </div>
            <div className="galleryLoad">
              <img className="galleryLoaderContainer" src={sideBarLoader} />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ boxSizing: "border-box" }}>
          <div
            className={
              location.pathname === "/"
                ? "mbglryMainContainer"
                : "singlembglryMainContainer"
            }
          >
            {/* <div className="mbglryContentConatiner">
              <h3>Gallery</h3>
              <img className="gtlimg" src={mbdesign} />
              <div className="mbglryImgConatiner">
                <Carousel afterChange={onChange}>
                  {galleryImages?.map((ele) => {
                    return (
                      <div className="mbglryImage">
                        <img src={ele.image} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div className="mbglrybtnConatiner">
                <Link to="/gallery-details">
                  <button> View More</button>
                </Link>
              </div>
              <img src={mbdesign} />
            </div>
            <div>
              <img className="mbsideBarglry-conatiner" src={mbsideBar} />
            </div> */}
            <div className="mbglryContentConatiner" >
              <h3 style={{ fontWeight: "bold", marginTop: 30 }}>Gallery</h3>
              <img className="gtlimg" src={mbdesign} />
              <div className="mbglryContentHolder">
                <div className="mbglryImgConatiner">
                  <Carousel autoplay afterChange={onChange}>
                    {galleryImages.map((ele) => {
                      return (
                        <div className="mbglryImage">
                          <img src={ele.image} />
                        </div>
                      );
                    })}
                  </Carousel>
                  {/* <div className="mbglrybtnConatiner">
                    <Link to="/gallery-details">
                      <button> View More</button>
                    </Link>
                  </div> */}
                  <img src={mbdesign} />
                </div>

                <div className="mbsideBarglry-conatiner">
                  <img src={mbsideBar} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  const { navBarId } = state.asob;
  return {
    navBarId
  }
};
const mapDispatchToProps = (dispatch) => ({
  setNavBarId: (val) => dispatch(setNavBarId(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
