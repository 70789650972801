import React, { useState } from 'react';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import useWindowDimensions from '../common/UseWindowDimensions';
import { Button, Input, Upload, Form, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import googlePayIcon from "../assets/images/google-pay-seeklogo.com.svg";
import phonePayIcon from "../assets/images/phonepe-seeklogo.com.svg";
import bhartPayIcon from "../assets/images/bharat-interface-for-money-bhim-logo-vector.svg";
import orCode from "../assets/images/orCodeForScanner.png";
import axios from 'axios';
import baseUrl from "../common/baseUrl";
import sideBar from "../assets/images/sideBarDesign.svg";
import Footer from '../common/Footer';
import loadCircle from "../assets/images/Loader1.svg";
import donationDetailsIcons from "../assets/images/donationdtIcons.svg";

const headerDesign = require('../assets/images/headerDesign.svg').default;

const Membership = () => {
    const { width } = useWindowDimensions();

    const [fields, setFields] = useState({ name: '', phone_number: null, email: '', membership: 750 })
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const customRequest = ({ file, onSuccess }) => {
        if (file) {
            setImageFile(file);
            setTimeout(() => {
                console.log("Upload complete");
                onSuccess("ok");
            }, 1000);
        }
    };

    const uploadProps = {
        customRequest: customRequest,
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            for (const key in fields) {
                formData.append(key, fields[key]);
            };

            formData.append('image', imageFile);

            const response = await axios.post(`${baseUrl}/user/membership`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false);

            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: response.data,
                    placement: 'topRight',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong with the request.',
                    placement: 'topRight',
                });
            }
            for (const key of formData.keys()) {
                formData.delete(key);
            }
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Error',
                description: 'An error occurred while submitting the data.',
                placement: 'topRight',
            });
        }
    };

    return (
        <div style={{ backgroundColor: '#FFFCF9', padding: 20, margin: -20, height: '100%' }}>
            {width > 600 ? (
                <div>
                    <div className="homeSidebar-about-us-details">
                        <img
                            className="homesideBarConatainer-about-us-details"
                            src={sideBar}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', marginTop: -450, marginLeft: 130 }}>
                        <div className='' style={{}}>
                            <Link to="/">
                                <ArrowLeftOutlined style={{ color: 'black' }} />
                            </Link><span style={{ marginLeft: 5 }}>Back</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#bb0a00', fontWeight: 600, fontSize: 35 }}>Membership</div>
                            <img src={headerDesign} style={{ width: 100, marginTop: 10, marginLeft: 20 }} />
                        </div>
                    </div>
                    <div className="homeLoad-about-us-details" style={{ marginLeft: '95%' }}>
                        <img className="loadCircle-about-us-details" src={loadCircle} />
                    </div>
                    <div style={{ marginLeft: 150 }}>
                        <img
                            className="donationDetailsIconsTst"
                            src={donationDetailsIcons}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginLeft: 210, marginTop: -500 }}>
                        <div style={{ marginLeft: 50, marginTop: 0, border: '1px solid #E6E6E6', height: 'fit-content', padding: 10, width: 500 }}>
                            <div>Name<span style={{ color: 'red' }}>*</span></div>
                            <Input name='name' placeholder='Enter name' value={fields?.name} onChange={handleInputChange} style={{ width: '100%', marginTop: 5 }} />

                            <div style={{ marginTop: 20 }}>Contact Number<span style={{ color: 'red' }}>*</span></div>
                            <Input name='phone_number' placeholder='Enter contact number' value={fields?.mobileNumber} onChange={handleInputChange} style={{ width: '100%', marginTop: 5 }} />

                            <div style={{ marginTop: 20 }}>Email Id</div>
                            <Input name='email' placeholder='Enter email' value={fields?.emailId} onChange={handleInputChange} style={{ width: '100%', marginTop: 5 }} />

                            <div style={{ marginTop: 20 }}>Membership Fee</div>
                            <div style={{ width: '95%', height: 30, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', border: '1px solid #E6E6E6', borderRadius: 6, paddingLeft: 10 }}>750</div>
                        </div>
                        <div className="dt-payment-info">
                            <div className="dt-payment-content-header">
                                <p>Scan the QR code for UPI Payments</p>
                                <p>Or</p>
                                <p>Transfer The Amount to ASOB’S IDFC Bank Account</p>
                                <div className="bank-details-subheading">
                                    <p>Assam Society Of Bangalore</p>
                                    <p>
                                        {" "}
                                        <span className="textColor-donation">
                                            Account No. :
                                        </span>{" "}
                                        10036618579
                                    </p>
                                    <p>
                                        {" "}
                                        <span className="textColor-donation">
                                            Account Type :
                                        </span>{" "}
                                        Current
                                    </p>
                                    <p>
                                        {" "}
                                        <span className="textColor-donation">IFSC : </span>
                                        IDFB0080158
                                    </p>
                                    <p>
                                        {" "}
                                        <span className="textColor-donation">
                                            Branch :
                                        </span>{" "}
                                        Sarjapura Road
                                    </p>
                                </div>
                                <div className="payment-methods">
                                    <img src={googlePayIcon} />
                                    <img src={phonePayIcon} />
                                    <img src={bhartPayIcon} />
                                </div>
                                <div className="bank-details-subheading">
                                    <p>Assam Society Of Bangalore</p>
                                    <p>asob.since.1982@gmail.com</p>
                                    <p>+91- 89711431982</p>
                                </div>
                                <div className="bank-details-subheading">
                                    <p>Membership Contact Details:</p>
                                    <p>Sabir Borah: 8822237904</p>
                                    <p>Abhijit Thapa: 9739003056</p>
                                </div>
                                <div className="orCodeImageIcon">
                                    <img src={orCode} />
                                </div>
                                <div className="bank-details-subheading">
                                    {" "}
                                    <p>asob.since.1982@oksbi</p>
                                </div>
                                <div className="uploadPayment">
                                    <Form.Item
                                        className="uploadPymtConatiner"
                                        name="image"
                                        label="Attachment"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please upload your payment screenshot",
                                            },
                                        ]}
                                    >
                                        <Upload {...uploadProps} maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Upload screenshot of payment</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, marginTop: 240 }}>
                            <img
                                className="donationDetailsIconsTst"
                                src={donationDetailsIcons}
                            />
                        </div>
                    </div>
                    <Form.Item
                        className="donation-submit-btn" >
                        <Button
                            className="submitDonation"
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Form.Item>

                    <Footer />
                </div>
            ) : (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '62%', marginTop: 100, marginLeft: 40 }}>
                        <div className='' style={{}}>
                            <Link to="/">
                                <ArrowLeftOutlined style={{ color: 'black' }} />
                            </Link>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ color: '#bb0a00', fontWeight: 600, fontSize: 24 }}>Membership</div>
                            <img src={headerDesign} style={{ width: 100, marginTop: 10, marginLeft: 20 }} />
                        </div>
                    </div>
                    <div style={{ marginLeft: 10, marginTop: 0, border: '1px solid #E6E6E6', height: 'fit-content', padding: 10, width: 350 }}>
                        <div>Name<span style={{ color: 'red' }}>*</span></div>
                        <Input name='name' placeholder='Enter name' value={fields?.name} onChange={handleInputChange} style={{ width: '100%', marginTop: 5 }} />

                        <div style={{ marginTop: 20 }}>Contact Number<span style={{ color: 'red' }}>*</span></div>
                        <Input name='phone_number' placeholder='Enter contact number' value={fields?.mobileNumber} onChange={handleInputChange} style={{ width: '100%', marginTop: 5 }} />

                        <div style={{ marginTop: 20 }}>Email Id</div>
                        <Input name='email' placeholder='Enter email' value={fields?.emailId} onChange={handleInputChange} style={{ width: '100%', marginTop: 5 }} />

                        <div style={{ marginTop: 20 }}>Membership Fee</div>
                        <div style={{ width: '95%', height: 30, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', border: '1px solid #E6E6E6', borderRadius: 6, paddingLeft: 10 }}>750</div>
                    </div>
                    <div className="mbscannerDetailConatiner" style={{ marginLeft: 20 }}>
                        <center>
                            <p>Scan the QR code for UPI Payments</p>
                            <p>Or</p>
                            <p>Transfer The Amount to ASOB’S IDFC Bank Account</p>
                            <div className="bank-details-subheading">
                                <p>Assam Society Of Bangalore</p>
                                <p>
                                    {" "}
                                    <span className="textColor-donation">
                                        Account No. :
                                    </span>{" "}
                                    10036618579
                                </p>
                                <p>
                                    {" "}
                                    <span className="textColor-donation">
                                        Account Type :
                                    </span>{" "}
                                    Current
                                </p>
                                <p>
                                    {" "}
                                    <span className="textColor-donation">IFSC : </span>
                                    IDFB0080158
                                </p>
                                <p>
                                    {" "}
                                    <span className="textColor-donation">
                                        Branch :
                                    </span>{" "}
                                    Sarjapura Road
                                </p>
                            </div>
                            <div className="payment-methods">
                                <img src={googlePayIcon} />
                                <img src={phonePayIcon} />
                                <img src={bhartPayIcon} />
                            </div>
                            <div className="bank-details-subheading">
                                <p>Assam Society Of Bangalore</p>
                                <p>asob.since.1982@gmail.com</p>
                                <p>+91- 89711431982</p>
                            </div>
                            <div className="bank-details-subheading">
                                <p>Membership Contact Details:</p>
                                <p>Sabir Borah: 8822237904</p>
                                <p>Abhijit Thapa: 9739003056</p>
                            </div>
                            <div className="orCodeImageIcon">
                                <img src={orCode} />
                            </div>
                            <div className="bank-details-subheading">
                                {" "}
                                <p>asob.since.1982@oksbi</p>
                            </div>

                            <div className="uploadPayment" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Form.Item
                                    className="uploadPymtConatiner"
                                    name="image"
                                    // label="Attachment"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please upload your payment screen shot ",
                                        },
                                    ]}
                                >
                                    <Upload {...uploadProps} maxCount={1}>
                                        <Button icon={<UploadOutlined />}>
                                            Upload screenshot of payment
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <Form.Item className="donation-submit-btn">
                                <Button
                                    className="mbDDSubBtn"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </center>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Membership;
