import React from "react";
import "./headerstyles.css";
import footerbg from "../assets/images/mbfooter.svg";
import tmgtimg from "../assets/images/mbsetb.png";
import mflogo from "../assets/images/logo.png";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "antd";

function MobileFooter() {
  let location = useLocation();
  const duration = 310;

  const handleClickRoute = (val) => {
    if (location.pathname !== "/") {
      window.location.href = val;
    }
  };
  const navigate = useNavigate();

  return (
    <div className="mbFooterCon">
      <img className="mbfooterbg" src={footerbg} />
      <img className="mbft" src={tmgtimg} />
      <div className="poweredByAulasMobile" style={{ color: "#1F1E1B", fontSize: 10 }}>Powered by <img src={require('../assets/images/common/aulas logo.svg').default} style={{ width: 50, marginLeft: 5, marginTop: -3 }} /></div>
      <div className="tstFooter">
        <div className="tstFooterTop" >
          <img className="mflogo" src={mflogo} />
          <div className="mbnavs">
            <p style={{ margin: "0", padding: "0 0 5px 0" }}>
              <div
                onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); }}
              >
                Home
              </div>
            </p>
            <p style={{ margin: "0", padding: "5px 0px" }}>
              <div
                onClick={() => { window.scrollTo({ top: 1150, left: 0, behavior: "smooth" }); }}
              >
                Events
              </div>
            </p>
            <p style={{ margin: "0", padding: "5px 0px" }}>
              <div
                onClick={() => { window.scrollTo({ top: 1700, left: 0, behavior: "smooth" }); }}
              >
                Gallery
              </div>
            </p>
            <p style={{ margin: "0", padding: "5px 0px" }}>
              <div
                onClick={() => { window.scrollTo({ top: 2200, left: 0, behavior: "smooth" }); }}
              >
                Members
              </div>
            </p>
            <p style={{ margin: "0", padding: "5px 0px" }}>
              <div
                onClick={() => { window.scrollTo({ top: 4050, left: 0, behavior: "smooth" }); }}
              >
                About Us
              </div>
            </p>
            <p style={{ margin: "0", padding: "5px 0px" }}>
            {/* <span style={{ color: "#AF180F", cursor: "pointer" }}> */}
              <div onClick={() => { navigate("/terms-and-conditions") }}>FAQs</div>
            </p>
          </div>
          <div className="mbcontact">
            <p style={{ margin: "0", padding: "0", fontWeight: "bold" }}>Our Office </p>
            <p style={{ margin: "0", padding: "5px 0px", fontSize: 12, lineHeight: 1.5 }}>
              4th Floor, Registered Office Only Axomi, 61, 1st Main Rd, Khb
              Colony, 7th Block, Koramangala, Bengaluru, Karnataka 560095
            </p>
          </div>
        </div>
        <div className="footerCpyWrt" style={{ marginLeft: 20, marginTop: 2 }}>
          <Typography.Text>
            Copyright &copy; {new Date().getFullYear()},  Assam Society Of
            Bangalore
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}

export default MobileFooter;
