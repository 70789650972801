import React, { useState, useEffect } from "react";
import Footer from "../common/Footer";
import sideBar from "../assets/images/sideBarDesign.svg";
import loadCircle from "../assets/images/Loader1.svg";
import { Element } from "react-scroll";
import { ArrowLeftOutlined } from "@ant-design/icons";
import assamSingleDanceIcon from "../assets/images/assamSingleDanceIcon.svg";
import assamGrpDanceIcon from "../assets/images/assamGrpDanceIcon.svg";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import designIcon from "../assets/images/homeFooterDesign.svg";
import DurgaPujaImages from "./DurgaPujaImages";
import BihuImages from "./BihuImages";
import DurgaPujaone from "./DurgaPujaone";
import axios from "axios";
import tstImg1 from "../assets/images/bihu/bihuImage1.jpeg";
import tstImg2 from "../assets/images/bihu/bihuImage11.jpeg";
import tstImg3 from "../assets/images/bihu/bihuImage12.jpeg";
import tstImg4 from "../assets/images/bihu/bihuImage14.jpeg";
import tstImg5 from "../assets/images/bihu/bihuImage13.jpeg";
import tstImg6 from "../assets/images/bihu/bihuImage18.jpeg";
import mblr from "../assets/images/mbLR.svg";
import useWindowDimensions from "../common/UseWindowDimensions";
import "./mobilegd.css";
import "./galleryStyle.css";
import mbgdlngimg from "../assets/images/mblongimg.svg";
import MobileFooter from "../common/MobileFooter";
import baseUrl from "../common/baseUrl";
import CryptoJS from "crypto-js";
import LoaderLottie from "../common/LoaderLottie";
import { Spin } from "antd";

function GalleryDetails() {
  const SECRET_KEY = "assam_society@bengaluru";
  const { height, width } = useWindowDimensions();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [eventFolders, setEventFolders] = useState([]);

  const [selectedTab, setSelectedTab] = useState({});
  // console.log("loggg",eventFolders[0]?.folderId);
  const [driveImages, setDriveImages] = useState([]);
  const [selectedLorgeImg, setSelectedLorgeImg] = useState("");

  const tstImgArr = [
    tstImg1,
    tstImg2,
    tstImg3,
    tstImg4,
    tstImg5,
    tstImg1,
    tstImg1,
  ];

  useEffect(() => {
    axios
      .post(`${baseUrl}/drive/accessFolders`, {})
      .then((response) => {
        const decryptedFolders = response.data?.map((folder) => {
          try {
            const decryptedFolderName = CryptoJS.AES.decrypt(
              folder.folderName,
              SECRET_KEY
            ).toString(CryptoJS.enc.Utf8);

            return { ...folder, folderName: decryptedFolderName };
          } catch (error) {
            console.error("Decryption error:", error);
            // return {...folder,folderName:'Decryption error'};
          }
        });

        console.log("ddd", decryptedFolders);
        setEventFolders(decryptedFolders);
        setSelectedTab({
          tab: decryptedFolders[0]?.folderName,
          folderId: decryptedFolders[0]?.folderId,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleTabClick = (selectedFolder) => {
    eventFolders.forEach((element) => {
      element.isActive = false;
      if (selectedFolder.folderName == element.folderName) {
        setSelectedTab({ tab: element.folderName, folderId: element.folderId });
        element.isActive = true;
      }
    });
  };
  useEffect(() => {
    const selectedFolder = selectedTab;
    handleTabClick(selectedFolder);
    setIsLoading(true);
   if(baseUrl&&selectedTab.folderId)
   {

    axios
    .post(`${baseUrl}/drive/accessImages `, {
      folderId: selectedTab.folderId,
    })
    .then((response) => {
      const decryptedImage = response.data?.map((imageId, index) => {
        try {
          const decryptedFolderImage = CryptoJS.AES.decrypt(
            imageId,
            SECRET_KEY
          ).toString(CryptoJS.enc.Utf8);
          return decryptedFolderImage;
        } catch (error) {
          console.error("Decryption error:", error);
          // return [...imageId,'Decryption error'];
        }
      });

      setDriveImages(decryptedImage);
      console.log("decryptedImage", decryptedImage);
      setSelectedLorgeImg(decryptedImage[0]);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error("show error",error);
     
         setIsLoading(false);
      
      
    });
   }
   
     

  }, [selectedTab]);

  document.addEventListener("DOMContentLoaded", function () {
    const img = document.getElementById("myImage");

    img.addEventListener("load", () => {
      console.log("Image has loaded.");
    });

    img.addEventListener("error", () => {
      console.log("Error loading the image.");
    });
  });

  const isTabActive = (index) => {
    return activeTab === index;
  };

  return (
    <div>
      {width > 600 ? (
        <div>
          <div className="gdContainer" >
            <div className="homeSidebar-about-us-details">
              <img
                className="homesideBarConatainer-about-us-details"
                src={sideBar}
              />
            </div>
            <div className="gdMainHeader" >
            <div className="gdHeader" style={{marginBottom:"1rem",border:"2px solid #fff", marginTop:20}} >
                <h2 style={{fontWeight:"bold"}}>Gallery</h2>
                <div className="goBack">
                  <Link to="/">
                    <p>
                      <ArrowLeftOutlined />
                      Back
                    </p>
                  </Link>
                </div>
              </div>
              {isLoading ? 
                  <div style={{display:"flex",height:"50vh",alignItems:"center",justifyContent:"center",margin:"0 auto"}}>
                        <Spin />
                  </div>:<><div className="gdnavcontainer" style={{marginBottom:"1rem",}}>
                <img src={designIcon} />
                <div className="folderListContainer" style={{marginBottom:"0.5rem"}}>
                  {eventFolders?.map((ele) => (
                    <div className="folderName" key={ele.id}>
                      <h3
                        onClick={() => handleTabClick(ele)}
                        className={
                          ele.folderName == selectedTab.tab ? "selectedTab" : ""
                        }
                      >
                        {ele.folderName}
                      </h3>
                    </div>
                  ))}
                </div>
                <img src={designIcon} />
              </div>
              <div className="gdimgcontainer">
                <div className="subgdimgcontainer">
                  <div className="flex-row-container">
                  
                    
                      <div>
                        <div className="tsttcontainer">
                          {driveImages.map((ele, index) => (
                            <img
                              id="myImage"
                              onClick={() => setSelectedLorgeImg(ele)}
                              key={index}
                              src={ele}
                              alt={`Image ${index + 1}`}
                            />
                          ))}
                        </div>
                      </div>
                    
                  </div>
                </div>

                <div className="gdsingleimg">
                  {selectedLorgeImg?.length > 0 ? (
                    <img
                      className="selectedLorgeImg"
                      src={selectedLorgeImg}
                      alt="Image not found"
                    />
                  ) : (
                    <img
                      className="selectedLorgeImg"
                      src={driveImages?.[0]}
                      alt="Image not found"
                    />
                  )}
                </div>
              </div>
              </>}
            </div>

            <div className="homeLoad-about-us-details">
              <img className="loadCircle-about-us-details" src={loadCircle} />
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div className="mbgdcontainer" >
            <div className="mbgoBack" style={{paddingLeft:"1rem", }}>
              <Link to="/">
                <a>
                  <ArrowLeftOutlined />{" "}
                  Back
                </a>
              </Link>
            </div>
            <div className="mbgdfirst">
              <div>
                <img src={mblr} />
              </div>
              <div className="mbfstcontenet">
                <h3 className="gdlabel" style={{fontWeight:"bold"}}>Gallery</h3>
                <img className="gdlng" src={mbgdlngimg} />
                <div className="mbfolderListContainer">
                  {eventFolders?.map((ele) => (
                    <div className="mbfolderName" key={ele.id}>
                      <h4
                        onClick={() => handleTabClick(ele)}
                        className={
                          ele.folderName == selectedTab.tab ? "selectedTab" : ""
                        }
                      >
                        {ele.folderName}
                      </h4>
                    </div>
                  ))}
                </div>
                <div className="mbsingleImgcont">
                  {isLoading ? (
                      <div>
                        {/* <LoaderLottie /> */}
                        <Spin />
                      </div>
                    ):(<div>
                             {selectedLorgeImg?.length > 0 ? (
                    <img
                    style={{width:"70vw",objectFit:"fill",height:"37vh"}}
                      className="selectedLorgeImg"
                      src={selectedLorgeImg}
                      alt="Image not found"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      style={{width:"70vw",objectFit:"fill",height:"37vh"}}
                      className="selectedLorgeImg"
                      src={driveImages?.[0]}
                      alt="Image not found"
                      loading="lazy"
                    />
                  )}
                    </div>)}
           
                </div>
              </div>
            </div>
            <div className="mbgsnd">
              <div className="image-gallery">
            
                
                  {isLoading ?
                    <div style={{display:"flex",margin:"0rem auto",width:"90vw",alignItems:"center",justifyContent:"center",}}>
                      <Spin />
                    </div>
                  :
                driveImages?.map((filename, index) => (
                  <div className="image-container-sub" key={index}>
                        <div >
                          <img
                      className="tstEvnetImgggggg"
                      src={filename}
                      onClick={() => setSelectedLorgeImg(filename)}
                      alt={`Image ${index + 1}`}
                      loading="lazy"
                    />
                    </div>
                
                  </div>
                ))}
              </div>

              <div>
                <img src={mblr} />
              </div>
            </div>
          </div>

          <MobileFooter />
        </div>
      )}
    </div>
  );
}

export default GalleryDetails;
