import React from "react";
import "./Style.css";
import footerDesign from "../assets/images/homeFooterDesign.svg";
import tst from "../assets/images/footerdesign.svg";
import logo from "../assets/images/logo.png";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "antd";

function Footer() {
  let location = useLocation();

  const duration = 310;

  const handleClickRoute = (val) => {
    if (location.pathname !== "/") {
      window.location.href = val;
    }
  };
  const navigate = useNavigate();

  return (
    <div>
      <div className="footer">
        <img className="footerImg" src={footerDesign} />
        <div className="poweredByAulas" style={{ color: "#1F1E1B", fontSize: 15 }}>Powered by <img src={require('../assets/images/common/aulas logo.svg').default} style={{ width: 70, marginTop: -5 }} /></div>
        <div className="rr">
          <img className="footerBg" src={tst} />
        </div>
        <div className="tt">
          <img src={logo} />
          <div className='hoverLinks'>
            <p>
              <Link
                activeClass="active"
                to="section-1"
                spy
                smooth
                onClick={() => handleClickRoute("/")}
                duration={duration}
              >
                Home
              </Link>
            </p>
            <p>
              <Link
                activeClass="active"
                to="section-2"
                spy
                smooth
                duration={duration}
                onClick={() => handleClickRoute("/events")}

              >
                Events
              </Link>
            </p>
            <p>
              <Link
                activeClass="active"
                to="section-4"
                spy
                smooth
                duration={duration}
                onClick={() => handleClickRoute("/members")}

              >
                Members
              </Link>
            </p>
          </div>
          <div className='hoverLinks'>
            <p>
              <Link
                activeClass="active"
                to="section-3"
                spy
                smooth
                duration={duration}
                onClick={() => handleClickRoute("/gallery")}

              >
                Gallery
              </Link>
            </p>
            <p>
              <Link
                activeClass="active"
                to="section-5"
                spy
                smooth
                duration={duration}
                onClick={() => handleClickRoute("/aboutUs")}

              >
                About Us
              </Link>
              
            </p>

            <p>
                <Link
                  activeClass="active"
                  spy
                  smooth
                  duration={duration}
                  onClick={() => { navigate("/terms-and-conditions") }}
                >
                  FAQs
                </Link>
              </p>
            {/* <p>
              <Link
                activeClass="active"
                to="section-6"
                spy
                smooth
                duration={duration}
                onClick={() => handleClickRoute("/donation")}
              >
                Donation

              </Link>
            </p> */}
          </div>
          <div style={{ marginRight: 50 }}>
            <p>Our office</p>
            <p style={{ lineHeight: 1.5 }}>
              4th Floor, Registered Office Only
              <br /> Axomi, 61, 1st Main Rd, Khb Colony,
              <br /> 7th Block, Koramangala, Bengaluru,
              <br /> Karnataka 560095
            </p>
          </div>
        </div>
        <div className="footerCntCpyWeb" style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0px 240px" }}>
          <Typography.Text>
            Copyright &copy; {new Date().getFullYear()},  Assam Society Of
            Bangalore <span style={{ color: "#AF180F", cursor: "pointer" }}></span>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}

export default Footer;
