export const SET_NAV_BAR_ID = "SET_NAV_BAR_ID"
export const SET_FORM_DATA = 'SET_FORM_DATA'

export const setNavBarId = (val) => ({
    type: SET_NAV_BAR_ID,
    val
})

export const setFormValues = (key, val) => ({
    type: SET_FORM_DATA,
    key, val
})