import React, { useEffect, useState } from "react";
import "./App.css";
import AppLayout from "./common/AppLayout";
import LoaderLottie from "../src/common/LoaderLottie";
import { BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";
import { saveToLocalStorage, loadFromLocalStorage } from "./store";
import { Button, Modal } from "antd";
import GalleryDetails from "./components/GalleryDetails";
import AboutUSDetails from "./components/AboutUSDetails";
import DonationDetails from "./components/DonationDetails";
import MembersDetails from "./components/MembersDetails";
import Header from "./common/Header";
import instagramIcon from "./assets/images/instagramIcon.png";
import faceBookIcon from "./assets/images/faceBookIcon.png";
import contactUsIcon from "./assets/images/contactUsIcon.svg";
import Event from "./components/Event";
import Gallery from "./components/Gallery";
import Members from "./components/Members";
import AboutUs from "./components/AboutUs";
import Donation from "./components/Donation";
import Membership from './components/membership'
import MobleSingleEvent from "./components/MobleSingleEvent";
import BookTickets from "./components/bookTickets";

// images
import sideBars from "./assets/images/sideBarDesign.svg";
import contactUS from "./assets/images/contactUsIcnWeb.svg";
import contactUsmbl from "./assets/images/contactUsIcnMbl.svg"
import contactUSIconverticle from "./assets/images/contatUsIconverticle.svg";
import useWindowDimensions from "./common/UseWindowDimensions";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
import { thunk } from 'redux-thunk';
import TermsAndConditions from "./components/termsAndConditions";
import PostPayment from "./components/postPayment";


const persistedState = loadFromLocalStorage();

export const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk)
);

store.subscribe(() => saveToLocalStorage(store.getState()));
window.store = store;

function App() {
  const { height, width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const instagramProfileUrl = "https://instagram.com/assamsocietyblr?igshid=MzRlODBiNWFlZA==";
  const faceBookProfileUrl = "https://www.facebook.com/assamsocietybangalore/";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    setPath(window.location.pathname)
  }, [window.location.pathname])
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <Provider store={store}>
      <div className="appContainer">
        {isLoading ? (
          <div className="loaderContainer">
            <LoaderLottie />
          </div>
        ) : (
          <>
            <div className="app-main-container">
              <Header />
              <div className="btn-Container">
                {
                  path === '/book-tickets' ? 
                  <a
                    href={instagramProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="cir-btn">
                      <img src={instagramIcon} />
                    </button>
                  </a> : null
                }
                {
                  path === '/book-tickets' ? 
                  <a
                    href={faceBookProfileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="cir-btn">
                      {" "}
                      <img src={faceBookIcon} />
                    </button>
                  </a> : null
                }
                <button onClick={() => { navigate("/contact-donation-details") }} className="cir-btn">
                  {" "}
                  <img src={contactUsIcon} alt="Image not found"
                    loading="lazy" />
                </button>
                {width > 600 ? (<div>
                  <Modal
                    open={isModalOpen}
                    bodyStyle={{ height: "63vh" }}
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    closable={true}
                    header={null}
                    footer={null}
                    width="60%"
                  >
                    <div>
                      <div className="sideBarContact">
                        <img src={sideBars} />
                        <div className="contactUsIcnCnt">
                          <img src={contactUSIconverticle} alt="Image not found"
                            loading="lazy" />
                          <img className="iconCnt" src={contactUS} alt="Image not found"
                            loading="lazy" />
                          <img src={contactUSIconverticle} alt="Image not found"
                            loading="lazy" />
                        </div>

                        <img src={sideBars} />
                      </div>
                    </div>
                  </Modal>
                </div>) : (<div>
                  <Modal
                    open={isModalOpen}
                    bodyStyle={{ height: "53vh" }}
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    closable={true}
                    header={null}
                    footer={null}
                    width="85%">
                    <div>
                      <div className="mbSideBarContact">
                        <div className="mbCntContentHolder">
                          <img className="mbCntContentHolderCntner" src={contactUSIconverticle} />
                          <img className="mbCntUsCntner" src={contactUsmbl} />

                          <img className="mbCntContentHolderCntner" src={contactUSIconverticle} />


                        </div>

                      </div>

                    </div>

                  </Modal></div>)}

              </div>
              {/* <BrowserRouter> */}
              <Routes>
                <Route path="/" element={<AppLayout />} />
                <Route path="/events" element={<Event />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/members" element={<Members />} />
                <Route path="/aboutUs" element={<AboutUs />} />
                <Route path="/donation" element={<Donation />} />
                <Route path="/membership" element={<Membership />} />
                <Route path="gallery-details" element={<GalleryDetails />} />
                <Route path="aboutus-details" element={<AboutUSDetails />} />
                <Route path="donation-details" element={<DonationDetails />} />
                <Route path="members-details" element={<MembersDetails />} />
                <Route path="single-event" element={<MobleSingleEvent />} />
                <Route path="/contact-donation-details" element={<DonationDetails />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path='/book-tickets' element={<BookTickets />} />
                <Route path='/post-payment' element={<PostPayment />} />
              </Routes>
            </div>
          </>
        )}
      </div>
    </Provider>
  );
}

export default App;
